/*
    Author: Reshma Ghanta
    Desc: This page allows you to load different components of e911Live system.
          When this page is called, mappingContainer is loaded by default 
          and other containers are loaded based on user request.
          (MappingContainer, AccessControl, Manage Account, Register)
*/
import React from "react";
import { Outlet } from "react-router-dom";
import FooterE911 from "../../components/footer";
import NavigationbarE911 from "../../components/reusableComponents/NavigationbarE911";

const Dashboard = () => {
  return (
    <>
      <NavigationbarE911 />  {/* Render NavigationbarE911 component */}
      <Outlet /> {/* Render child routes/components from react-router-dom */}
      <FooterE911 /> {/* Render FooterE911 component */}
    </>
  );
};
export default Dashboard;
