/*
    Author: Reshma Ghanta
    Desc: Reusable Text component.
*/
import React from "react";
import useMediaQuery from "../../hooks/useMediaQuery.jsx";

/*
    Author: Lamiaa Moustafa
    Desc: Handling Media query using hook
*/

const SloganStyle = {
  color: "var(--white)",
  fontFamily: "var(--fontFamily)",
  lineHeight: "1em",
  fontWeight: "var(--boldFont)",
  letterSpacing: "2px",
  textShadow: " 4px 4px 0px rgba(0,0,0,0.3)",
};


const SloganE911 = (props) => {
  const { text } = props.data;
  const isMobile = useMediaQuery("(min-width: 575px)");
  //debugger;
  return (
    <>
      <h3
        style={{
          ...SloganStyle,
          ...{ fontSize: isMobile ? "7vw " : "3.5em" },
        }}
      >
        {text}
      </h3>
    </>
  );
};

export default SloganE911;
