import React, { useState, useRef, useEffect } from "react";
//import ReactPlayer from "react-player";
import TextE911 from "../../../components/reusableComponents/TextE911";
import { BsCloudDownloadFill } from "react-icons/bs";
import {
  headlineSidebar,
  bodySidebar,
  bodySidebarName,
} from "../../../styles/customStyle";
import { Col } from "react-bootstrap";
// import VideoPlayer from "../../../components/videoPlayer";
import ModalE911 from "../../../components/reusableComponents/ModalE911";
import "../../../styles/scss/sidebar.scss";
import "../../../styles/scss/video.scss";
import { SoteriaData } from "../../../contexts/SoteriaContext";
import AWS from "aws-sdk";
import Hls from "hls.js";
import poster from "../../../assets/images/pngs/video-poster.png";

const DeviceDetails = () => {
  const { soteriaDetails } = SoteriaData();
  const [modalShow, setModalShow] = useState(false); // State hook for managing modal show/hide state
  //const [isPlaying, setIsPlaying] = useState(false);

  const downloadUrl = soteriaDetails.floorPlanUrl; // Extract download URL from soteria object
  const deviceFullname = soteriaDetails.deviceID;
  const videoRef = useRef(null);

  // console.log(soteriaDetails);

  /*
     Author: Reshma Ghanta
     Desc: Check if facility layout is available or not and show it to the user.
  */
  const handleClick = () => {
    if (!downloadUrl) {
      setModalShow(true); // Set modal show state to true
    } else {
      // Perform facility layout action here, e.g., open URL in new window
      window.open(downloadUrl);
    }
  };

  /*
     Author: Reshma Ghanta
     Desc: Kinesis streaming for soteria device.
  */
  const fetchHLSStream = async (kinesisVideoArchivedContent) => {
    try {
      const hlsStream = await kinesisVideoArchivedContent
        .getHLSStreamingSessionURL({
          StreamName: deviceFullname,
          HLSFragmentSelector: {
            FragmentSelectorType: "SERVER_TIMESTAMP",
          },
        })
        .promise();

      // console.log('HLS Stream Response:', hlsStream);

      if (hlsStream && hlsStream.HLSStreamingSessionURL) {
        const player = videoRef.current;
        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(hlsStream.HLSStreamingSessionURL);
          hls.attachMedia(player);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            player.play();
          });
        } else if (player.canPlayType("application/vnd.apple.mpegurl")) {
          player.src = hlsStream.HLSStreamingSessionURL;
          player.addEventListener("loadedmetadata", () => {
            player.play();
          });
        }
        return true;
      }
    } catch (err) {
      console.error(err);
      /* if (videoRef.current) {
        videoRef.current.style.display = 'none';
      } */
      return false;
    }
  };

  //  useEffect(() => {

  /*   fetch('https://covertalertwebsitetest.web.app/utilapi/kinesis_a')
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      console.log(data);
    }); */

  /*  fetch('https://covertalertwebsitetest.web.app/utilapi/kinesis_a', {
            mode: 'no-cors',
            method: "GET",
            headers: {
                 "Content-Type": "application/json"
            }
     }).then((res) => {
      return res.json();
    })
    .then((data) => {
      console.log(data);
    });
 */

  /*  const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
    const targetUrl = 'https://covertalertwebsitetest.web.app/utilapi/kinesis_a';

    fetch(targetUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); 
    })
    .then((data) => {
      console.log(data); 
    })
    .catch((error) => {
      console.error('There was an error fetching the data:', error);
    }); */

  /* fetch('http://localhost:5000/api/kinesis_a')
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      console.log(data); // Log the data to verify it's correct
    })
    .catch((error) => {
      console.error('There was an error fetching the data:', error);
    }); */
  // }, []);

  /*
     Author: Reshma Ghanta
     Desc: Kinesis streaming for soteria device.
  */
  useEffect(() => {
    const fetchSDKClients = async () => {
      try {
        // Fetch region, access key, and secret key from respective endpoints
        const regionResponse = await fetch(
          "https://covertalertwebsitetest.web.app/utilapi/kinesis_basic"
        );
        if (!regionResponse.ok)
          throw new Error("Network response was not ok for kinesis_basic");
        const regionData = await regionResponse.json();

        const accessKeyResponse = await fetch(
          "https://covertalertwebsitetest.web.app/utilapi/kinesis_a"
        );
        if (!accessKeyResponse.ok)
          throw new Error("Network response was not ok for kinesis_a");
        const accessKeyData = await accessKeyResponse.json();

        const secretKeyResponse = await fetch(
          "https://covertalertwebsitetest.web.app/utilapi/kinesis_s"
        );
        if (!secretKeyResponse.ok)
          throw new Error("Network response was not ok for kinesis_s");
        const secretKeyData = await secretKeyResponse.json();

        const options = {
          region: regionData.region,
          accessKeyId: accessKeyData.accessKeyId,
          secretAccessKey: secretKeyData.secretAccessKey,
        };

        /*  const options = {
          region: process.env.REACT_APP_AWS_REGION,
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        };
 */
        // console.log('AWS Options:',options);

        const kinesisVideo = new AWS.KinesisVideo(options);
        const kinesisVideoArchivedContent = new AWS.KinesisVideoArchivedMedia(
          options
        );

        const dataEndpoint = await kinesisVideo
          .getDataEndpoint({
            StreamName: deviceFullname,
            APIName: "GET_HLS_STREAMING_SESSION_URL",
          })
          .promise();

        // console.log('Data Endpoint:', dataEndpoint);

        kinesisVideoArchivedContent.endpoint = new AWS.Endpoint(
          dataEndpoint.DataEndpoint
        );

        const checkStream = async () => {
          const success = await fetchHLSStream(kinesisVideoArchivedContent);
          if (!success) {
            setTimeout(checkStream, 2000); // Retry after 2 seconds if fetching the stream failed
          }
        };

        checkStream(); // Start checking for the stream
      } catch (err) {
        console.error(err);
        /*  if (videoRef.current) {
          videoRef.current.style.display = 'none';
        } */
      }
    };

    //console.log(soteriaDetails.soteriaShow);
    if (soteriaDetails.streamingInfo) {
      fetchSDKClients();
    }
  }, [deviceFullname, soteriaDetails.streamingInfo]);

  return (
    <>
      <div>
        <Col className="d-flex  justify-content-start align-items-center">
          <TextE911
            data={{
              text: "Download facility layout",
              customStyle: headlineSidebar,
            }}
          />
          <BsCloudDownloadFill className="downloadIcon" onClick={handleClick} />
          <ModalE911
            show={modalShow}
            handleClose={() => {
              setModalShow(false);
            }}
            onHide={() => setModalShow(false)}
            handleShow={() => setModalShow(true)}
            modalTitle="Facility Layout"
          >
            Facility layout is not available for this building.
          </ModalE911>
        </Col>
        <Col>
          <TextE911
            data={{ text: "Time of incident", customStyle: headlineSidebar }}
          />
          <TextE911
            data={{
              text: soteriaDetails.timeOfIncident,
              customStyle: bodySidebar,
            }}
          />
          <TextE911 data={{ text: "Address", customStyle: headlineSidebar }} />
          <TextE911
            data={{
              text: soteriaDetails.address,
              customStyle: bodySidebar,
            }}
          />
          {soteriaDetails.streamingInfo ? (
            <>
              <TextE911
                data={{ text: "LIVE VIDEO", customStyle: headlineSidebar }}
              />
              <div className="player-wrapper">
                <video
                  ref={videoRef}
                  className="video"
                  id="play-video"
                  controls
                  poster={poster}
                ></video>
              </div>
            </>
          ) : null}
          <TextE911
            data={{ text: "ADDITIONAL INFO", customStyle: headlineSidebar }}
          />

          <div className="AdditionalInfo">
            <TextE911
              data={{ text: "Company name:", customStyle: bodySidebarName }}
            />
            <TextE911
              data={{
                text: soteriaDetails.companyName,
                customStyle: bodySidebar,
              }}
            />
          </div>
          <div className="d-flex justify-content-start align-items-center">
            <TextE911
              data={{
                text: "Device Location:",
                customStyle: bodySidebarName,
              }}
            />
            <TextE911
              data={{
                text: soteriaDetails.deviceLocation,
                customStyle: bodySidebar,
              }}
            />
          </div>
        </Col>
      </div>
    </>
  );
};

export default DeviceDetails;
