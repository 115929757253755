import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { RiArrowDownSFill } from "react-icons/ri";
import { GhostButton } from "react-hamburger-menus";
import "react-hamburger-menus/dist/style.css";
import "../../styles/scss/nav.scss";
import { UserData } from "../../contexts/GlobalContext";
import debounce from "lodash/debounce";

const GhostButtonAsDropdownLink = () => {
  const { e911UserDetails } = UserData();
  const [register, setRegister] = useState(false);
  const [accessControl, setAccessControl] = useState(true);

  /*
    Author: Reshma Ghanta
    Desc:  This function uses the debounce method from Lodash to delay the execution of the update function
           If the user role is "superAdmin", it sets both the "register" and "accessControl" states to true.
           If the user role is "Admin", it sets only the "accessControl" state to true and the "register" state to false.
    */
  const delayedUpdate = useCallback(debounce((data) => {
      if (data.userRole === "superAdmin") {
        setRegister(true);
        setAccessControl(true);
      } else if (data.userRole === "Admin") {
        setAccessControl(true);
        setRegister(false);
      }
    }, 1000),
    []
    );
  /*
   * Author: Reshma Ghanta
   * Desc: This useEffect hook runs whenever the e911UserDetails or delayedUpdate values change
           This hook is used to ensure that the delayedUpdate function only runs once per second
   */
  useEffect(() => {
    delayedUpdate(e911UserDetails);
  }, [e911UserDetails, delayedUpdate]);

  return (
    <>
      <GhostButton
        icon={
          <p
            className="NavLink"
            style={{
              color: "#fff",
              fontSize: "18px",
              // borderBottom: "1px solid grey",
            }}
          >
            User Manager <RiArrowDownSFill style={{ color: "white" }} />
          </p>
        }
        styles={{
          floatButtonY: 40,
          floatButtonX: 10,
          navigationCard: {
            backgroundColor: "#fff",
            // top: "50px"
            // width: '160px'
            // left: 0
          },
          navigationButton: {
            borderRadius: "10px",
            border: "none",
            // In case you need static not fixed
            position: "relative",
            width: "150px",
            height: "40px",
            backgroundColor: "transparent",
            zIndex: 10,
            boxShadow: "none",
          },
          navigationIcon: {
            zIndex: 10,
          },
          iconColor: "#fff",
        }}
      >
        <ul>
          {accessControl && (
            <Link className="NavLinkStyle" to="/accessControl">
              Access Control
            </Link>
          )}
          {register && accessControl && (
            <Link className="NavLinkStyle" to="/register">
              Register
            </Link>
          )}
        </ul>
      </GhostButton>
    </>
  );
};

export default GhostButtonAsDropdownLink;
