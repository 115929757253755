/*
    Author: Lamiaa Moustafa
    Desc: Reusable Label component.
*/
import React from "react";

function TextareaE911(props) {
  const {
    label,
    defaultValue,
    onChange,
    placeholder,
    ref,
    maxLength,
    customStyle,
    isRequired,
  } = props.data;
  return (
    <>
      <textarea
        label={label}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        maxLength={maxLength}
        ref={ref}
        isRequired={isRequired}
        style={customStyle}
      />
    </>
  );
}

export default TextareaE911;
