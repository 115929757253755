import React, { createContext,useContext,useEffect,useState } from 'react';
import {ref,set,update,onValue,push} from  "firebase/database"
import {E911_db} from '../firebaseConfig'
import { UserAuth } from './AuthContext';

/*
    Author: Reshma Ghanta
    Desc: Context API for E911live project.
          To create Temp users and Permenant users and update users for e911live project.
          (Register and Manage account pages)
*/
const GlobalContext=createContext();

export const GlobalContextProvider=({children})=>{
    const { e911User } = UserAuth();
    const [e911UserDetails,setE911UserDetails]= useState({});
  //  const [locationSettings, setLocationSettings] = useState();



  /*   const setTypeOfLocationSettings = (data) =>{
        //console.log(data)
       setLocationSettings(data);
     } */

    /*
       Author: Reshma Ghanta
       Desc: Function to create E911 user data in Firebase database
    */
    const createUserDataE911=(userId,firstName,lastName,email,phoneNumber,badgeNumber,userRole)=>{
     return set(ref(E911_db, `/firstResponders/${userId}`), {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        alertSound:"softAlert",
        profilePhotoUrl:"",
        email2FA:"",
        phone2FA:"",
        gpsLatLng:{
            gpsLatitude: 0,
            gpsLongitude: 0,
        },
        badgeNumber:badgeNumber,
        userRole:userRole,
        owner:""
       }); 
      }

     /*
       Author: Reshma Ghanta
       Desc: Function to create temporary E911 user data in Firebase database
     */
      const createTempDataE911=(userId,firstName,lastName,email,phoneNumber,badgeNumber,userRole)=>{
        return push(ref(E911_db, `/tempMembers/`), {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
            alertSound:"softAlert",
            profilePhotoUrl:"",
            email2FA:"",
            phone2FA:"",
            gpsLatLng:{
                gpsLatitude: 0,
                gpsLongitude: 0,
            },
            badgeNumber:badgeNumber,
            userRole:userRole,
            owner:userId
           });
         }

     /*
       Author: Reshma Ghanta
       Desc: Function to update E911 user data in Firebase database
     */     
     const updateUserDataE911=(userId,firstName,lastName,phoneNumber,alertSound,profilePhotoUrl)=>{
      return update(ref(E911_db, `/firstResponders/${userId}`), {
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        alertSound:alertSound,
        profilePhotoUrl:profilePhotoUrl,
       });  
    } 

     /*
       Author: Reshma Ghanta
       Desc: Fetch E911 user data from Firebase database on initial load or when user changes
     */ 
    useEffect(()=>{
    //  console.log(e911User)
       e911User&&onValue(ref(E911_db, `/firstResponders/${e911User.uid}`), (snapshot) => {
          const data=snapshot.val();
          //console.log(data)
          setE911UserDetails(data);
        })
     },[e911User]) ; 

    return(
        <GlobalContext.Provider value={{createUserDataE911, updateUserDataE911, createTempDataE911, e911UserDetails}}>
        {children}
        </GlobalContext.Provider>

    )
}

export const UserData=()=>{
    return useContext(GlobalContext)
}

