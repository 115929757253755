/*
    Author: Reshma Ghanta/Lamiaa Moustafa
    Desc: Reusable Button component.
    DESC: setting the button hover using state 
*/
import React, { useState } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";

function ButtonE911(props) {
  const { label, onClick, customStyle } = props.data;
  const isMobile = useMediaQuery("(min-width: 575px)");
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const HoverStyle = {
    backgroundColor: isHover ? "var(--yellowOrange)" : "var(--orange)",
  };

  const ButtonStyle = {
    backgroundColor: "var(--orange)",
    fontWeight: "var(--regularFont)",
    color: "var(--white)",
    fontSize: "1em",
    padding: isMobile ? "12px 35px" : "10px 25px",
    letterSpacing: "1px",
    borderRadius: "25px",
    margin: "20px 10px",
    border: "1px solid var(--white)",
    cursor: "pointer",
  };

  return (
    <button
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      style={{ ...ButtonStyle, ...HoverStyle, ...customStyle }}
    >
      {label}
    </button>
  );
}

export default ButtonE911;
