// import CampusPolice from "../assets/images/CampusPolice.png";
//import ErrorPage from "../assets/images/svgs/404Error.svg";

// brand Color variables
const Colors = {
  orange: "#ff9b30",
  black: " #000000",
  white: "#ffffff",
  darkGray: "#8e9094",
};

// logo brand container style
export const BrandContainer = {
  margin: "0",
  padding: "0 50px",
  // flexGrow: "1",
};

export const NavContainer = {
  height: "70px",
  maxWidth: "700px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  // flexGrow: "1",
};

// background image style for Landing page
export const LandingContainer = {
  width: "100%",
  minHeight: "100vh",
  backgroundColor: "black",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "right",
  paddingTop: "1em",
};

// Logo style
export const LogoBrand = {
  Width: "100%",
  padding: "10px",
};

// container style for Gps receiver/bootstrap popover containers
export const ContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexGrow: "1",
};

//bootstrap popover containers style
export const PopoverContainerStyle = {
  display: "flex",
  paddingRight: "20px",
  justifyContent: "end",
  alignItems: "center",
};

// Navigation bar style
export const NavbarStyle = {
  borderBottom: "1px solid var(--orange)",
  backgroundColor: "var(--black)",
  display: "flex",
  justifyContent: "center",
};

// background image style for Contact page
export const ContactContainer = {
  width: "60%",
  height: "100vh",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "right",
  paddingTop: "1em",
};

export const styles = {
  LandingContainer: LandingContainer,
  LogoBrand: LogoBrand,
  BrandContainer: BrandContainer,
  Colors: Colors,
  ContainerStyle: ContainerStyle,
  NavbarStyle: NavbarStyle,
  NavContainer: NavContainer,
  ContactContainer: ContactContainer,
};
