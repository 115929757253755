import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import TextE911 from "../../../components/reusableComponents/TextE911";
import { TextListIncident } from "../../../styles/customStyle";
import "../../../styles/scss/incidentList.scss";
import { VictimData } from "../../../contexts/E650DContext";
import SyncLoader from "react-spinners/SyncLoader";
// import { Fire } from "../../../assets/markers/FireMarker.png";
// import { Medical } from "../../../assets/markers/MedicalMarker.png";
// import { Victim } from "../../../assets/markers/VictimMarker.png";
import { TbCircleDashed } from "react-icons/tb";

const IncidentsList = () => {
  const { updateHistoryDataClick, selectTimestamp, historyData } = VictimData();
  const [allIncidents, setAllIncidents] = useState([]);
  const [loading, setLoading] = useState(true);
 // console.log(historyData);

  useEffect(() => {
    if (selectTimestamp === "Past24hours") {
      // Retrieve historyData from localStorage
      const storedHistoryData = localStorage.getItem("historyData");

      // Parse the retrieved string into a JavaScript object
      const historyDataLocal = storedHistoryData
        ? JSON.parse(storedHistoryData)
        : [];

       // console.log(historyDataLocal);
         
      if (historyDataLocal.length > 0) {
        //console.log(historyData);
        setLoading(false);

        const newIncidents = historyDataLocal.map((data) => {
          //console.log(data)
          const { firstName, typeOfEmergency, userId, isFalseAlert } = data;
          const victimLabel = isFalseAlert
            ? `${firstName} - ${typeOfEmergency} emergency - false alert`
            : `${firstName} - ${typeOfEmergency} emergency`;

          const victimValue = `${userId}_${typeOfEmergency}`;

          return {
            value: victimValue,
            label: victimLabel,
            firstName,
            startLatitude: data.startLatitude,
            startLongitude: data.startLongitude,
            timeStamp: data.timeStamp,
            typeOfEmergency,
            userId,
            typeOfData: data.typeOfData,
            isFalseAlert,
          };
        });
        //setAllIncidents((prevVictimData) => [...prevVictimData, victimData]);
        setAllIncidents(newIncidents);
      } else {
        setLoading(true);
      }
    }
  }, [localStorage.getItem("historyData")]);

  const handleChangeSelect = (data) => {
    //console.log(data)
    updateHistoryDataClick(data);
  };

  return (
    <>
      <Col md={{ span: 6 }} lg={{ span: 12 }}>
        <div className="ListBoxLabel">
          <TextE911
            data={{
              text: "Incidents List",
              customStyle: TextListIncident,
            }}
          />
        </div>
        {loading ? (
          <div
            className="ListBoxStyle"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "25px",
            }}
          >
            <SyncLoader
              color="#ff9b30"
              loading={loading}
              margin={3}
              size={10}
              speedMultiplier={0.5}
            />
          </div>
        ) : (
          <div className="ListBoxStyle">
            <ul className="orderedList">
              {allIncidents
                .slice()
                .sort((a, b) => b.timeStamp - a.timeStamp)
                .map((item) => (
                  <div className="listRow">
                    <>
                      <TbCircleDashed style={{ color: "orange" }} />
                    </>
                    <li
                      key={item.value}
                      onClick={() => handleChangeSelect(item)}
                    >
                      {item.label}
                    </li>
                  </div>
                ))}
            </ul>
          </div>
        )}
      </Col>
    </>
  );
};

export default IncidentsList;
