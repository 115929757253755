/*
    Author: Reshma Ghanta/Lamiaa Moustafa
    Desc: Reusable Navigation bar component.
          Importing Links from react dom router.
    Desc:Style and implement React bootstrap responsive navigation
*/
import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Hamburger from "hamburger-react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import GhostButton3 from "./GhostButton3";
import { NavbarStyle, NavContainer } from "../../styles/MainStyled";
import { useLocation } from "react-router-dom";
import "../../styles/scss/nav.scss";
import AvatarPopOver from "../popOver";
import ImageE911 from "./Image911";
import ReceiverSoftware from "../receiverSoftware";
import { UserData } from "../../contexts/GlobalContext";
import debounce from "lodash/debounce";
import useMediaQuery from "../../hooks/useMediaQuery";

const NavigationbarE911 = () => {
  // Menu responsive
  const isMobile = useMediaQuery("(min-width: 575px)");
  const [isNavExpanded, setIsNavMenuExpanded] = useState(false);
  const { e911UserDetails } = UserData();
  const [isFirstResponder, setIsFirstresponder] = useState(true);
  const [register, setRegister] = useState(false);
  const [accessControl, setAccessControl] = useState(false);

  /*
    Author: Reshma Ghanta
    Desc:  This function uses the debounce method from Lodash to delay the execution of the update function
           If the user role is "firstResponder", it sets  the "isFirstResponder" state to true and the "register", "access control" state to false.
           Handles for responsiveness hamburger in smaller screen
          ( If the user role is "Admin", it sets only the "accessControl" state to true and the "register" state to false.
           If the user role is "superAdmin", it sets both the "register" and "accessControl" states to true.  )    
    */
  const delayedUpdate = useCallback(
    debounce((data) => {
     // console.log(data);
      if (data.userRole === "firstResponder") {
        setIsFirstresponder(true);
        setAccessControl(false);
        setRegister(false);
      } else {
        setIsFirstresponder(false);
        if (data.userRole === "Admin") {
          setAccessControl(true);
          setRegister(false);
        } else if (data.userRole === "superAdmin") {
          setAccessControl(true);
          setRegister(true);
        }
      }
    }, 1000),
    []
  );

  /*
     Author: Reshma Ghanta
     Desc: This useEffect hook runs whenever the e911UserDetails or delayedUpdate values change
           This hook is used to ensure that the delayedUpdate function only runs once per second
   */
  useEffect(() => {
    delayedUpdate(e911UserDetails);
  }, [e911UserDetails, delayedUpdate]);

  /*
    Author: Reshma Ghanta
    Desc: Download the latest GPS software available from firebase storage on click event
   */
  const handleClickDownload = (event) => {
    //console.log("Download clicked")
    const gpsSoftware =
      "https://firebasestorage.googleapis.com/v0/b/covertalert-e650d.appspot.com/o/Covert%20Alert%20GPS%20receiver%2FCovert%20Alert%20GPS-2.0%20win-x64.exe.exe?alt=media&token=1237a763-61c6-48a6-ad2e-6efcedf2775b";
    window.location.href = gpsSoftware;
  };

  const closeMenu = () => {
    isNavExpanded(true);
  };
  const location = useLocation();

  return (
    <>
      <Navbar
        style={NavbarStyle}
        collapseOnSelect
        fixed="top"
        variant="dark"
        expand="sm"
      >
        <Container fluid d-flex align-items-center justify-content-between>
          <Link to="/dashboard">
            <ImageE911 />
          </Link>
          <div
            style={NavContainer}
            className={
              isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
            }
          >
            <ul>
              <li>
                {location.pathname === "/dashboard" && (
                  <ReceiverSoftware
                    data={{
                      label: "GPS Receiver Software",
                      onClick: handleClickDownload,
                    }}
                  />
                )}
              </li>
              <Link onClick={closeMenu} className="MobileNav3" to="/dashboard">
                Dashboard
              </Link>
              {!isFirstResponder && accessControl && register && (
                <Link onClick={closeMenu} className="MobileNav2" to="/register">
                  Register
                </Link>
              )}
              {!isFirstResponder && accessControl && (
                <Link
                  onClick={closeMenu}
                  className="MobileNav1"
                  to="/accessControl"
                >
                  Access Control
                </Link>
              )}

              <li style={{ display: isMobile ? "block" : "none" }}>
                {!isFirstResponder && <GhostButton3 />}
              </li>
              <li>
                <AvatarPopOver />
              </li>
            </ul>
          </div>
          <div>
            <Hamburger
              onToggle={() => {
                setIsNavMenuExpanded(!isNavExpanded);
                // console.log(isNavExpanded);
              }}
            />
          </div>
        </Container>
      </Navbar>
    </>
  );
};
export default NavigationbarE911;
