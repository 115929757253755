/*
   Author : Reshma Ghanta
   Desc: Select the dropdown value based on user input.
         Get the current time&date using javascript Data object.
         Calculate past 24 hours time/date.
*/
import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { Slider } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "../../../styles/scss/sidebar.scss";
import {
  TimeStampText,
  NumIncText,
  EditUserSelect,
  headlineSidebar,
} from "../../../styles/customStyle";
import TextE911 from "../../../components/reusableComponents/TextE911";
import SelectE911 from "../../../components/reusableComponents/SelectE911";
import { VictimData } from "../../../contexts/E650DContext";

const calculateTimeRange = (startOffsetMillis, endOffsetMillis) => {
  const start = new Date(
    new Date().getTime() - startOffsetMillis
  ).toLocaleTimeString();
  const end = new Date().toLocaleTimeString();
  return `${new Date(
    new Date().getTime() - startOffsetMillis
  ).toLocaleDateString()} ${start} to ${new Date().toLocaleDateString()} ${end}`;
};

const calculateTimeNow = () => {
  const loginTime = new Date().toLocaleTimeString();
  return `${new Date(
    new Date().getTime()
  ).toLocaleDateString()} ${loginTime} to now `;
};

const TimeStamp = ({
  appsCount,
  devicesCount,
  setSelectedTimeStamp,
  setGeofenceRadius,
  setSelect24hrsRange,
}) => {
  const { setTypeOfTimestamp } = VictimData();

  const options = [
    { id: 0, label: "Active Emergencies", value: "activeEmergencies" },
    { id: 1, label: "Past 24 Hours", value: "Past24hours" },
  ];

  const [selectValue, setSelectValue] = useState(options[0]);
  const [timeframe, setTimeFrame] = useState("");
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );
  const currentTimeMilliSecs = Math.round(new Date().getTime());
  const last24hoursMilliSecs = Math.round(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000).getTime()
  );
  const last15minsMilliSecs = Math.round(
    new Date(new Date().getTime() - 15 * 60 * 1000).getTime()
  );

  const [showTotalCount, setShowTotalCount] = useState(true);
  /*
     Author: Reshma Ghanta
     Desc: Update the time for every 1 second.
  */
  function refreshClock() {
    setCurrentTime(new Date().toLocaleTimeString());
  }

  /*
     Author: Reshma Ghanta
     Desc: Refresh the timestamp for every 1 sec and clean up the memory.
           When timestamp is selected by user, 
          a) display the timeframe on webpage based on user selection
          b) update/set the timestamp to state variable.
  */
  useEffect(() => {
    //console.log(selectValue.value)
    if (selectValue.value === "activeEmergencies") {
      setSelect24hrsRange({ incidentsListShow: false });
      setShowTotalCount(true);
      setTimeFrame(calculateTimeNow());
      setSelectedTimeStamp({
        currentTime: currentTimeMilliSecs,
        previousTime: last15minsMilliSecs,
      });
    }
  }, [selectValue.value, currentTime]);

  /*
     Author: Reshma Ghanta
     Desc: Refresh the timestamp for every 1 sec and clean up the memory.
           When timestamp is selected by user, 
          a) display the timeframe on webpage based on user selection
          b) update/set the timestamp to state variable.
  */
  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    if (selectValue.value === "Past24hours") {
      setSelect24hrsRange({ incidentsListShow: true });
      setShowTotalCount(false);
      setTimeFrame(calculateTimeRange(24 * 60 * 60 * 1000));
      setSelectedTimeStamp({
        currentTime: currentTimeMilliSecs,
        previousTime: last24hoursMilliSecs,
      });
    }
    return function cleanup() {
      clearInterval(timerId);
    };
  }, [currentTime]);

  return (
    <>
      <div className="TimeStampDiv">
        <Col md={{ span: 6 }} lg={{ span: 12 }}>
          <TextE911
            data={{ text: "Geofencing Radius", customStyle: headlineSidebar }}
          />
          <Slider
            progress
            min={1}
            defaultValue={10}
            max={25}
            onChange={(value) => {
              setGeofenceRadius(value);
            }}
          />
          <SelectE911
            options={options}
            value={selectValue}
            customStyle={EditUserSelect}
            onChange={(selected) => {
              setSelectValue(selected);
              setTypeOfTimestamp(selected.value);
            }}
            defaultValue={options[0]}
          />
          <TextE911 data={{ text: timeframe, customStyle: TimeStampText }} />
          {showTotalCount && (
            <div className="d-flex justify-content-start align-items-center">
              <TextE911
                data={{ text: "Active alerts:", customStyle: NumIncText }}
              />
              <TextE911
                data={{ text: appsCount, customStyle: NumIncText }}
              />
               <TextE911
                data={{ text: "apps,", customStyle: NumIncText }}
              />
               <TextE911
                data={{ text: devicesCount, customStyle: NumIncText }}
              />
               <TextE911
                data={{ text: "devices", customStyle: NumIncText }}
              />
            </div>
          )}
        </Col>
      </div>
    </>
  );
};

export default TimeStamp;
