/*
Author: Reshma Ghanta/Lamiaa Moustafa
Desc: Styling using React Bootstrap and importing elements from the grid System.
      Password reset is sent to the user's email after the email is validated.
*/

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { styles } from "../../styles/MainStyled";
import { Container, Row, Col } from "react-bootstrap";
import { InputStyle } from "../../styles/customStyle";
import SloganE911 from "../../components/reusableComponents/SloganE911";
import FormInputE911 from "../../components/reusableComponents/FormInputE911";
import ButtonE911 from "../../components/reusableComponents/ButtonE911";
import FadeIn from "react-fade-in";
import earthClear from "../../assets/images/svgs/earthClear.svg";
import "../../styles/scss/login.scss";
import { UserAuth } from "../../contexts/AuthContext";
import ImageE911 from "../../components/reusableComponents/Image911";

const ForgotPassword = () => {
  /*
    Author: Lamiaa Moustafa
    Desc: changing background image based on route.
  */
  const earthImage = {
    backgroundImage: `url(${earthClear})`,
  };

  // Importing the necessary dependencies
  const { resetPasswordE911 } = UserAuth();
  const navigate = useNavigate();

  // Setting up state to manage form values
  const [formVal, setFormValue] = useState({
    email: "",
  });

 /*
    Author: Reshma Ghanta
    Desc: Event handler for updating form values
  */
  const handleChangeSendEmail = (event) => {
    setFormValue({ ...formVal, [event.target.name]: event.target.value });
  };

  /*
    Author: Reshma Ghanta
    Desc: Event handler for sending password reset email
   */
  const handleClickSendEmail = async () => {
    await resetPasswordE911(formVal.email);
    navigate("/"); // Navigating to home page after sending email
  };

   /*
    Author: Reshma Ghanta
    Desc:  Send password reset link to user on click  Enter key
*/
const handleKeyPress = (e) => {
  //console.log("handle key press is triggered")
  if (e.key === 'Enter' || e.key === 'Return') {
    handleClickSendEmail(e);
  }
};

  return (
    <div style={{ ...styles.LandingContainer, ...earthImage }}>
      <ImageE911 />
      <FadeIn>
        <Container
          fluid
          className="d-flex flex-column align-items-start justify-content-around "
        >
          <Row className="Homepage">
            <Col>
              <SloganE911
                data={{
                  text: "FORGOT YOUR",
                }}
              ></SloganE911>
              <SloganE911
                data={{
                  text: "PASSWORD",
                }}
              ></SloganE911>
            </Col>
            <Col
              lg={10}
              xs={12}
              className="FormInput mt-3 w-80 d-flex
              flex-column align-items-start justify-content-center"
            >
              <label className="InputLabel">
                Enter your email address, and we will send you a reset.
              </label>
              <FormInputE911
                data={{
                  name: "email",
                  type: "email",
                  placeholder: "EMAIL ADDRESS",
                  onChange: handleChangeSendEmail,
                  onKeyPress: handleKeyPress,
                  customStyle: InputStyle,
                }}
              />
            </Col>

            <Col
              xs={12}
              md={6}
              className="mt-4 d-flex flex-column align-items-center justify-content-center"
            >
              <ButtonE911
                data={{
                  label: "SEND",
                  onClick: handleClickSendEmail,
                }}
              />

              <Link className="linkStyle" to="/">
                Click here to send email again?
              </Link>
            </Col>
          </Row>
        </Container>
      </FadeIn>
    </div>
  );
};

export default ForgotPassword;
