/*
    Author: Reshma Ghanta/ Lamiaa Moustafa
    Desc: Register page for e911live
          This page allows super Admin to sign up Admin/First respondersinto the e911 system.
          This allows Admin to Sign up first responders.
          User role dropdown menu is opened based on the user role of the person logged into e911 system.
          Importing the small components and React hooks that are necessary.
    Desc: Styling using React Bootstrap and importing elements from the grid System.
*/

import React, { useState, useEffect } from "react";
import { Container, Col } from "react-bootstrap";
import { styles } from "../../styles/MainStyled";
import {
  InputStyle,
  SuccessMessageStyle,
  selectStyle,
} from "../../styles/customStyle";
import earthContact from "../../assets/images/svgs/earthContact.svg";
import FormInputE911 from "../../components/reusableComponents/FormInputE911";
import ButtonE911 from "../../components/reusableComponents/ButtonE911";
import { UserAuth } from "../../contexts/AuthContext";
import { UserData } from "../../contexts/GlobalContext";
import SuccessMessage from "../../components/successMesssage";
import SelectE911 from "../../components/reusableComponents/SelectE911";
import "../../styles/scss/register.scss";

const Register = () => {
  /*
    Author: Reshma Ghanta
    Desc: Storing form input values into a object using React state
          By default user role is first responders
*/
  const [formValue, setFormValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    badgeNumber: "",
    userRole: "",
  });

  // UserRole drop down options available for super admin
  const superAdminOptions = [
    { id: 1, label: "First Responders", value: "firstResponders" },
    { id: 2, label: "Admin", value: "Admin" },
    { id: 3, label: "Super Admin", value: "superAdmin" },
  ];

  // UserRole drop down options available for  admin
  const AdminOptions = [
    { id: 1, label: "First Responders", value: "firstResponders" },
  ];

  //Storing form input validation & Firebath auth validation errors, success message
  const [formErrors, setFormErrors] = useState({});
  const [registerSuccess, setRegisterSuccess] = useState();
  const { e911UserDetails } = UserData();
  const { e911User } = UserAuth();
  const [selectValue, setSelectValue] = useState("");
  const [options, setOptions] = useState([]);
  const userId = e911User.uid;
  const { createTempDataE911 } = UserData();

  /*
        Author: Reshma Ghanta
        Desc: Show the userRole dropdown based on e911user logged in.
              Change the dropdown values when the e911user details are changed. 
  */
  useEffect(() => {
    if (e911UserDetails) {
      const e911UserRole = e911UserDetails.userRole;
      // console.log(e911UserRole)
      if (e911UserRole === "superAdmin") {
        setOptions(superAdminOptions);
      } else if (e911UserRole === "Admin") {
        setOptions(AdminOptions);
      }
    }
    // eslint-disable-next-line
  }, [e911UserDetails]);

  /*
    Author: Reshma Ghanta
    Desc: Remove success message
          Storing form values into a state object.
 */
  const handleChangeRegister = (event) => {
    setRegisterSuccess("");
    setFormValue({ ...formValue, [event.target.name]: event.target.value });
  };

  /*
    Author: Reshma Ghanta
    Desc: Remove success message
          Update selected user.
          Update userRole in form State
 */
  const handleChangeSelect = (selected, name) => {
    setRegisterSuccess("");
    //console.log(selected.value);
    setSelectValue(selected);
    setFormValue({ ...formValue, [name]: selected.value });
  };

  /*
    Author: Reshma Ghanta
    Desc: Validate the form input data.
          Create a post request and send email to the registered user.
          Create temporary node in the database for the registed user.
 */
  const handleClickRegister = async (e) => {
    e.preventDefault(); // prevent page refresh

    //Validating form input values
    setFormErrors(validate(formValue)); //function for validating form input values and it returns form input errors
    var formInputErrors = validate(formValue); // Validate function returns an object with form input errors
    setRegisterSuccess(""); //Remove success message.

    //If there are no errors from form input then validate firebase auth
    if (Object.keys(formInputErrors).length === 0) {
      //If there is an e911user logged into the system
      if (userId) {
        // Send request to backend and send email to the registered user, to complete registration.
        fetch("/invite_email", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formValue), // Send form data as body to backend
        })
          .then((data) => {
            console.log("Success:", data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });

        // debugger;

        // Create temporary node in E911 live projext
        await createTempDataE911(
          userId,
          formValue.firstName,
          formValue.lastName,
          formValue.email,
          formValue.phoneNumber,
          formValue.badgeNumber,
          formValue.userRole
        );
        setRegisterSuccess("New user is successfully registered!"); // Display success message
        setFormValue({}); // Reset the form
        setSelectValue(""); // Reset user Role
      } // end if user id
    } // end if form errors
  }; // end register function

  /*
    Author: Reshma Ghanta
    Desc:  validating form inputs from user using Regex
*/
  const validate = (values) => {
    const errors = {};

    const name_regex = /^[a-zA-Z]+$/; //eslint-disable-next-line
    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i; //eslint-disable-next-line
    const phone_regex =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/; //eslint-disable-next-line
    const id_regex = /^[A-Za-z0-9]+/; //eslint-disable-next-line

    if (!values.firstName) {
      errors.firstName = "First name is required!";
    } else if (!name_regex.test(values.firstName)) {
      errors.firstName =
        "Numbers and special characters not allowed for first name";
    }
    if (!values.lastName) {
      errors.lastName = "Last name is required!";
    } else if (!name_regex.test(values.lastName)) {
      errors.lastName =
        "Numbers and special characters not allowed for last name";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!email_regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.phoneNumber) {
      errors.phone = "Phone number is required!";
    } else if (!phone_regex.test(values.phoneNumber)) {
      errors.phone = "This is not a valid phone number format!";
    } else if (values.phoneNumber.length > 10) {
      errors.phone = "Phone number cannot exceed 10 numbers!";
    }
    if (!values.badgeNumber) {
      errors.badgeNumber = "Identification number is required!";
    } else if (!id_regex.test(values.badgeNumber)) {
      errors.badgeNumber = "This is not a valid id number format!";
    }

    if (!values.userRole) {
      errors.userRole = "User role is required!!";
    }
    return errors;
  };

  /*
    Author: Lamiaa Moustafa
    Desc: changing background image based on route.
  */
  const earthContactImage = {
    backgroundImage: `url(${earthContact})`,
  };

  return (
    <div style={{ ...styles.LandingContainer, ...earthContactImage }}>
      <Container fluid className="RegisterContainerStyle">
        <div className="RegisterGroup">
          <Col
            className="RegisterFormInput mt-1 d-flex
              flex-column align-items-start justify-content-center"
          >
            <SuccessMessage
              data={{
                text: registerSuccess,
                customStyle: SuccessMessageStyle,
              }}
            ></SuccessMessage>
            <FormInputE911
              data={{
                name: "firstName",
                type: "text",
                label: "First Name",
                customStyle: InputStyle,
                onChange: handleChangeRegister,
                error: formErrors.firstName,
                autoComplete: "off",
                value: formValue.firstName || "",
              }}
            />
            <FormInputE911
              data={{
                name: "lastName",
                type: "text",
                label: "Last Name",
                customStyle: InputStyle,
                onChange: handleChangeRegister,
                error: formErrors.lastName,
                autoComplete: "off",
                value: formValue.lastName || "",
              }}
            />
            <FormInputE911
              data={{
                name: "email",
                type: "email",
                label: "Email Address",
                customStyle: InputStyle,
                onChange: handleChangeRegister,
                error: formErrors.email,
                autoComplete: "off",
                value: formValue.email || "",
              }}
            />

            <FormInputE911
              data={{
                name: "phoneNumber",
                type: "tel",
                label: "Phone Number",
                customStyle: InputStyle,
                onChange: handleChangeRegister,
                error: formErrors.phone,
                autoComplete: "off",
                value: formValue.phoneNumber || "",
              }}
            />

            <FormInputE911
              data={{
                name: "badgeNumber",
                type: "text",
                label: "Badge or Identification Number",
                customStyle: InputStyle,
                onChange: handleChangeRegister,
                error: formErrors.badgeNumber,
                autoComplete: "off",
                value: formValue.badgeNumber || "",
              }}
            />
          </Col>

          <Col sm={12} lg={6} className="mt-3">
            <SelectE911
              placeholder={<div>Select User Role</div>}
              label="Select User Role"
              options={options}
              value={selectValue}
              customStyle={selectStyle}
              // defaultValue={options[0]}
              onChange={(selected) => handleChangeSelect(selected, "userRole")}
              classNamePrefix="filter"
              error={formErrors.userRole}
            />
          </Col>

          <Col md={6} sm={12} className="mt-3 d-flex justify-content-center">
            <ButtonE911
              data={{
                onClick: handleClickRegister,
                label: "REGISTER",
              }}
            />
          </Col>
        </div>
      </Container>
    </div>
  );
};
export default Register;
