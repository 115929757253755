import React from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { Container } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import FalseMarker from "../../assets/markers/FalseMarker.png";
import VictimMarker from "../../assets/markers/VictimMarker.png";
import MedicalMarker from "../../assets/markers/MedicalMarker.png";
import FireMarker from "../../assets/markers/FireMarker.png";
import C3vlarMarker from "../../assets/markers/C3vlarMarker.png";
import FooterE911 from "../../components/footer/";
import "../../styles/scss/faq.scss";

const Faq = () => {
  return (
    <>
      <Container className="faqContainer">
        <h2 className="headline">E911 Live FAQ</h2>
        <Accordion ActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              When will I be able to Live Stream into the E911Live website for
              First Responders?
            </Accordion.Header>
            <Accordion.Body>
              We are currently undergoing pilot programs with first responders
              and once those are concluded and certified, we will be releasing
              www.E911live.com in the U.S.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              How can I obtain access to the mapping system?
            </Accordion.Header>
            <Accordion.Body>
              Your supervisor must authorize your use for e911Live.com by
              establishing an account for you and the permissions you are being
              granted. Your email account and your mobile phone number is
              required for two factor authentication.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Why is two factor authentication being required?
            </Accordion.Header>
            <Accordion.Body>
              e911Live.com is ONLY to be used by first responders. It is not
              designed for the press or other non-authorized users. We require
              this second level of security to ensure the site is used for its
              intended purpose.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              How do I set up my Global GPS Device to work with the mapping
              system?
            </Accordion.Header>
            <Accordion.Body>
              First, from the dashboard page, download the GPS receiver software
              onto your laptop. Upon completion, plug the device into your USB
              Port and wait for the red light to begin flashing. Your laptop is
              now set for location services.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              Do I need to update my Manage Account page?
            </Accordion.Header>
            <Accordion.Body>
              Yes, especially the selection of the loud or soft sounds your
              system emits when an alert is received. The loud sound is
              extremely loud, and you may want to select the soft sound. The
              loud sound was designed for when you are away from your laptop.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              On the mapping page, what does the blue dot represent?
            </Accordion.Header>
            <Accordion.Body>
              The blue dot represents your location.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              What does the red line signify on the mapping system?
            </Accordion.Header>
            <Accordion.Body>
              This line represents the County or Borough you are in. Only
              emergencies that occur within that County or Borough will appear
              on your mapping system.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              What if I drive out of the County or Borough I was in?
            </Accordion.Header>
            <Accordion.Body>
              The mapping system will automatically change to the new County or
              Borough you are in. Alerts within that County or Borough will
              begin appearing on the mapping system automatically. If you are in
              pursuit of someone, the mapping system will continue to follow the
              person in the emergency regardless of how far they travel.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              What if I do not want to see the entire County or Borough I am in?
            </Accordion.Header>
            <Accordion.Body>
              You may Zoom in or out of specific locations on the map for now.
              We are currently working on Geo Fencing which will become
              automatically available to all first responders when we have fully
              tested and released it.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>
              How do I retrieve drive directions to the emergency location?
            </Accordion.Header>
            <Accordion.Body>
              Click on the icon displayed for the emergency. There is a link
              directly under the information. Once you click that link, you will
              be routed to our directional services page where step by step
              driving instruction will be presented to you.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>
              How do I listen to live streaming audio from a phone alert, or how
              do I hear and see live audio and video from Soteria?
            </Accordion.Header>
            <Accordion.Body>
              On the left-hand side, you will see a live audio button for mobile
              devices. Click to play and you will hear everything happening in
              an emergency. If the alert is coming from a fixed location device,
              you will see a picture of the emergency and you may click on the
              play button to see and hear everything happening in the emergency.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="11">
            <Accordion.Header>
              How long are past alerts available to view?
            </Accordion.Header>
            <Accordion.Body>
              The past alerts are available for 24 hours.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="12">
            <Accordion.Header>What do the icons represent?</Accordion.Header>
            <Accordion.Body>
              <div className="d-flex align-items-center">
                <p>
                  • This icon{" "}
                  <img
                    style={{ width: "25px", margin: "5px" }}
                    src={VictimMarker}
                    alt="false marker icon"
                  />
                  represents an emergency alert from a phone. Live streaming of
                  audio may be present with this alert.
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p>
                  • This icon
                  <img
                    style={{ width: "25px", margin: "5px" }}
                    src={MedicalMarker}
                    alt="victim marker icon"
                  />
                  represents a medical emergency alert from a phone. Live
                  streaming of audio may be present with this alert.
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p>
                  • This icon{" "}
                  <img
                    style={{ width: "25px", margin: "5px" }}
                    src={FireMarker}
                    alt="victim marker icon"
                  />
                  represents a fire emergency alert from a phone. Live streaming
                  of audio may be present with this alert.
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p>
                  • This icon
                  <img
                    style={{ width: "25px", margin: "5px" }}
                    src={FalseMarker}
                    alt="victim marker icon"
                  />
                  represents the user has canceled the alert and no longer is
                  requiring assistance.{" "}
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p>
                  • This icon{" "}
                  <img
                    style={{ width: "25px", margin: "5px" }}
                    src={C3vlarMarker}
                    alt="victim marker icon"
                  />
                  represents an emergency alert from our device in a fixed
                  location. Live streaming of audio and video is available for
                  this alert.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="13">
            <Accordion.Header>
              What are levels assigned and by whom on the access control
              webpage?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <strong> • Super Admin </strong>(Covert Alert senior staff) –
                mapping, access control, sign up for the admin.
              </p>
              <p>
                <strong> • Admin </strong>(official of first responders) –
                mapping, access control (limited access) , contact number set up
                for first responders.
              </p>
              <p>
                <strong> • First responder</strong> – mapping privileges only.
                <strong style={{ color: "red", fontWeight: "600" }}>
                  Note:
                </strong>
                If a person has admin privileges and the person he or she is
                searching for has super admin privileges, they won't be able to
                modify anything. They will receive and error message stating,
                “You don't have any permissions.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="14">
            <Accordion.Header>
              How will I know if a user has been set up or not?
            </Accordion.Header>
            <Accordion.Body>
              If a person has admin privileges and the searched for user does
              not have any privileges, they will be able to grant privileges to
              the searched for user (access control, mapping, signup, contact
              number). It is important to add an email address and cell phone
              number for every user.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="15">
            <Accordion.Header>
              What happens if 2 people in an organizations has Admin Privileges?
            </Accordion.Header>
            <Accordion.Body>
              If a person has admin privileges and the searched for user also
              has admin privileges, then they will not be able to change
              privileges of the other Admin (access control, mapping, contact
              number). Admin privileges can only be changed by Super Admin.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="16">
            <Accordion.Header>
              Who can sign up new users into E911Live system?
            </Accordion.Header>
            <Accordion.Body>
              A person who has Super Admin privileges can sign up an Admin into
              the 1st2911 system. The Admin can then sign up first responders.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="17">
            <Accordion.Header>
              How do I set my geofence radius?
            </Accordion.Header>
            <Accordion.Body>
              In the upper left-hand top side of your screen, you will see a
              sliding scale that can be adjusted from 0 to 25 miles. By sliding
              the bar in either direction, you can set the area of the map you
              want to monitor for emergency alerts.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="18">
            <Accordion.Header>
              Do all alerts appear on the map, or just those that occur in my
              set radius?
            </Accordion.Header>
            <Accordion.Body>
              Only alerts will appear for you in the geofenced radius you
              select. Alerts outside of your geofence do not appear.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="19">
            <Accordion.Header>
              If a user sends an emergency alert from Soteria, how do I access
              the facility layout?
            </Accordion.Header>
            <Accordion.Body>
              On the left-hand side of the screen, there is a facility layout in
              the center of the page. If the user has uploaded a floor plan,
              campus police will have access to it prior to arriving at the
              facility.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="20">
            <Accordion.Header>
              What do the total incidents number represent?
            </Accordion.Header>
            <Accordion.Body>
              This is the number of alerts that have occurred in your geofenced
              area during the time frame you have selected, either 15 minutes or
              24 hours.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="21">
            <Accordion.Header>
              How do I enable location services on my laptop/desktop?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <strong> For Mac users :</strong> Choose Apple menu
                <BsArrowRight />
                Click System Settings
                <BsArrowRight /> Click Privacy & Security on the sidebar
                <BsArrowRight />
                Turn on Location Services.
              </p>
              <p>
                <strong>For Windows users : </strong>Go to Start{" "}
                <BsArrowRight />
                Select Settings
                <BsArrowRight />
                Click on Privacy on the left side
                <BsArrowRight /> Select Location <BsArrowRight /> Turn on
                Location Services.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <p className="MoreInfo">
          If you have any question that is not listed under FAQ section. Please
          contact us <Link to="/contact"> here </Link> or give us a call at
          (317) 285-0785.
        </p>
      </Container>
      <FooterE911 />
    </>
  );
};
export default Faq;
