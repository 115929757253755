/*
    Author: Reshma Ghanta
    Desc: Reusable Dropdown component.
*/
import React from "react";
import Select from "react-select";
import TextE911 from "./TextE911";
import { ErrorMessageStyle } from "../../styles/customStyle";

const SelectE911 = (props) => {
  //console.log(props);
  const {
    onChange,
    options,
    defaultValue,
    value,
    customStyle,
    disabled,
    label,
    placeholder,
    error,
  } = props;

  return (
    <>
      <label>{label}</label>
      <Select
        styles={customStyle}
        options={options?.map((options, index) => ({
          value: options.value,
          label: options.label,
          id: index,
        }))}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder={placeholder}
        isDisabled={disabled}
        required
      />
       <TextE911
          data={{ text: error, customStyle: ErrorMessageStyle }}
        ></TextE911>
    </>
  );
};

export default SelectE911;
