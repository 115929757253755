
/*
    Author: Reshma Ghanta
    Desc: Firebase Configuration for E911live project.
          Configuring Authentiation, database and storage to covert-alerte650d project and c3vlar360 project using secondary instance
*/
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import {getStorage} from "firebase/storage";
import { initializeFirestore} from "firebase/firestore";

// Initialize  E911Live project.
 const E911_app=initializeApp({
  apiKey: process.env.REACT_APP_E911_FIREBASE_API_KEY_E911LIVE,
  authDomain: process.env.REACT_APP_E911_FIREBASE_AUTH_DOMAIN_E911LIVE,
  databaseURL: process.env.REACT_APP_E911_FIREBASE_DATABASE_URL_E911LIVE,
  projectId: process.env.REACT_APP_E911_FIREBASE_PROJECT_ID_E911LIVE,
  storageBucket: process.env.REACT_APP_E911_FIREBASE_STORAGE_BUCKET_E911LIVE,
  messagingSenderId: process.env.REACT_APP_E911_FIREBASE_MESSAGING_SENDER_ID_E911LIVE,
});

/* export const E911_firestore=initializeFirestore(E911_app,{
  experimentalForceLongPolling: true,
}); */
//console.log(E911_firestore);



//Get the default auth for E911Live app
export const E911_auth=getAuth(E911_app);

// Get the default database instance for E911Live app
export const E911_db=getDatabase(E911_app);

export const E911_storage=getStorage(E911_app);
//console.log(E911_storage)


/* if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'production') {
  E911_auth.settings.appVerificationDisabledForTesting = true;
} */

//console.log("E911_auth settings:", E911_auth.settings.appVerificationDisabledForTesting);

//Initialize Covertalert-e650d project
const CA_app=initializeApp({
  apiKey: process.env.REACT_APP_E911_FIREBASE_API_KEY_E650D,
  authDomain: process.env.REACT_APP_E911_FIREBASE_AUTH_DOMAIN_E650D,
  databaseURL: process.env.REACT_APP_E911_FIREBASE_DATABASE_URL_E650D,
  storageBucket: process.env.REACT_APP_E911_FIREBASE_STORAGE_BUCKET_E650D,
},'CA_app');

//Get the secondary auth for Covertalert-e650d app
export const CA_auth=getAuth(CA_app);

//Get the database instance for CovertAlert-e650d app
export const CA_db=getDatabase(CA_app);

//Get the storage instance for CovertAlert-e650d app
export const CA_storage=getStorage(CA_app);

//Initialize C3VLAR360 project
const Soteria_app=initializeApp({
  apiKey: process.env.REACT_APP_E911_FIREBASE_API_KEY_SOTERIA,
  authDomain: process.env.REACT_APP_E911_FIREBASE_AUTH_DOMAIN_SOTERIA,
  databaseURL: process.env.REACT_APP_E911_FIREBASE_DATABASE_URL_SOTERIA,
  storageBucket: process.env.REACT_APP_E911_FIREBASE_STORAGE_BUCKET_SOTERIA,
},'Soteria_app');

//Get the secondary auth for C3VLAR360 app
export const Soteria_auth=getAuth(Soteria_app);

//Get the database instance for C3VALR360 app
export const Soteria_db=getDatabase(Soteria_app);

//Get the storage instance for C3VALR360 app
export const Soteria_storage=getStorage(Soteria_app);

export default E911_app;
