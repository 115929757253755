/*
    Author: Reshma Ghanta/ Lamiaa Moustafa
    Desc: Login page for e911live
          This page allows you to login to the e911 system.
          Importing the small components and React hooks that are necessary.
          Importing Links into react dom router.
          User will be able to login after validating the credentials.
    Desc: Styling using React Bootstrap and importing elements from the grid System
*/

import React, { useState } from "react";
import FadeIn from "react-fade-in";
import FormInputE911 from "../../components/reusableComponents/FormInputE911";
import ButtonE911 from "../../components/reusableComponents/ButtonE911";
import SloganE911 from "../../components/reusableComponents/SloganE911";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { styles } from "../../styles/MainStyled";
import { InputStyle } from "../../styles/customStyle";
import "../../styles/scss/login.scss";
import { UserAuth } from "../../contexts/AuthContext";
import CampusPolice from "../../assets/images/CampusPolice.png";
import { AuthErrorCodes } from "firebase/auth";
import ImageE911 from "../../components/reusableComponents/Image911";
import MoonLoader from "react-spinners/MoonLoader";

const LandingPage = () => {
  /*
    Author: Reshma Ghanta
    Desc: Storing form input values using React State
*/
  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
  });

  //Storing form input validation & Firebath auth validation errors
  const [formErrors, setFormErrors] = useState({});

  // Storing form values into an object.
  const handleChangeLogin = (event) => {
    setFormValue({ ...formValue, [event.target.name]: event.target.value });
  };

  //Auth context of sigin to E911live project
  const { signInE911, e911User } = UserAuth();
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);

  /*
    Author: Reshma Ghanta
    Desc:  Login to the system on user Click Enter key
*/
const handleKeyPress = (e) => {
  //console.log("handle key press is triggered")
  if (e.key === 'Enter' || e.key === 'Return') {
    handleClickLogin(e);
  }
};

  /*
    Author: Reshma Ghanta
    Desc: Handling Login button to login into 911 system.
          Do necessary validations for form inputs
*/
  const handleClickLogin = async (e) => {
    e.preventDefault(); // prevent page refresh

    // Display loader
    setLoading(true);

    //Validating form input values
    setFormErrors(validate(formValue));

    //If there are no errors from form input then validate firebase auth
    if (Object.keys(formErrors).length === 0) {
      //console.log(formValue);
       try {
         const user= await signInE911( formValue.email,formValue.password);
           if(user){
              console.log("User signed in:", user);
             // console.log(user.operationType);
             // console.log(user.additionalUserInfo.isNewUser)
              navigate("/authentication"); 
          } 

          /* if (user && user.operationType === "signIn" && user.additionalUserInfo.isNewUser === false) {
            console.log("User signed in:", user);
            if (e911User.multiFactor.enrolledFactors.length > 0) {
              console.log("mfa")
            } else {
                console.log("Error")
            }
          }  */
      } catch (e) {
        //Error codes from firebase authentication
        if (e.code === AuthErrorCodes.INVALID_PASSWORD) {
          setFormErrors({ password: "Wrong password. Try again!" });
        } else if (e.code === AuthErrorCodes.USER_DELETED) {
          setFormErrors({
            email:
              "The email you entered does not match our records. Try again!",
          });
        } else if (e.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
          setFormErrors({
            email:
              "The email you entered does not match our records. Try again!",
          });
        }
      } finally {
        // Hide loader regardless of success or failure
        setLoading(false);
    }
    } else {
      // Hide loader regardless of success or failure
      setLoading(false);
    }
  }; // End Login function



/*
    Author: Reshma Ghanta
    Desc:  validating for form inputs from user using Regex
*/
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.password) {
      errors.password = "Password is required!";
    } else if (values.password.length < 6) {
      errors.password = "Password must be atleast 6 characters";
    } else if (values.password.length > 10) {
      errors.password = "Password cannot exceed more than 10 characters";
    }
    return errors;
  };

  /*
    Author: Lamiaa Moustafa
    Desc: changing background image based on route.
  */
  const PoliceImage = {
    backgroundImage: `url(${CampusPolice})`,
  };

  /*
    Author: Reshma Ghanta
    Desc: Skeleton for Landing page using custom small components.
 */
  return (
    <div style={{ ...styles.LandingContainer, ...PoliceImage }}>
      <ImageE911 />
      <FadeIn>
        <Container
          fluid
          className="d-flex flex-column align-items-start justify-content-around"
        >
          <Row className="Homepage">
            <Col>
              <SloganE911
                data={{
                  text: "CONNECTED & ",
                }}
              ></SloganE911>
              <SloganE911
                data={{
                  text: "PROTECTED",
                }}
              ></SloganE911>
            </Col>

            <Col
              md={12}
              className="FormInput mt-3 w-100 d-flex
              flex-column align-items-start justify-content-center"
            >
              <FormInputE911
                data={{
                  name: "email",
                  type: "email",
                  placeholder: "EMAIL",
                  onChange: handleChangeLogin,
                  onKeyPress: handleKeyPress,
                  customStyle: InputStyle,
                  error: formErrors.email,
                }}
              />
              <div className="pass-wrapper">
                <FormInputE911
                  data={{
                    name: "password",
                    type: "password",
                    placeholder: "PASSWORD",
                    onChange: handleChangeLogin,
                    onKeyPress: handleKeyPress,
                    customStyle: InputStyle,
                    error: formErrors.password,
                  }}
                />
              </div>
              <div className="loader-wrapper">
              {loading && <MoonLoader color="#ff9b30" loading={loading} size={30} speedMultiplier={0.6}/>}
              </div>
            </Col>

            <Col
              xs={12}
              md={8}
              className="mt-4 d-flex flex-column align-items-center justify-content-center"
            >
              <Link className="linkStyle" to="/forgotpassword">
                Forgot Password?
              </Link>
              <ButtonE911
                data={{
                  onClick: handleClickLogin,
                  label: "LOGIN",
                }}
              />
            </Col>
          </Row>
        </Container>
      </FadeIn>
    </div>
  );
};

export default LandingPage;
