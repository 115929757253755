import React from "react";
import FadeIn from "react-fade-in";
import { styles } from "../../styles/MainStyled";
import {
  ErrorGraphicStyle,
  ErrorPageText,
  ErrorContainer,
  ElementCenter,
} from "../../styles/customStyle";
import { Container } from "react-bootstrap";
import ErrorGraphic from "../../assets/images/svgs/404Error.svg";
import ProfilePhotoE911 from "../../components/reusableComponents/ProfilePhotoE911";
import TextE911 from "../../components/reusableComponents/TextE911";
import ButtonE911 from "../../components/reusableComponents/ButtonE911";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../contexts/AuthContext";

function NotFound() {
  const { e911User } = UserAuth();
  const navigate = useNavigate();

  /*
      Author: Reshma Ghanta
      Desc: If the user already sign in navigate to dashboard else navigate to login page.
   */
  const handleClickSignIn = () => {
    if (e911User) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  };

  return (
    <div style={{ ...styles.LandingContainer }}>
      <FadeIn>
        <Container style={ErrorContainer}>
          <div style={ElementCenter}>
            <ProfilePhotoE911
              data={{ source: ErrorGraphic, customStyle: ErrorGraphicStyle }}
            />
            <TextE911
              data={{
                text: "Sorry, Page Not Found",
                customStyle: ErrorPageText,
              }}
            />
            <div className="d-flex justify-content-center align-items-center">
              <ButtonE911
                data={{ label: "SIGN IN", onClick: handleClickSignIn }}
              />
            </div>
          </div>
        </Container>
      </FadeIn>
    </div>
  );
}

export default NotFound;
