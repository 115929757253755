/*
    Author: Reshma Ghanta
    Desc: Reusable Text component.
*/
import React from "react";

const TextE911 = (props) => {
  const { text, customStyle } = props.data;
  return (
    <>
      <p style={customStyle}>{text}</p>
    </>
  );
};

export default TextE911;
