import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { InitialsCircleStyle } from "../../styles/customStyle";
import "../../styles/scss/nav.scss";
import { PopoverContainerStyle } from "../../styles/MainStyled";
import { UserData } from "../../contexts/GlobalContext";
import AccountPopup from "../../views/AccountPopup";

const popover = (
  <Popover className="PopStyle" id="popover-basic">
    <AccountPopup />
  </Popover>
);

const AvatarPopOver = () => {
  const { e911UserDetails } = UserData();
  const [initials, setInitials] = useState();


  /*
        Author: Reshma Ghanta
        Desc: Show the initials of the e911user logged in.
              Change the initials when the e911user details are changed. 
     */
  useEffect(() => {
    if (e911UserDetails) {
      let firstName = e911UserDetails.firstName;
      let lastName = e911UserDetails.lastName;
      // debugger
      if (
        firstName !== "" &&
        lastName !== "" &&
        firstName !== undefined &&
        lastName !== undefined
      ) {
        let data =firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
        setInitials(data);
      }
    }
  }, [e911UserDetails]);

  return (
    <div style={PopoverContainerStyle}>
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom"
        overlay={popover}
      >
        <Button style={InitialsCircleStyle}>{initials}</Button>
      </OverlayTrigger>
    </div>
  );
};
export default AvatarPopOver;
