/*
    Author: Lamiaa Moustafa
    Desc: Reusable Image component.
*/
import React from "react";
import "../../styles/scss/nav.scss";
import useMediaQuery from "../../hooks/useMediaQuery.jsx";
import CaLogo from "../../assets/images/svgs/Logo.svg";

const ImageE911 = () => {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  return (
    <div
      style={{
        padding: isDesktop ? "0 50px " : "0 10px",
      }}
    >
      <img className="CaLogo" src={CaLogo} alt="" />
    </div>
  );
};

export default ImageE911;
