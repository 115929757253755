import React from "react";
// import { FiEdit } from "react-icons/fi";
import { GiSightDisabled } from "react-icons/gi";
import Table from "react-bootstrap/Table";
import EditModal from "../editModal";

const TableComponent = () => {
  return (
    <>
      <Table className="tableStyle" responsive bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>BADGE NUMBER</th>
            <th>EMAIL ADDRESS</th>
            <th>CELL PHONE</th>
            <th>ROLE</th>
            <th>EDIT</th>
            <th>DISABLE</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              {/* <FiEdit className="EditIcon" />  */}
              <EditModal />
            </td>
            <td>
              <GiSightDisabled className="DisableIcon" />
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>3</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
export default TableComponent;
