import React from "react";
// import { useNavigate } from "react-router-dom";
import ButtonMailto from "../../../components/reusableComponents/MailToE911";
import { Container } from "react-bootstrap";
import "../../../styles/scss/invite.scss";
//import { ExpiredLink } from "../../../styles/customStyle";

const ExpirationLink = () => {
  // const navigate = useNavigate();
  return (
    <Container fluid className="InviteContainer">
      <div className="ExpiredBox ">
        <img
          className="LogoInvite"
          src={require("../../../assets/brand/Logo.png")}
          alt="covert alert logo"
        />
        <h3>Link is Expired</h3>
        <h6 className="expiredLink">
          The invitational link expires after 24 hours, contact the Covert Alert
          team to send a new register link. Please call this number (317)
          285-0785 or send email to info@covertalert.ai.
        </h6>
        <ButtonMailto
          label="Send E-Mail"
          mailto="mailto:info@covertalert.ai?subject=E911 Live Expiration Link"
        />
      </div>
    </Container>
  );
};
export default ExpirationLink;
