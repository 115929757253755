/*
    Author: Reshma Ghanta
    Desc: Dashboard/MappingContainer page for e911live
          This page allows you to download the external GPS software.
          Importing the small components and React hooks that are necessary.
*/
import React, {useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import Mapping from "./Mapping";
import "../../styles/scss/mapping.scss";
import Sidebar from "./Sidebar";

/*
    Author: Reshma Ghanta
    Desc: Create the state variable to inititate the data sharing between the components
          Parent to child, child to parent, grant child to grand parent, grand parent to grand child data sharing is implemented.
 */
const MappingContainer = () => {

const [selectedTimeStamp, setSelectedTimeStamp] = useState({ 
    currentTime:"",
    previousTime:""
 });

 const [select24hrsRange, setSelect24hrsRange] = useState({ 
  incidentsListShow:false
}); 

 const [appsCount,setAppsCount]=useState(0);
 const [devicesCount,setDevicesCount]=useState(0);

 const [geofenceRadius,setGeofenceRadius]=useState(10);


  return (
    <Container fluid className="MappingContainer">
      <Row>
        <Col
          xs={{ span: 12, order: 2 }}
          md={{ span: 12, order: 2 }}
          lg={{ span: 3, order: 1 }}
          xxl={{ span: 2, order: 1 }}
          className="sideBarContainer"
        >
        <Sidebar {...{appsCount,devicesCount,setSelectedTimeStamp,setGeofenceRadius,select24hrsRange, setSelect24hrsRange}}/>
        </Col>
        <Mapping {...{setAppsCount,setDevicesCount,geofenceRadius,selectedTimeStamp}}/>
      </Row>
    </Container>
  );
};

export default MappingContainer;
