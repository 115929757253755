/*
    Author: Reshma Ghanta
    Desc: This page allows you to load the Landing components of e911Live system.
          When this page is called, Login is loaded by default 
          and other containers are loaded based on user request. 
          (Authentication, ForgotPassword, Faq, Contact )
*/
import React from "react";
import { Outlet } from "react-router-dom"; // Importing Outlet component from react-router-dom for nested routing
import FooterE911 from "../../components/footer";
const Landing = () => {
  return (
    <>
      <Outlet /> {/* Outlet component is used for rendering child routes */}
      <FooterE911 />{/*  Rendering FooterE911 component */}
    </>
  );
};

export default Landing;
