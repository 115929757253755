/*
    Author: Reshma Ghanta 
    Desc: This page allows you to set routing to all pages in e911Live system.
          Importing the components of all the pages.
          Importing Links, Routes into react dom router
*/
import React from "react";
import Routers from "./routes/Routers";
import { AuthContextProvider } from "./contexts/AuthContext";
import "./app.scss";
function App() {
  return (
    <>
      <AuthContextProvider>
        <Routers />
      </AuthContextProvider>
    </>
  );
}

export default App;
