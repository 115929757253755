import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import FormInputE911 from "../../../components/reusableComponents/FormInputE911";
import { InputStyle, ModalHeaderStyle } from "../../../styles/customStyle";
import "../../../styles/scss/access.scss";
import ButtonE911 from "../../../components/reusableComponents/ButtonE911";
import TextE911 from "../../../components/reusableComponents/TextE911";
import SelectE911 from "../../../components/reusableComponents/SelectE911";
import { EditUserSelect } from "../../../styles/customStyle";

function MyVerticallyCenteredModal(props) {
  const options = [
    { id: 1, label: "First Responders", value: "firstResponders" },
    { id: 2, label: "Admin", value: "Admin" },
    { id: 3, label: "Super Admin", value: "superAdmin" },
  ];

  const [formValue, setFormValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    badgeNumber: "",
    userRole: "firstResponders",
  });

  const handleChangeRegister = (event) => {
    setFormValue({ ...formValue, [event.target.name]: event.target.value });
  };

  const [selectValue, setSelectValue] = useState("");
  const handleChangeSelect = (selected) => {
    // setRegisterSuccess("");
    // console.log(selected.value);
    setSelectValue(selected);
    setFormValue({ ...formValue, userRole: selected.value });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <TextE911
          data={{ text: "Edit User Account", customStyle: ModalHeaderStyle }}
        />
        {/* <CloseButton variant="white" /> */}
      </Modal.Header>
      <Modal.Body>
        {/* <button type="button" class="btn-close" aria-label="Close"></button> */}
        <FormInputE911
          data={{
            name: "email",
            type: "email",
            label: "Email address",
            customStyle: InputStyle,
            onChange: "",
            error: "",
          }}
        />
        <FormInputE911
          data={{
            name: "phoneNumber",
            type: "number",
            label: "Phone Number",
            customStyle: InputStyle,
          }}
        />
        <FormInputE911
          data={{
            name: "badgeNumber",
            type: "text",
            label: "Badge or Identification Number",
            customStyle: InputStyle,
            onChange: handleChangeRegister,
            // error: formErrors.badgeNumber,
          }}
        />

        <Col lg={12} className="mt-1">
          <SelectE911
            value={selectValue}
            onChange={handleChangeSelect}
            label="Select User Role"
            options={options}
            defaultValue={options[0]}
            customStyle={EditUserSelect}
            classNamePrefix="filter"
          />
        </Col>

        <div className="d-flex justify-content-center">
          <ButtonE911 data={{ label: "EDIT" }} onClick={props.onHide}>
            SAVE
          </ButtonE911>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const EditModal = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      {/* <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button> */}
      <FiEdit className="EditIcon" onClick={() => setModalShow(true)} />

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default EditModal;
