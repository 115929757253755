/*
    Author:Lamiaa Moustafa
    Desc: GPS software receiver component.
*/

import React from "react";
import Button from "react-bootstrap/Button";
import "../../styles/scss/nav.scss";

const gpsBtnStyle = {
  borderRadius: "25px",
  color: "var(--white)",
  backgroundColor: "var(--orange)",
  marginRight: "20px",
  padding: "5px",
  border: "1px solid var(--white)",
  cursor: "pointer",
  minWidth: "220px",
};

const ReceiverSoftware = (props) => {
  const { onClick, label } = props.data;
  return (
    <Button style={gpsBtnStyle} onClick={onClick}>
      {label}
    </Button>
  );
  // }
};

export default ReceiverSoftware;
