import React, { createContext,useContext,useEffect,useState } from 'react';
import {ref,onValue,query,get,orderByChild,equalTo,limitToLast,} from  "firebase/database"
import {Soteria_db} from '../firebaseConfig'
//import { UserAuth } from "../contexts/AuthContext";
import io from "socket.io-client";
const SoteriaContext=createContext();


 //const socket = io.connect("http://192.168.1.139:7766");
 //const socket = io.connect("https://socketio.c3vlar360.com/");
  const socket = io.connect("https://socketio.c3vlar360.com/", {
  transports: ["websocket"],
}); 

 socket.on("connect", () => {
    //console.log("soteria socket connected");
  });
  
  socket.on("connect_error", (error) => {
    console.log("Connection error:(soteria)", error);
  });  



export const SoteriaContextProvider=({children})=>{
  //  const { e911User,soteriaInstance} = UserAuth();

    const [soteriaDetails,setSoteriaDetails]= useState({
       // latitude:"",
        //longitude:"",
        address:"",
        companyName:"",
        deviceLocation:"",
        floorPlanUrl:"",
        streamingInfo:{
        },
        soteriaShow:false,
        associatedUid:"",
        timeOfIncident:"", 
        deviceID:"",   
    });

    const [soteriaDataSocket,setSoteriaDataSocket]=useState([]);
    const [endSoteriaDataSocket,setEndSoteriaDataSocket]=useState([]);
    const [selectTimestamp, setSelectTimeStamp] = useState(null);
   
   

    useEffect(() => {
      socket.on('startSoteriaEmergency', (data) => {
        //console.log("Soteria socket")
        console.log(data);   
      });
  
    // Remove event listener on component unmount
      return () => socket.off('receive_message');
    }, [socket]);


      useEffect(() => {
            const handleDeviceEmergency = (data) => {
               //console.log("startSocket for test/localhost",data)
   
               // Check if the data object contains the deviceId field
              if (!data.deviceId) {
                console.error("Missing deviceId fi+eld in soteria data:", data);
                return; // Do not update soteriaDataSocket if deviceId field is missing
              }

               // Check if deviceId already exists in soteriaDataSocket
               const existingId = soteriaDataSocket.findIndex(item => item.deviceId === data.deviceId);
          
              if (existingId !== -1) {
                // If data.userId exists, replace the old data with the new data
                setSoteriaDataSocket(prevDeviceData => {
                  const updatedData = [...prevDeviceData];
                  updatedData[existingId] = data;
                  return updatedData;
                });
              } else {
                // If deviceId doesn't exist, add the new data to soteriaDataSocket
                setSoteriaDataSocket(prevDeviceData => [...prevDeviceData, data]);
              } 
          };    
           
          const handleEndDeviceEmergency = (data) => {
               //console.log("endSocket for test/localhost",data)
               console.log("End Device socket data",data)
               setEndSoteriaDataSocket(prevDeviceData => [...prevDeviceData, data]);
          };
  
            socket.on("startSoteriaEmergency", handleDeviceEmergency);
            socket.on("endSoteriaEmergency", handleEndDeviceEmergency);
            
            // Clean up the event listener when the component unmounts
            return () => {
              socket.off("startSoteriaEmergency", handleDeviceEmergency);
              socket.off("endSoteriaEmergency", handleEndDeviceEmergency);
            }; 
      }, [soteriaDataSocket, endSoteriaDataSocket]);
        


    const updateDeviceDetails = async (deviceId,timestamp,soteriaShow) => {    
        if(soteriaShow===true){
            const deviceInfoRef = ref(Soteria_db, `/devices/${deviceId}/deviceInformation`);     
               onValue(deviceInfoRef, (snapshot) => {
                       const deviceInfo = snapshot.val();
                       const updatedDeviceDetails = { ...soteriaDetails };
                        if(timestamp){
                            updatedDeviceDetails.timeOfIncident=new Date(timestamp).toString();
                          }
                        updatedDeviceDetails.deviceID=deviceId;
                        updatedDeviceDetails.floorPlanUrl=deviceInfo.floorPlanUrl;
                        updatedDeviceDetails.address=deviceInfo.address;
                        updatedDeviceDetails.streamingInfo=deviceInfo.streamingInfo.isStreaming;
                        updatedDeviceDetails.companyName=deviceInfo.companyName;
                        updatedDeviceDetails.deviceLocation=deviceInfo.deviceLocation;
                        updatedDeviceDetails.soteriaShow=true;
                    setSoteriaDetails(updatedDeviceDetails);
                });
            }else{
                setSoteriaDetails({
                address:"",
                companyName:"",
                deviceLocation:"",
                floorPlanUrl:"",
                streamingInfo:{
                },
                soteriaShow:false,
                associatedUid:"",
                timeOfIncident:"", 
                deviceID:"",  });
            }
          };
         

  const updateDeviceData = (deviceId) => {
            // console.log(soteriaDataSocket)
              
             // Check if any element in the array matches deviceId
           const foundIndex = soteriaDataSocket.findIndex(item => item.deviceId === deviceId);
           
           if (foundIndex !== -1) {
             // Remove the element from the array
             setSoteriaDataSocket(soteriaDataSocket.filter((_, index) => index !== foundIndex));
           } 
};
           
const updateEndDeviceData = (deviceId) => {
             //console.log(endSoteriaDataSocket)
          
             // Check if any element in the array matches deviceId
           const foundIndex = endSoteriaDataSocket.findIndex(item => item.deviceId === deviceId);
           
           if (foundIndex !== -1) {
             // Remove the element from the array
             setEndSoteriaDataSocket(endSoteriaDataSocket.filter((_, index) => index !== foundIndex));
           } 
};

    
        useEffect(()=>{
          //  if(selectTimestamp===null||selectTimestamp==='activeEmergencies'){
            const streamingDevicesQuery = query(ref(Soteria_db, '/devices'),orderByChild('deviceInformation/streamingInfo/isStreaming'),equalTo(true)); 
            get(streamingDevicesQuery).then((snapshot) => {
                if (snapshot.exists()) {
                    snapshot.forEach((childSnapshot) => {
                        const deviceID = childSnapshot.key;
                 
                    // Query for the last node added to "latLongTimeStamp"
                    const lastNodeQuery = query(ref(Soteria_db, `/devices/${deviceID}/latLongTimeStamp`),orderByChild("time"),limitToLast(1));
                    get(lastNodeQuery).then((snapshot) => {
                        if(snapshot.val()){
                                snapshot.forEach((nodeSnapshot) => {
                                 // console.log(nodeSnapshot.val());
                                const deviceLastNodeData={
                                    timeStamp: nodeSnapshot.val().time,
                                    deviceId: deviceID,
                                } 
                               setSoteriaDataSocket((prevDeviceData) => [...prevDeviceData, deviceLastNodeData]);
                                 });
                            } else{
                            console.log("Device does not have latlongtimestamp node")
                            }
                        }).catch((error) => {
                            console.log("Error querying for the last node: ", error);
                        });          
                    }); 
                } 
              }).catch((error) => {
                     console.error("Error getting streaming devices: ", error);
                    });
                //  }
            },[]) ;
    return(
        <SoteriaContext.Provider value={{soteriaDataSocket,updateDeviceDetails,soteriaDetails,endSoteriaDataSocket,updateDeviceData,updateEndDeviceData}}>
        {children}
        </SoteriaContext.Provider>
    )
}

export const SoteriaData=()=>{
    return useContext(SoteriaContext);
}


