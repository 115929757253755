import React, { useState } from "react";
import ModalE911 from "../../../components/reusableComponents/ModalE911";
import { UserAuth } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import ButtonE911 from "../../../components/reusableComponents/ButtonE911";

export const SuccessModal = (props) => {
  const { show } = props;
  const [showSuccess, setShowSuccess] = useState(show);
  const navigate = useNavigate();
  const { logoutE911 } = UserAuth();

   /*
   * Author: Reshma Ghanta
   * Desc:  Function to handle logout action
   */
   const handleClickLogout = async () => {
    await logoutE911(); // Call logoutE911 function for logging out user from E911 live project
    navigate("/"); // Navigate to the home page after logout
  };

  return (
    <div>
      <ModalE911
        show={showSuccess}
        handleClose={() => {
          setShowSuccess(false);
          navigate("/");
        }}
        handleShow={() => setShowSuccess(true)}
        modalTitle="Email Registration"
      >
        New user is successfully registered!
        <ButtonE911
          data={{
            onClick: handleClickLogout,
            label: "Ok",
          }}
        />
      </ModalE911>
    </div>
  );
};
