import React from "react";
import TimeStamp from "../TimeStamp";
import VictimDetails from "../VictimDetails";
import DeviceDetails from "../DeviceDetails";
import "../../../styles/scss/sidebar.scss";
import IncidentsList from "../IncidentsList";
import {VictimData} from "../../../contexts/E650DContext";
import { SoteriaData } from "../../../contexts/SoteriaContext";
/*
 Author: Reshma Ghanta
 Disc: Toggle between victim and soteria details using conditional rendering
*/
const Sidebar = ({
  appsCount,
  devicesCount,
  setSelectedTimeStamp,
  setGeofenceRadius,
  select24hrsRange,
  setSelect24hrsRange,
}) => {
  const { soteriaDetails} = SoteriaData();
  const {caVictimDetails}=VictimData();
  //console.log(soteria)
  //console.log(victim)
  //console.log(victim.victimShow)
  //console.log(soteriaDetails.soteriaShow)
  /* const [incidentsListShow, setIncidentsListShow] = useState(false);

  console.log(selectTimestamp)
  if(selectTimestamp==null){
    setIncidentsListShow(false);
  }
 else if(selectTimestamp==='Past24hours'){
    setIncidentsListShow(true)
  }else{
    setIncidentsListShow(false);
  } */
  
   
  return (
    <div className="p-2">
      <TimeStamp {...{appsCount,devicesCount,setSelectedTimeStamp,setGeofenceRadius,setSelect24hrsRange}}/>
      {caVictimDetails.victimShow && <VictimDetails/>}
      {soteriaDetails.soteriaShow && <DeviceDetails/>}
      {select24hrsRange.incidentsListShow && (<IncidentsList/> )} 
    </div>
  );
};

export default Sidebar;
