import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { InputStyle, InviteSignUp } from "../../../styles/customStyle";
import FormInputE911 from "../../../components/reusableComponents/FormInputE911";
import ButtonE911 from "../../../components/reusableComponents/ButtonE911";
import { UserAuth } from "../../../contexts/AuthContext";
import { E911_db } from "../../../firebaseConfig";
import "../../../styles/scss/invite.scss";
import { SuccessModal } from "./SuccessModal";
import {
  ref,
  get,
  set,
  remove,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";
import { useParams } from "react-router-dom";
//import { useNavigate } from "react-router-dom";
import { AuthErrorCodes } from "firebase/auth";
// import { SuccessModal } from "./SuccessModal";

const Invitation = () => {
  const { emailid } = useParams();
  //const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const { signUpE911 } = UserAuth();

  /*
    Author: Reshma Ghanta
    Desc: Storing form input values using React State
*/
  const [formValue, setFormValue] = useState({
    email: emailid,
    password: "",
  });

  /*
    Author: Reshma Ghanta
    Desc: Event handler for storing form values into a state object.
 */
  const handleChangeInvite = (event) => {
    setFormValue({ [event.target.name]: event.target.value });
  };

  /*
    Author: Reshma Ghanta
    Desc: Event handler for storing form values into a state object.
 */
  const handleClickSignUp = async (e) => {
    e.preventDefault(); // prevent page refresh

    let emailSignUp = emailid; //get email id from the url (user parameters)
    // console.log(emailSignUp);

    setFormErrors(validate(formValue)); //function for validating form input values and it returns form input errors
    var formInputErrors = validate(formValue); // Validate function returns an object with form input errors

    // Check if there are any errors in the form inputs or not
    if (Object.keys(formInputErrors).length === 0) {
      try {
        //Sign up new user to E911 Live project using the email (from params) and user entered password
        const userCrendential = await signUpE911(
          emailSignUp,
          formValue.password
        );
        // console.log(userCrendential);

        // Write a firebase query with E911live  project database instance
        // Check in tempMembers node, if there is any email that matches to email in the url (user params)
        const recentRef = query(
          ref(E911_db, `/tempMembers/`),
          orderByChild("email"),
          equalTo(emailSignUp)
        );
        // console.log(recentRef);

        get(recentRef).then((snapshot) => {
          if (snapshot.val() && snapshot.val() !== null) {
            let data = snapshot.val();
            let dataObj = Object.values(data);
            let dataKey = Object.keys(data)[0];

            if (userCrendential.user) {
              var newUid = userCrendential.user.uid;

              //Create database for new registered user using unique userID and form input values
              set(ref(E911_db, `/firstResponders/${newUid}`), dataObj[0]);

              // To remove tempMembers node
              const tempRef = ref(E911_db, "/tempMembers/" + dataKey);
              remove(tempRef)
                .then(function () {
                  //popup model for successfully registered
                  setShow(true);
                  // console.log("Remove succeeded.");
                  // navigate("/");
                })
                .catch(function (error) {
                  console.log("Remove failed: " + error.message);
                });
            }
          } else {
            console.log("data not available");
            // If data to the user is not available in tempMembers node, show error message
            setFormErrors({
              email:
                "Your email is tempered!! Please contact Covert Alert team",
            });
          } // end else block
        }); // end get query of firebase.
      } catch (e) {
        //Error codes from firebase authentication
        //console.log(e.code);
        if (e.code === AuthErrorCodes.EMAIL_EXISTS) {
          setFormErrors({
            email:
              "There is a user already registered with this email. Please contact Covert Alert team",
          });
        }
      } //end try-catch
    } // end if (form validation)
  }; // end handleClickSignUp

  /*
    Author: Reshma Ghanta
    Desc:  validating form inputs from user using Regex
*/
  const validate = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = "Password is required!";
    } else if (values.password.length < 6) {
      errors.password = "Password must be atleast 6 characters";
    } else if (values.password.length > 10) {
      errors.password = "Password cannot exceed more than 10 characters";
    }
    return errors;
  };

  return (
    <Container fluid className="InviteContainer">
      <div className="InviteBox ">
        <img
          className="LogoInvite"
          src={require("../../../assets/brand/Logo.png")}
          alt="covert alert logo"
        />
        <h3>Invitational Link</h3>
        <p>Create your new password to access E911 Live</p>
        <div className=" InviteInput">
          <FormInputE911
            data={{
              name: "Email",
              type: "email",
              label: "Email Address",
              customStyle: InputStyle,
              value: formValue.email,
              error: formErrors.email,
              disabled: true,
            }}
          />
        </div>
        <div className=" InviteInput">
          <FormInputE911
            data={{
              name: "password",
              type: "password",
              label: "New Password",
              customStyle: InputStyle,
              error: formErrors.password,
              onChange: handleChangeInvite,
            }}
          />
        </div>
        <ButtonE911
          data={{
            onClick: handleClickSignUp,
            label: "SIGNUP",
            customStyle: InviteSignUp,
          }}
        />
      </div>

      <div>{show && <SuccessModal show={show} />}</div>
    </Container>
  );
};

export default Invitation;
