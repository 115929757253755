import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import SelectE911 from "../../../components/reusableComponents/SelectE911";
import { selectStyle, InputStyle } from "../../../styles/customStyle";
import "../../../styles/scss/access.scss";
import FormInputE911 from "../../../components/reusableComponents/FormInputE911";

const SearchComponent = () => {
  const [formValue, setFormValue] = useState({
    email: "",
    phoneNumber: "",
    badgeNumber: "",
  });

  const [selectValue, setSelectValue] = useState("");
  const handleChangeSelect = (selected) => {
    // setRegisterSuccess("");
    console.log(selected.value);
    setSelectValue(selected);
    setFormValue({ ...formValue, userRole: selected.value });
  };
  const options = [
    { id: 1, label: "Badge ID", value: "BadgeId" },
    { id: 2, label: "Phone", value: "phone" },
    { id: 3, label: "Email", value: "email" },
  ];

  return (
    <>
      <Row className="searchContainer">
        <Col sm={12} md={4} className="searchFormInput">
          <SelectE911
            value={selectValue}
            onChange={handleChangeSelect}
            placeholder="Badge ID"
            options={options}
            defaultValue={options[0]}
            customStyle={selectStyle}
            classNamePrefix="filter"
          />
        </Col>
        <Col sm={12} md={8} className="AccessFormInput">
          <FormInputE911
            data={{
              name: "text",
              type: "text",
              placeholder: "Search...",
              customStyle: InputStyle,
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default SearchComponent;
