import React, { createContext,useContext,useEffect,useState } from 'react';
import {ref as dbRef,onValue,query,get,orderByChild,equalTo,limitToLast, startAt, endAt} from  "firebase/database"
import {CA_db} from '../firebaseConfig'
import io from "socket.io-client";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { UserAuth } from "../contexts/AuthContext";
import { CA_storage } from "../firebaseConfig";
import { ref as storageRef, getDownloadURL } from "firebase/storage";

const E650DContext=createContext();


/*
   Author: Reshma Ghanta
   Desc: 
 */

   //console.log("Testinnnggggg");
const socket = io.connect("https://socketio.c3vlar360.com/", {
    transports: ["websocket"],
  });
  
 socket.on("connect", () => {
    //console.log("victims socket connected");
  });
  
  socket.on("connect_error", (error) => {
    console.log("Connection error(victim):", error);
  }); 


export const E650DContextProvider=({children})=>{
  const { e911User,victimInstance} = UserAuth();
  //console.log(e911User);

    const [caVictimDetails,setCaVictimDetails]= useState({
        victimInformation:{   
        },
        victimShow:false,
        address:"",
        timeOfIncident:"", 
        speedTravel:"",
        victimId:"", 
        mostRecentUrl:"",
        liveUrl:"",
        liveStream:false
    });

    const [victimDataSocket,setVictimDataSocket]=useState([]);
    const [endVictimDataSocket,setEndVictimDataSocket]=useState([]);
   // const [historyData,setHistoryData]=useState([]);
     const [historyData, setHistoryData] = useState(() => {
          const storedHistoryData = localStorage.getItem('historyData');
          return storedHistoryData ? JSON.parse(storedHistoryData) : [];
    }); 
    const [dataFromIncidentList, setDataFromIncidentList] = useState(null);
    const [selectTimestamp, setSelectTimeStamp] = useState(null);
    
    // For test users (include this when deploying to e911live.com)
    const [testUsers,setTestUsers]=useState([
      "RD4F25tw0LPgr3p8LmPzZvCcssB2",
      "UygyLnpBkJM3Bh55b0P4cCcx3QG3",
      "zARvVWaqI6ZkxT6XSnUmUUMoJRr1",
      "KORtHjhMLwZ2GpMLiW8G2EywwCJ3",
      "w8epVaCIVQbFtHDU9RByvV7wxCd2",
      "plQ4E4OQvEVoOB7qDGVfdDq3jbk1",
      "0lTln2DcZ6cBgmeizVpQAsilLvq2",
      "9uUMkASLUzbFSfu5RyGQtcjrCSx1",
      "GFeaymOGdOMUq85znpnzNj0y7V43",
      "BP6PCmvnHrf97hXDVfCf7YBxTZH2",
    ]);

   // const hostname = window.location.hostname;
    //console.log(hostname);

    useEffect(() => {
      const hostname = window.location.hostname;

      if (hostname === 'www.e911live.com' || hostname === 'e911live.web.app') {
        // console.log("Checking Socket io")
        const handleUserEmergencyDetail = (data) => {
          //  console.log("startSocket for e911live.com ",data)

            // Check if the data object contains the userId field
            if (!data.userId) {
              console.error("Missing userId field in victim data:", data);
              return; // Do not update victimDataSocket if userId field is missing
            }

              // Check if data.userId exists in testUsers. if it exists then dont add that to map
              // For test users (include this when deploying to e911live.com)
           if (!testUsers.includes(data.userId)) {

             // Check if userId already exists in victimDataSocket
            const existingId = victimDataSocket.findIndex(item => item.userId === data.userId);
        
            if (existingId !== -1) {
              // If data.userId exists, replace the old data with the new data
              setVictimDataSocket(prevVictimData => {
                const updatedData = [...prevVictimData];
                updatedData[existingId] = data;
                return updatedData;
              });
            } else {
              // If userId doesn't exist, add the new data to victimDataSocket
              setVictimDataSocket(prevVictimData => [...prevVictimData, data]);
            } 
          
          } // For test users (include this when deploying to e911live.com)
        };   
         
        const handleEndUserEmergency = (data) => {
             //console.log("endSocket for e911live.com",data)
            setEndVictimDataSocket(prevVictimData => [...prevVictimData, data]);
        };

          socket.on("userEmergencyDetail", handleUserEmergencyDetail);
          socket.on("endUserEmergency", handleEndUserEmergency);
          
          // Clean up the event listener when the component unmounts
          return () => {
            socket.off("userEmergencyDetail", handleUserEmergencyDetail);
            socket.off("endUserEmergency", handleEndUserEmergency);
          };
        } else{

          const handleUserEmergencyDetail = (data) => {
            //console.log("startSocket for test/localhost",data)

               // Check if the data object contains the userId field
               if (!data.userId) {
                console.error("Missing userId field in victim data:", data);
                return; // Do not update victimDataSocket if userId field is missing
              }

             // Check if userId already exists in victimDataSocket
            const existingId = victimDataSocket.findIndex(item => item.userId === data.userId);
        
            if (existingId !== -1) {
              // If data.userId exists, replace the old data with the new data
              setVictimDataSocket(prevVictimData => {
                const updatedData = [...prevVictimData];
                updatedData[existingId] = data;
                return updatedData;
              });
            } else {
              // If userId doesn't exist, add the new data to victimDataSocket
              setVictimDataSocket(prevVictimData => [...prevVictimData, data]);
            } 
        };   
         
        const handleEndUserEmergency = (data) => {
             //console.log("endSocket for test/localhost",data)
            setEndVictimDataSocket(prevVictimData => [...prevVictimData, data]);
        };

          socket.on("userEmergencyDetail", handleUserEmergencyDetail);
          socket.on("endUserEmergency", handleEndUserEmergency);
          
          // Clean up the event listener when the component unmounts
          return () => {
            socket.off("userEmergencyDetail", handleUserEmergencyDetail);
            socket.off("endUserEmergency", handleEndUserEmergency);
          };
        }  
    }, [victimDataSocket, endVictimDataSocket]);

       //console.log(victimDataSocket)

       const reverseGeocoding = (longitude, latitude) => {
         return new Promise((resolve, reject) => {
           let url ="https://api.mapbox.com/geocoding/v5/mapbox.places/" +longitude + ", " +latitude +".json?access_token=" +mapboxgl.accessToken;
           fetch(url)
             .then((response) => {
               if (!response.ok) {
                 throw new Error("Unable to connect to Geocode API");
               }
               return response.json();
             })
             .then((data) => {
               if (data.features.length === 0) {
                 reject("Unable to find location. Try to search another location.");
               } else {
                 resolve(data.features[0].place_name);
               }
             })
             .catch((error) => {
               reject(error.message);
             });
         });
       };

       const updateCaVictimDetails = async (userId,timestamp,victimShow) => { 
        if(victimShow===true){    
        const trackingInfoRef = query(dbRef(CA_db, `/users/${userId}/victimTrackingInfo`));
        const streamInfoRef = query(dbRef(CA_db, `/users/${userId}/streamInfo`));
        const victimNameInfoRef = query(dbRef(CA_db, `/users/${userId}/victimInformation`));
        const recordingInfoRef = query(dbRef(CA_db, `/users/${userId}/recordings`),orderByChild('timeStamp'),equalTo(timestamp));
      
        
          let trackingInfoSnapshot = await get(trackingInfoRef);
          let streamInfoSnapshot = await get(streamInfoRef);
          let victimNameInfoSnapshot = await get(victimNameInfoRef);
          let recordingInfoSnapshot = await get(victimNameInfoRef);

          const updatedCaVictimDetails = { ...caVictimDetails };
      
          updatedCaVictimDetails.victimId=userId;

          const updateAddress = async () => {
            if (trackingInfoSnapshot.exists()) {
              const victimAddress = await reverseGeocoding(
                trackingInfoSnapshot.val().longitude,
                trackingInfoSnapshot.val().latitude
              );
              updatedCaVictimDetails.address = victimAddress;
            }
          };

              onValue(trackingInfoRef, (snapshot) => {
                    trackingInfoSnapshot = snapshot;
                    updatedCaVictimDetails.speedTravel = snapshot.val().speedTravel;
                    updateAddress();
              });
      
            
              onValue(victimNameInfoRef, (snapshot) => {
                victimNameInfoSnapshot = snapshot;
                updatedCaVictimDetails.victimInformation = snapshot.val();
              });


               if(selectTimestamp==='Past24hours'){
                //console.log("history data selected....");
                onValue(recordingInfoRef, (snapshot) => {
                  recordingInfoSnapshot = snapshot;

                  const recordingsObject = snapshot.val();
                  //console.log(recordingsObject);

                  if (recordingsObject) {
                  const recordingsValues = Object.values(recordingsObject);
                     //console.log(recordingsValues[0]);
                  const recordName = recordingsValues[0].recordName;
                  const recordingWasStreamed =recordingsValues[0].recordingWasStreamed;

                if(recordingWasStreamed){
                   const storageReference = storageRef(CA_storage, `Audio/${userId}/${recordName}`);
                  // console.log(storageRef);
                  getDownloadURL(storageReference).then((url) => {
                      //console.log(url);
                      updatedCaVictimDetails.mostRecentUrl=url;
                    }).catch((error) => {
                      // Handle any errors that may occur during URL retrieval
                      console.error("Error getting download URL:", error);
                    }); 
                  }
                  //updatedCaVictimDetails.recordingInfo = recordingsValues[0];
                } else {
                  console.log("Recordings not available from Database.");
                   }
                });
              } else {
                  onValue(streamInfoRef, (snapshot) => {
                    streamInfoSnapshot = snapshot;
                      if(snapshot.val()){
                        const liveStream = snapshot.val().isStreaming;
                        const liveUrl=snapshot.val().playbackUrl;
                        
                        updatedCaVictimDetails.liveStream=liveStream;

                         if(liveStream){
                           updatedCaVictimDetails.liveUrl=liveUrl;
                          }
                         /*   updatedCaVictimDetails.victimShow=true;
                         }else{
                          updatedCaVictimDetails.victimShow=false;
                         } */
                      }
                    // updatedCaVictimDetails.streamInfo = snapshot.val();
                  });
                     
              }

          if(timestamp){
            updatedCaVictimDetails.timeOfIncident=new Date(timestamp).toString();
          }
           updatedCaVictimDetails.victimShow=true;
             // console.log(updateCaVictimDetails);
          setCaVictimDetails(updatedCaVictimDetails);
        }else{
          setCaVictimDetails({ 
          victimInformation:{   
          },
          victimShow:false,
          address:"",
          timeOfIncident:"", 
          speedTravel:"",
          victimId:"", 
          mostRecentUrl:"",
          liveUrl:"",
          liveStream:false
         } );
        }
      };
      
      

/*
   Author: Reshma Ghanta
   Desc: 24 Hour history data.
 */
// Retrieve the victimInstance from local storage

useEffect(()=>{

  if (victimInstance|| selectTimestamp==='Past24hours'){
    //console.log(victimInstance);
   // console.log(typeof(selectTimestamp));
    // if(selectTimestamp==='Past24hours'){
      //console.log("hai")
    
    const currentTimeMilliSecs = Math.round(new Date().getTime());
    const last24hoursMilliSecs = currentTimeMilliSecs - (24 * 60 * 60 * 1000);

   const usersListQuery = query(dbRef(CA_db, '/users'));

   get(usersListQuery).then((snapshot) => {
      if (snapshot.exists()) {
        const newData = [];

        snapshot.forEach((childSnapshot) => {
              const userID = childSnapshot.key;
              if(childSnapshot.val().victimInformation){
              const victimFirstName = childSnapshot.val().victimInformation.firstName;
           
              const historyUsersQuery = query(dbRef(CA_db, `/users/${userID}/latLongTimeStamp`),orderByChild("timeStamp"),startAt(last24hoursMilliSecs),endAt(currentTimeMilliSecs));       
              get(historyUsersQuery).then((snapshot) => {
                //console.log(snapshot.val())
                if(snapshot.val()){
                
                      snapshot.forEach((nodeSnapshot) => {
                          //  const nodeKey = nodeSnapshot.key;
                          //  console.log(nodeSnapshot.val())
                           const is911enabled=nodeSnapshot.val().isAlert911Enabled;

                        if(is911enabled===true) {
                            const historyNodeData={
                              startLatitude: nodeSnapshot.val().startLatitude,
                              startLongitude: nodeSnapshot.val().startLongitude,
                              timeStamp: nodeSnapshot.val().timeStamp,
                              typeOfEmergency : nodeSnapshot.val().typeOfEmergency,
                              isFalseAlert : nodeSnapshot.val().isFalseAlert,
                              userId: userID,
                              firstName:victimFirstName,
                              typeOfData:"history"
                          }
                            //const historyNodeData = nodeSnapshot.val();
                          //console.log(historyNodeData)
                          newData.push(historyNodeData);
                         // localStorage.setItem('historyData', JSON.stringify(historyNodeData));

                      }else{
                           //console.log("no data")
                        }
                       
                   })

                   localStorage.setItem('historyData', JSON.stringify(newData));

                  // console.log(newData)
                   //setHistoryData(newData);
                }
                //console.log(newData)
               // setHistoryData(newData);
              })
              //console.log(newData)
            }
          }) 
          
        }else{
          console.log("No users found")
        }
      }) 
    }else{
      setHistoryData(null);
       // console.log("History data not selected")
    } 
 // }
},[victimInstance,selectTimestamp]) ;  

const updateHistoryDataClick = (data) => {
  setDataFromIncidentList(data);
};

const setTypeOfTimestamp = (data) =>{
 // console.log(data)
  setSelectTimeStamp(data);
}

const updateVictimData = (uid,typeOfEmergency) => {
 // console.log(victimDataSocket)
 
  // Check if any element in the array matches both userId and typeOfEmergency
const foundIndex = victimDataSocket.findIndex(item => item.userId === uid && item.typeOfEmergency === typeOfEmergency);

if (foundIndex !== -1) {
  // Remove the element from the array
  setVictimDataSocket(victimDataSocket.filter((_, index) => index !== foundIndex));
} 
};

const updateEndVictimData = (uid,typeOfEmergency) => {
  //console.log(endVictimDataSocket)
  //console.log("end victim data array",uid,typeOfEmergency)
 
  // Check if any element in the array matches both userId and typeOfEmergency
const foundIndex = endVictimDataSocket.findIndex(item => item.userId === uid && item.typeOfEmergency === typeOfEmergency);

if (foundIndex !== -1) {
  // Remove the element from the array
  setEndVictimDataSocket(endVictimDataSocket.filter((_, index) => index !== foundIndex));
} 
};

 useEffect(()=>{
//  console.log(e911User)
const hostname = window.location.hostname;

     
//  if (e911User !== null && (typeof e911User === 'object' && Object.keys(e911User).length !== 0)) {
  //if(e911User){
   // console.log(e911User)
// if(selectTimestamp===null||selectTimestamp==='activeEmergencies'){
const streamingUsersQuery = query(dbRef(CA_db, '/users'),orderByChild('streamInfo/isStreaming'),equalTo(true));

get(streamingUsersQuery).then((snapshot) => {
      //console.log(snapshot)
    if (snapshot.exists()) {
        snapshot.forEach((childSnapshot) => {
            const userID = childSnapshot.key;
            const userData = childSnapshot.val();
            const victim911AlertEnabled=userData.victimSettings.isAlert911Enabled;
           
            if (hostname === 'www.e911live.com'|| hostname === 'e911live.web.app') {
            // For test users (include this when deploying to e911live.com)
            if (!testUsers.includes(userID)) {
     
              if(victim911AlertEnabled===true){
             //console.log(userData)
        // Query for the last node added to "latLongTimeStamp"
        const lastNodeQuery = query(dbRef(CA_db, `/users/${userID}/latLongTimeStamp`),orderByChild("timeStamp"),limitToLast(1));

        get(lastNodeQuery).then((snapshot) => {
            //console.log(snapshot.val())
            if(snapshot.val()){
                    snapshot.forEach((nodeSnapshot) => {
                    //const nodeKey = nodeSnapshot.key;
                    const lastNodeData = nodeSnapshot.val();
                    //console.log("Last node in latlongtimestamp for the user:");
                    //console.log("Node ID: " + nodeKey);
                   //   console.log("Node Data: ", lastNodeData);
                    
                    const victimLastNodeData={
                        startLatitude: lastNodeData.startLatitude,
                        startLongitude: lastNodeData.startLongitude,
                        timeStamp: lastNodeData.timeStamp,
                        typeOfEmergency : lastNodeData.typeOfEmergency,
                        userId: userID,
                    }
                   // console.log(new Date(lastNodeData.timeStamp).toString())
                    //console.log(victimLastNodeData)
                    
                   setVictimDataSocket((prevVictimData) => [...prevVictimData, victimLastNodeData]);
                     });
                } else{
                console.log("User does not have latLongTimeStamp node")
                }
            }).catch((error) => {
                console.log("Error querying for the last node: ", error);
            }); 
      }else{
          console.log("Victim did not opt for 911 Streaming")
      }
       } // For testUsers (include this when deploying to e911live.com)
    }else{

        if(victim911AlertEnabled===true){
          //console.log(userData)
     // Query for the last node added to "latLongTimeStamp"
     const lastNodeQuery = query(dbRef(CA_db, `/users/${userID}/latLongTimeStamp`),orderByChild("timeStamp"),limitToLast(1));

     get(lastNodeQuery).then((snapshot) => {
         //console.log(snapshot.val())
         if(snapshot.val()){
                 snapshot.forEach((nodeSnapshot) => {
                 //const nodeKey = nodeSnapshot.key;
                 const lastNodeData = nodeSnapshot.val();
                 //console.log("Last node in latlongtimestamp for the user:");
                 //console.log("Node ID: " + nodeKey);
                //   console.log("Node Data: ", lastNodeData);
                 
                 const victimLastNodeData={
                     startLatitude: lastNodeData.startLatitude,
                     startLongitude: lastNodeData.startLongitude,
                     timeStamp: lastNodeData.timeStamp,
                     typeOfEmergency : lastNodeData.typeOfEmergency,
                     userId: userID,
                 }
                // console.log(new Date(lastNodeData.timeStamp).toString())
                 //console.log(victimLastNodeData)
                 
                setVictimDataSocket((prevVictimData) => [...prevVictimData, victimLastNodeData]);
                  });
             } else{
             console.log("User does not have latLongTimeStamp node")
             }
         }).catch((error) => {
             console.log("Error querying for the last node: ", error);
         }); 
   }else{
       console.log("Victim did not opt for 911 Streaming")
   }

      }
        }); 
    } else {
           // console.log("No users found with isStreaming: true");
        }
  }).catch((error) => {
         console.error("Error getting streaming users: ", error);
        });
     // }
},[e911User,selectTimestamp]) ;
 


    return(
        <E650DContext.Provider value={{ caVictimDetails,updateCaVictimDetails,victimDataSocket,endVictimDataSocket,historyData,updateHistoryDataClick,dataFromIncidentList,setTypeOfTimestamp,selectTimestamp,updateEndVictimData,updateVictimData}}>
        {children}
        </E650DContext.Provider>
    )
}

export const VictimData=()=>{
    return useContext(E650DContext)
}
