/*
 Lamiaa Moustafa
    Desc: Acess page for e911live
          This page allows super Admin & Admin Police to access privileges into the e911 system.
          Importing the small components and React hooks that are necessary.
    Desc: Styling using React Bootstrap and importing elements from the grid System.
*/
import React from "react";
import { Container } from "react-bootstrap";
import earthProtected from "../../assets/images/svgs/earthProtected.svg";
import { styles } from "../../styles/MainStyled";

import "../../styles/scss/access.scss";
import TableComponent from "./tableComponent";
import SearchComponent from "./searchComponent";

const AccessControl = () => {
  /*
    Author: Lamiaa Moustafa
    Desc: changing background image based on route.
  */
  const earthContactImage = {
    backgroundImage: `url(${earthProtected})`,
  };
  return (
    <div style={{ ...styles.LandingContainer, ...earthContactImage }}>
      <Container className="AccessContainerStyle">
        <SearchComponent />
        <TableComponent />
      </Container>
    </div>
  );
};
export default AccessControl;
