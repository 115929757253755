/*
Author: Reshma Ghanta/Lamiaa Moustafa
Desc: Styling using React Bootstrap and importing elements from the grid System
*/

import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, } from "react-bootstrap";
import { UserAuth } from "../../contexts/AuthContext";
import FadeIn from "react-fade-in";
//import ModalE911 from "../../components/reusableComponents/ModalE911";
import SloganE911 from "../../components/reusableComponents/SloganE911";
import ButtonE911 from "../../components/reusableComponents/ButtonE911";
import RadioButtonE911 from "../../components/reusableComponents/RadioButtonE911";
import FormInputE911 from "../../components/reusableComponents/FormInputE911";
import { styles } from "../../styles/MainStyled";
import {
  codeBoxStyle,
//  addressManualStyle,
 // locationBtn,
} from "../../styles/customStyle";
import "../../styles/scss/radio.scss";
import "../../styles/scss/access.scss";
import earthClear from "../../assets/images/svgs/earthClear.svg";
import ImageE911 from "../../components/reusableComponents/Image911";
import { UserData } from "../../contexts/GlobalContext";
//import { UserAuth } from '../../contexts/AuthContext';
//import { CgSpinner } from "react-icons/cg"

//import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
//import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

//mapboxgl.accessToken = process.env.REACT_APP_E911_MAPBOX_ACCESSKEY;
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  multiFactor,
} from "firebase/auth";
import { E911_auth } from "../../firebaseConfig";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const Authentication = () => {
  const { logoutE911 , e911User } = UserAuth();
  const navigate = useNavigate();
  const { e911UserDetails } = UserData();
  
 // const { setTypeOfLocationSettings } = UserData();
 

/*
    Author: Reshma Ghanta
    Desc: Handling 2FA options (radio buttons) based on user input.
*/

const [selectedValue, setSelectedValue] = useState("");
const [userEmail, setUserEmail]=useState();
const [userPhone, setUserPhone]=useState();
//const [loading, setLoading]=useState(true);
const [verificationId, setVerificationId] = useState("");
const [code, setCode] = useState("");

useEffect(() => {
   if (e911UserDetails) {
     setUserEmail(e911UserDetails.email);
     setUserPhone(e911UserDetails.phoneNumber);
   }
 }, [e911UserDetails]);

  /*
      Author: Reshma Ghanta
      Desc:  Function to handle logout action
   */
  const handleClickLogout = async (e) => {
    try {
      localStorage.removeItem("victimInstance");
      localStorage.removeItem("soteriaInstance");
      localStorage.removeItem("historyData");
      await logoutE911(); // Call logoutE911 function for logging out user from E911 live project
      navigate("/"); // Navigate to the home page after logout
    } catch (err) {
      console.error("Error while logging out", err);
    }
  };

  // const handleClickVerify = () => {
  //   navigate("/dashboard");
  // };

  const handleRadioChange = async(event) => {
    const value = event.target.value;
    setSelectedValue(value);
     
    if (value === 'phone') {
      try {
        const formattedPhoneNumber = formatPhoneNumber(userPhone);
        await sendPhoneVerification(formattedPhoneNumber);
      } catch (error) {
        console.error("Error formatting phone number:", error);
      }
    } else if(value === 'email'){
      sendEmailVerification(userEmail)
    }
  };
  
 // const [modalShow, setModalShow] = useState(false);

   // Initialize state with value from localStorage if it exists
 /*   const [address, setAddress] = useState(() => {
    return localStorage.getItem('manualAddress') || "";
  }); */

 

 
  function sendEmailVerification(email){
       alert("Email verification under production")
  }

 // console.log("Environment:", process.env.NODE_ENV);
 // console.log(RecaptchaVerifier);


 const formatPhoneNumber = (phoneNumber) => {
  const phoneNumberParsed = parsePhoneNumberFromString(phoneNumber, 'US'); // replace 'US' with the correct country code
  if (phoneNumberParsed && phoneNumberParsed.isValid()) {
    return phoneNumberParsed.format('E.164');
  } else {
    throw new Error('Invalid phone number');
  }
};

  /*
    Author: Reshma Ghanta
    Desc: Function to send OTP for phone verification.
*/
const sendPhoneVerification = async (phoneNumber) => {
  console.log("Two factor authentication",phoneNumber)
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    console.log(formattedPhoneNumber)
 try {
 
    if (!document.getElementById('recaptcha-container')) {
        throw new Error("recaptcha-container element not found");
    }

  const appVerifier = new RecaptchaVerifier(E911_auth, 'recaptcha-container', { size: 'invisible',
    callback: (response) => {
      //console.log("reCAPTCHA solved:", response);
    },
    'expired-callback': () => {
      console.log("reCAPTCHA expired");
    }
   });
   const phoneAuthProvider = new PhoneAuthProvider(E911_auth);
   console.log(phoneAuthProvider)
   const verificationId = await phoneAuthProvider.verifyPhoneNumber(formattedPhoneNumber, appVerifier);
   console.log(verificationId)
   setVerificationId(verificationId);
   console.log("OTP sent to phone:", verificationId);
 
} catch (error) {
  console.error("Error during phone verification:", error);
  throw error;
}
};

/*
  Author: Reshma Ghanta
  Desc: Verify the OTP entered by the user.
*/

const verifyPhoneCode = async () => {
  try {
    const credential = PhoneAuthProvider.credential(verificationId, code);
    await multiFactor(E911_auth.currentUser).enroll(credential, 'phone');
    console.log("Phone verification successful");
     navigate("/dashboard");
  } catch (error) {
    console.error("Error verifying phone code:", error);
    console.error("Verification failed. Please check the OTP and try again.");
  }
};

 

  const handleSendCodeAgain = async(event) => {
    event.preventDefault(); // Prevent the default link behavior
    if (selectedValue === 'email') {
      sendEmailVerification(userEmail);
    } else if (selectedValue === 'phone') {
      sendPhoneVerification(userPhone);
     /*  try {
        const verificationId = await sendPhoneVerification(userPhone);
        setVerificationId(verificationId);
      } catch (error) {
        console.error("Error sending phone verification:", error);
      } */
    }
  };

  const handleClickVerify = async() => {
    //  navigate("/dashboard");

    if (selectedValue === 'phone') {
    verifyPhoneCode();
  } else {
       navigate("/dashboard");
  }  

     // Write 2FA verficiation login and then proceed to modal
     //setModalShow(true);
     /*  try {
      if (selectedValue === 'phone'&& verificationId && code) {
       // await verifyPhoneCode(verificationId, code);
       await verifyPhoneCode(verificationId, code);
     //  navigate("/dashboard");
        console.log("2FA successful")
        //navigate("/dashboard");
      }
    } catch (error) {
      console.error("Error verifying code:", error);
    }  */
   };

   const handleChangeCode = (event) => {
    setCode(event.target.value);
  };

 /* const handleClickOk = () => {
    navigate("/dashboard");
  };

  const handleChangeAddress=(event) =>{
    const newAddress = event.target.value;
    setAddress(newAddress);
       localStorage.setItem('manualAddress',newAddress);
  } */

/* function getAddress(){
    const liveMarker = document.createElement('div');
    liveMarker.className = 'livemarker';

  var geocoder =  new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    mapboxgl: mapboxgl,
    marker: {
      element: liveMarker,
      draggable: false, // Set draggable to true if you want to allow dragging the marker
      offset: [-15, -30] // Adjust the offset if needed
  }
});


geocoder.on('result', function(e) {
  // Get the geocoded location
  var location = e.result.geometry.coordinates;
  console.log(location);
  
});

}; */


  /*
    Author: Lamiaa Moustafa
    Desc: changing background image based on route.
*/
  const earthImage = {
    backgroundImage: `url(${earthClear})`,
  };


  return (
    <div style={{ ...styles.LandingContainer, ...earthImage }}>
      <div id="recaptcha-container"></div>
      <ImageE911 />
      <FadeIn>
        <Container
          fluid
          className="d-flex flex-column align-items-start justify-content-around "
        >
          <Row className="Homepage">
            <Col>
              <SloganE911
                data={{
                  text: "VERIFY YOUR",
                }}
              ></SloganE911>
              <SloganE911
                data={{
                  text: "ACCOUNT",
                }}
              ></SloganE911>
            </Col>

            <Row
              className="mt-4 w-80 d-flex flex-column
               justify-content-start"
            >
              <Col className="d-flex align-items-center RadioButtonStyle">
                <RadioButtonE911
                  data={{ 
                    value: "phone",
                    checked: selectedValue === "phone", 
                    onChange: handleRadioChange, 
                    label: userPhone ? `Text code to ${userPhone}` : "Phone number not available for user" }}
                />
              </Col>
              <Col className="d-flex  align-items-center RadioButtonStyle">
                <RadioButtonE911
                  data={{
                    value: "email",
                    checked:selectedValue === "email", 
                    onChange: handleRadioChange, 
                    label: userEmail ? `Send email to ${userEmail}` : "Email not available for user" }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Col
                  xs={12}
                  md={6}
                  className="mt-4 d-flex align-items-center justify-content-center RadioButtonStyle "
                >
                  <Col
                    md={4}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <FormInputE911
                      data={{
                        type: "number",
                        placeholder: "Authentication code",
                        customStyle: codeBoxStyle,
                        value: code,
                        onChange: handleChangeCode,
                      }}
                    />
                  </Col>
                </Col>
              </Col>
            </Row>

            <Row>
              <Col
                xs={12}
                md={6}
                className="mt-4 d-flex align-items-center justify-content-center"
              >
                <ButtonE911
                  data={{
                    onClick: handleClickVerify,
                    label: "VERIFY",
                  }}
                />
              {/*   <ModalE911
                  show={modalShow}
                  size="md"
                  handleClose={() => {
                    setModalShow(false);
                  }}
                  onHide={() => setModalShow(false)}
                  handleShow={() => setModalShow(true)}
                  modalTitle="Please choose one of the options below:"
                >
                  <Container className="accessBrowserLocation">
                    <RadioButtonE911
                      data={{
                        value: "gps",
                        checked: selectedValue === "gps",
                        onChange: handleRadioChange,
                        label: "Use GPS software.",
                      }}
                    />
                    <RadioButtonE911
                      data={{
                        value: "default",
                        checked: selectedValue === "default",
                        onChange: handleRadioChange,
                        label: "Use default browser location.",
                      }}
                    />

                    <>
                      <RadioButtonE911
                        data={{
                          value: "manual",
                          checked: selectedValue === "manual",
                          onChange: handleRadioChange,
                          label: "Manually enter your address.",
                        }}
                      />

                      {selectedValue === "manual" && (
                        <FormInputE911
                          data={{
                            name:"manualAddress",
                            type: "text",
                            placeholder: "Enter address",
                            customStyle: addressManualStyle,
                            value:address,
                            onChange: handleChangeAddress,
                          }}
                        />
                      )}
                    </>

                    <div className="d-flex justify-content-center">
                      <ButtonE911
                        data={{
                          onClick: handleClickOk,
                          label: "OK",
                          customStyle: locationBtn,
                        }}
                      />
                    </div>
                  </Container>
                </ModalE911> */}
                <ButtonE911
                  data={{
                    onClick: handleClickLogout,
                    label: "LOGOUT",
                  }}
                />
                {/*  {loading&&<CgSpinner size={20} className="mt-1 animate-spin"></CgSpinner>} */}
              </Col>
            </Row>
            <Row
              mt-3="true"
              w-80
              d-flex
              flex-column
              align-items-start
              justify-content-center
            >
              <Col
                xs={12}
                md={6}
                className="mt-2 d-flex align-items-center justify-content-center"
              >
                <button className="btnStyle" onClick={handleSendCodeAgain}>
                  Send code again?
                </button>
              </Col>
            </Row>
          </Row>
        </Container>
      </FadeIn>
    </div>
  );
};

export default Authentication;
