// E 911 Live font

export const FONT = {
  font: "Source Sans Pro, sans-serif",
  lightFont: "300",
  regularFont: "400",
  boldFont: "500",
};

// header logo style
export const LogoStyle = {
  width: "65px",
  height: "65px",
};

// header logo title style
export const TitleStyle = {
  color: "var(--white)",
  fontSize: "1em",
  fontWeight: "var(--regularFont)",
  paddingLeft: "10px",
  fontFamily: "var(--Font)",
  marginBottom: "0",
  textShadow: "2px 1px 5px rgba(0,0,0,0.5)",
};

// footer copyright style
export const CopyrightStyle = {
  color: "var(--black)",
  fontFamily: "var(--fontFamily)",
  fontSize: "12px",
  // marginTop: "5px",
  textAlign: "center",
  fontWeight: "var(--boldFont)",
  letterSpacing: "1px",
  marginBottom: "0",
};

// FormInputE911 style
export const InputStyle = {
  position: "relative",
  display: "block",
  // marginBottom: "1em",
  fontFamily: "var(--fontFamily)",
  fontWeight: "var(--regularFont)",
  fontSize: "1em",
  // textTransform: "uppercase",
};

// TextareaE911 style
export const TextareaStyle = {
  color: "var(--black)",
  fontFamily: "var(--fontFamily)",
  fontWeight: "var(--regularFont)",
  fontSize: "1em",
};

//ListIcons navigation style
export const ListIconsNav = {
  color: "var(--orange)",
  fontSize: "1.2em",
  marginRight: "8px",
};

//ListIcons navigation style
export const LogoutIconStyle = {
  color: "var(--orange)",
  fontSize: "1.5em",
  marginRight: "8px",
};

export const logoutButton = {
  backgroundColor: "var(--orange)",
  fontWeight: "var(--regularFont)",
  color: "var(--white)",
  padding: " 7px 10px",
  letterSpacing: ".5px",
  borderRadius: "0",
  margin: "10px auto",
  // border: "1px solid var(--white)",
};

//Authentication Code style
export const codeBoxStyle = {
  width: "220px",
  height: "60px",
  border: "1px solid orange",
  borderRadius: "5px",
  backgroundColor: " #fff",
  color: "var(--lightGray)",
};

//Authentication component
// browser location button in popup
export const locationBtn = {
  width: "80px",
  height: "35px",
  margin: "0",
  padding: "0",
};

//input style for browser location popup
export const addressManualStyle = {
  width: "300px",
  height: "35px",
  border: "1px solid orange",
  borderRadius: "5px",
  backgroundColor: " #fff",
  color: "var(--lightGray)",
  marginLeft: "30px",
};

//Avatar profile image style
export const avatarStyle = {
  borderRadius: "100%",
  backgroundColor: "var(--lightGray)",
  border: "1px solid orange",
  width: "60px",
  height: "60px",
  marginLeft: "10px",
};

// Nav Circle style
export const InitialsCircleStyle = {
  backgroundColor: "var(--white)",
  textAlign: "center",
  fontWeight: "var(--boldFont)",
  fontFamily: "$font",
  border: "1px solid orange",
  width: "55px",
  height: "55px",
  borderRadius: "100%",
  color: "var(--orange)",
  boxShadow: "none",
};

export const emailStyle = {
  color: "var(--darkGray)",
  fontSize: "1em",
  lineHeight: "1em",
  fontWeight: "var(--regularFont)",
  paddingLeft: "10px",
  fontFamily: "$font",
};

// contact page heading style
export const ContactFontStyle = {
  marginTop: "3em",
  fontSize: "2em",
  marginBottom: "0",
  fontWeight: "var(--boldFont)",
  color: "var(--orange)",
};

// contact page subtitle style
export const ContactSubtitle = {
  fontSize: "1em",
  marginBottom: "0",
  fontWeight: "var(--regularFont)",
  color: "var(--darkGray)",
};

// 404 error page style starts
export const ErrorPageText = {
  fontSize: "1.3em",
  fontWeight: "var(--lightFont)",
  color: "var(--white)",
  textAlign: "center",
};

export const ErrorGraphicStyle = {
  maxWidth: "70%",
  display: "block",
  margin: "0 auto",
};

export const ErrorContainer = {
  height: "85vh",
  width: "100%",
  position: "relative",
  display: "flex",
  justifyContent: "center",
};

export const ElementCenter = {
  width: "80%",
  margin: "0 auto",
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%) ",
};
// 404 error page style ends

// Error message style
export const ErrorMessageStyle = {
  fontSize: "0.9em",
  color: "red",
  margin: "0",
  paddingBottom: "10px",
  display: "block",
};

// Success message style
export const SuccessMessageStyle = {
  fontSize: "1.3em",
  color: "var(--orange)",
  margin: "0",
  paddingBottom: "1em",
};

// dropdown style
export const DropdownStyle = {
  position: "relative",
  display: "block",
  marginBottom: "1em",
  fontFamily: "var(--fontFamily)",
  fontWeight: "var(--regularFont)",
  fontSize: "1em",
};

// Time stamp style
export const TimeStampText = {
  fontSize: "0.9em",
  fontWeight: "var(--regularFont)",
  lineHeight: "1.5em",
  color: "var(--darkGray",
  paddingTop: "1em",
};

export const NumIncText = {
  fontSize: "0.9em",
  fontWeight: "var(--boldFont)",
  lineHeight: "1em",
  marginLeft: "5px",
  color: "var(--darkGray",
  marginTop: "0",
};

// Profile Photo sidebar Style
export const ProfilePicStyle = {
  overflow: "hidden",
  width: "100px",
  height: "100px",
  borderRadius: "100%",
  display: "block",
  margin: "10px 0",
  border: "1.5px solid var(--orange)",
};

//Locate button sidebar style
export const LocateButtonStyle = {
  backgroundColor: "var(--orange)",
  fontWeight: "var(--regularFont)",
  color: "var(--white)",
  fontSize: "16px",
  padding: "5px 20px",
  letterSpacing: "1px",
  borderRadius: "25px",
  border: "1px solid var(--white)",
  cursor: "pointer",
  marginBottom: "1.5em",
};

//headline text  style for sidebar
export const headlineSidebar = {
  textTransform: "uppercase",
  fontSize: "16px",
  fontWeight: "700",
  Color: "var(--darkGray)",
  margin: "10px 0 0",
  fontFamily: "var(--fontFamily)",
};

export const bodySidebarName = {
  fontSize: "16px",
  fontWeight: "var(--boldFont)",
  marginBottom: "0",
  Color: "var(--darkGray)",
  marginRight: "5px",
  lineHeight: "1.6em",
  fontStyle: "italic",
  paddingTop: "0",
  marginTop: "0",
};

export const bodySidebar = {
  fontSize: "14px",
  fontWeight: "var(--regularFont)",
  marginBottom: "0",
  Color: "var(--lightGray)",
  marginRight: "5px",
  lineHeight: "1.6em",
  marginTop: "0",
};

//victim details first&last name style
export const victimNameStyle = {
  color: "var(--black)",
  fontWeight: "var(--boldFont)",
  marginRight: "5px",
  marginBottom: "0",
  fontSize: "1em",
};

export const speedStyle = {
  marginRight: "5px",
};

export const ModalHeaderStyle = {
  fontFamily: "var(--fontFamily)",
  fontWeight: "var(--boldFont)",
  fontSize: "1.5em",
  marginLeft: "40%",
  marginTop: "1em",
};

export const InviteSignUp = {
  width: "150px",
  margin: "0",
};
export const ExpiredLink = {
  width: "160px",
};

//react select dropdown style for register  access page and manage account
export const selectStyle = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "var(--orange)" : null,
      color: "#302f2f",
    };
  },
  singleValue: (provided) => ({
    ...provided,
    color: "white",
  }),
  control: (base, state) => ({
    ...base,
    background: "black",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "var(--orange)" : "var(--darkGray)",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "var(--orange)" : "var(--darkGray)",
    },
    minHeight: "50px",
  }),

  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
};

//react select dropdown style for Edit User Account
export const EditUserSelect = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "var(--orange)" : null,
      color: "#302f2f",
    };
  },
  singleValue: (provided) => ({
    ...provided,
    color: "#636364",
    fontWeight: 600,
  }),
  control: (base, state) => ({
    ...base,
    background: "#fff",

    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: "var(--orange)",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: "var(--orange)",
    },
    minHeight: "35px",
  }),

  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
};

export const TextListIncident = {
  fontSize: "1em",
  fontWeight: "600",
  letterSpacing: "2px",
  color: "var(--white)",
  textTransform: "upperCase",
};
