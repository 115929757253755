/*
    Author: Reshma Ghanta/ Lamiaa Moustafa
    Desc: Contact page for e911live
          This page allows you to send emails filling the Contact Form.
          Styling using React Bootstrap and importing elements from the grid System.
*/

import React from "react";
import { Container, Col } from "react-bootstrap";
import FormInputE911 from "../../components/reusableComponents/FormInputE911";
import ButtonE911 from "../../components/reusableComponents/ButtonE911";
import { styles } from "../../styles/MainStyled";
import {
  InputStyle,
  TextareaStyle,
  ContactFontStyle,
  ContactSubtitle,
} from "../../styles/customStyle";
import CampusPolice from "../../assets/images/CampusPolice.png";
import "../../styles/scss/contact.scss";
import TextE911 from "../../components/reusableComponents/TextE911";
import TextareaE911 from "../../components/reusableComponents/TextareaE911";

const Contact = () => {
  // using landing page background image
  const PoliceImage = {
    backgroundImage: `url(${CampusPolice})`,
  };

  return (
    <>
      <Container fluid className="gx-0 ContactContainerStyle">
        <div
          className="d-none d-md-block"
          style={{ ...styles.ContactContainer, ...PoliceImage }}
        ></div>
        <div className="ContactGroup">
          <Col
            className="ContactFormInput d-flex
              flex-column "
          >
            <TextE911
              data={{ text: "NEED SUPPORT?", customStyle: ContactFontStyle }}
            />

            <TextE911
              data={{
                text: "Contact us if you need further assistance",
                customStyle: ContactSubtitle,
              }}
            />

            <FormInputE911
              data={{
                name: "name",
                type: "name",
                placeholder: "FIRST NAME AND LAST NAME",
                customStyle: InputStyle,
              }}
            />
            <FormInputE911
              data={{
                type: "email",
                placeholder: "EMAIL ADDRESS",
                customStyle: InputStyle,
              }}
            />
            <FormInputE911
              data={{
                name: "phone number",
                type: " phone number",
                placeholder: "PHONE NUMBER",
                customStyle: InputStyle,
              }}
            />
            <TextareaE911
              data={{
                onChange: "",
                placeholder: "MESSAGE",
                maxLength: "500",
                customStyle: TextareaStyle,
              }}
            />
            <Col>
              <ButtonE911 data={{ label: "SUBMIT" }} />
            </Col>
          </Col>
        </div>
      </Container>
      {/* <FooterE911 /> */}
    </>
  );
};
export default Contact;
