/*
    Author: Reshma Ghanta
    Desc: Reusable Image component.
*/
import React from "react";

const ProfilePhotoE911 = (props) => {
  const { source, avatar, customStyle, name } = props.data;
  return (
    <>
      <img src={source} alt={avatar} style={customStyle} name={name} />
    </>
  );
};

export default ProfilePhotoE911;
