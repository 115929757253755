/*
    Author: Reshma Ghanta
    Desc: Mapping page for e911live
          Importing the small components and React hooks that are necessary.
          Importing Links into react dom router.
          This page has details of the victims in a specific region, in a requested timeframe
*/
import React, { useState, useRef, useEffect} from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { Col } from "react-bootstrap";
import PulseLoader from "react-spinners/PulseLoader";
import "../../../styles/scss/mapping.scss";
import { CA_db, Soteria_db } from "../../../firebaseConfig";
import {
  ref,
  query,
  onValue,
  get
} from "firebase/database";
import * as turf from "@turf/turf";
//import ReactAudioPlayer from 'react-audio-player';
import {VictimData} from "../../../contexts/E650DContext"
import { UserData } from "../../../contexts/GlobalContext";
import { SoteriaData } from "../../../contexts/SoteriaContext";
import loudAlert from "../../../assets/audio/loudAlert.mp3";
import softAlert from "../../../assets/audio/softAlert.mp3"
//console.log(io);

mapboxgl.accessToken = process.env.REACT_APP_E911_MAPBOX_ACCESSKEY;


const Mapping = ({
  setAppsCount,
  setDevicesCount,
  geofenceRadius,
}) => {
  // console.log(geofenceRadius);

  const {victimDataSocket,updateCaVictimDetails,endVictimDataSocket,dataFromIncidentList,selectTimestamp,updateVictimData,updateEndVictimData}=VictimData();
  const { soteriaDataSocket,updateDeviceDetails,endSoteriaDataSocket,updateDeviceData,updateEndDeviceData } = SoteriaData();
   const { e911UserDetails} = UserData();
  
  const mapContainer = useRef(null); // Create a ref for the map container element
  const map = useRef(null); // Create a ref for the map instance
  const geofenceCircleRef = useRef(null);   // Define geofenceCircle outside of useEffect  
  const prevDataFromIncidentList = useRef(null);
  const geocodeMarkerRef = useRef(null); // Reference to the geocode marker
  const initialMarkerRef = useRef(null);

  const [liveLng, setLiveLng] = useState(null); // Initialize with null
  const [liveLat, setLiveLat] = useState(null); // Initialize with null
  const [zoom, setZoom] = useState(10); // Initialize a state variable for map zoom
  const [loading,setLoading] = useState(true);
  const [victimMarkers, setVictimMarkers] = useState([]);
  const [soteriaMarkers,setSoteriaMarkers] = useState([]);
  const [alertSoundSelection,setAlertSoundSelection]=useState(null);
  const [alertSound, setAlertSound] = useState(null);
 


useEffect(() => {
  if (e911UserDetails) {
    setAlertSoundSelection(e911UserDetails.alertSound);
    }
}, [e911UserDetails]); 



 useEffect(() => {
  const loadAudio = async () => {
    let audioSrc;

  if (alertSoundSelection==='loudAlert') {
    //console.log("loud")
    audioSrc = loudAlert;
  }else if (alertSoundSelection==='softAlert') {
     //console.log("soft")
     audioSrc = softAlert;
  }
  
  const audio = new Audio(audioSrc);

    await new Promise((resolve) => {
        audio.oncanplaythrough = resolve;
      });
     
      setAlertSound(audio);
  };

    loadAudio();
}, [alertSoundSelection]);  


 /*
    Author:Reshma Ghanta
    Desc: To initialiaze the map  and assign them to the ref objects.
          Set a preloader when the map coords are not loaded.
          Add navigation controls to the map
          Add GeoLocator controls to the map
          Add GeoCoder controls to map and then set geofence accordingly.
 */
const createGeofence = (longitude, latitude, radius) => {
     //console.log(radius)
  const geofenceCenter = turf.point([longitude, latitude]);
  const geofenceOptions = {
    steps: 80,
    units: "miles",
    radius: radius
  };
  const geofenceCircle = turf.circle(geofenceCenter, radius, geofenceOptions);
  geofenceCircleRef.current = geofenceCircle;

  const geofenceSourceId = "geofence";
  let geofenceSource = map.current.getSource(geofenceSourceId);
  if (geofenceSource) {
    geofenceSource.setData(geofenceCircle);
    map.current.setPaintProperty("geofence-fill", "fill-color", "#9accff");
    map.current.setPaintProperty("geofence-outline", "line-color", "#006AD4");
  } else {
    map.current.addSource(geofenceSourceId, {
      type: "geojson",
      data: geofenceCircle,
    });

    map.current.addLayer({
      id: "geofence-fill",
      type: "fill",
      source: geofenceSourceId,
      paint: {
        "fill-color": "#9accff",
        "fill-opacity": 0.2,
      },
    });

    map.current.addLayer({
      id: "geofence-outline",
      type: "line",
      source: geofenceSourceId,
      paint: {
        "line-color": "#006AD4",
        "line-width": 1.5,
      },
    });
  }
};


useEffect(() => { 
  //console.log("geofence Radius",geofenceRadius)
  const getPosition = (timeout = 10000) => {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        reject(new Error("Geolocation request timed out"));
      }, timeout);

      navigator.geolocation.getCurrentPosition(
        (position) => {
          clearTimeout(timer);
          resolve(position);
        },
        (error) => {
          clearTimeout(timer);
          reject(error);
        },
        { enableHighAccuracy: true }
      );
    });
  };

  const initMap = async () => {
    try {
      const position = await getPosition();
     // console.log("current position successful")
      setLoading(false);
      const lng = position.coords.longitude;
      const lat = position.coords.latitude;
      setLiveLng(lng);
      setLiveLat(lat);

        //console.log("inside init geofence",geofenceRadius)

      if (!map.current) {
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/streets-v11',
          center: [lng, lat],
          zoom: 10
        });

        const liveCoordinates = [lng, lat];
        initialMarkerRef.current =  addLiveMarker(map.current, liveCoordinates);

        map.current.on("style.load", () => {
           // console.log("style.load",geofenceRadius)
          createGeofence(lng, lat, geofenceRadius);
           const scale = new mapboxgl.ScaleControl({
            maxWidth: 100,
            unit: 'imperial'
          });
          map.current.addControl(scale);
          

          const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            marker: false,
            placeholder: 'Insert address here',
          });

          geocoder.on('result', (e) => {
            const location = e.result.geometry.coordinates;
           // console.log(location);
            map.current.flyTo({
              center: location,
              zoom: 10,
              speed: 1
            });

             // Remove initial live marker
             if (initialMarkerRef.current) {
              initialMarkerRef.current.remove();
            }

             // Remove previous geocode marker if it exists
              if (geocodeMarkerRef.current) {
                geocodeMarkerRef.current.remove();
              }
             
            // Update live marker and geofence
            geocodeMarkerRef.current = addLiveMarker(map.current, location);
            createGeofence(location[0], location[1], geofenceRadius);
            setLiveLng(location[0]);
            setLiveLat(location[1]);
          });

          map.current.addControl(geocoder);

          map.current.addControl(new mapboxgl.NavigationControl());
          map.current.addControl(
            new mapboxgl.GeolocateControl({
              positionOptions: {
                enableHighAccuracy: true,
              },
              trackUserLocation: true,
              showUserHeading: true,
            })
          );
        
        });
      } 
    }catch(error) {
      console.error('Error watching position:', error);
      setLoading(true);
    }
  };
    
  initMap();
}, []);


useEffect(() => {
  if (map.current && liveLng !== null && liveLat !== null) {
    createGeofence(liveLng, liveLat, geofenceRadius);
  }
}, [geofenceRadius]);


  function addLiveMarker(map, coordinates) {
    const liveMarker = document.createElement('div');
    liveMarker.className = 'livemarker';
    return new mapboxgl.Marker(liveMarker)
      .setLngLat(coordinates)
      .addTo(map);
  }

  
  useEffect(() => {
    if(dataFromIncidentList && !loading && map.current){
   // console.log(dataFromIncidentList);

    const victimCoordinates = [dataFromIncidentList.startLongitude, dataFromIncidentList.startLatitude];
    const victimUserId=dataFromIncidentList.userId;
    const victimTimestamp=dataFromIncidentList.timeStamp;
    //const typeOfData=dataFromIncidentList.typeOfData;
    const isFalseAlert=dataFromIncidentList.isFalseAlert;
    
    let typeOfEmergency;
    if(isFalseAlert===true){
      typeOfEmergency='false';
    }else{
      typeOfEmergency=(dataFromIncidentList.typeOfEmergency|| '').toLowerCase();
    }
    const markerKey = `${victimUserId}_${typeOfEmergency}`;
    const matchingUserKey = victimMarkers[markerKey] !== undefined;
    //const matchingUserKey = Object.keys(victimMarkers).includes(markerKey);
    
    // Perform geofence check
    //const isInsideGeofence = turf.booleanPointInPolygon(victimCoordinates, geofenceCircleRef.current);
    const isInsideGeofence = turf.booleanPointInPolygon(turf.point(victimCoordinates), geofenceCircleRef.current);

    if (isInsideGeofence && !matchingUserKey) {
      addVictimMarker(map.current, victimCoordinates, victimUserId, victimTimestamp,typeOfEmergency); // For victim marker
    // setVictimMarkers(prevMarkers => ({ ...prevMarkers, [markerKey]: true }));
      /* setVictimMarkers(prevMarkers => ({...prevMarkers, [markerKey]: {
          victimUserId: victimUserId,
          typeOfEmergency:markerType,
          marker: marker,
        }})); */
    }else if (!isInsideGeofence && matchingUserKey) {
      hideVictimMarker(victimUserId, typeOfEmergency);
      setVictimMarkers(prevMarkers => {
        const newMarkers = { ...prevMarkers };
        delete newMarkers[markerKey];
        return newMarkers;
      });
    }

    if (prevDataFromIncidentList.current !== dataFromIncidentList) {
     // console.log('dataFromIncidentList has changed');
      //console.log(prevDataFromIncidentList);
      if(prevDataFromIncidentList.current){
         const prevVictimUserId=prevDataFromIncidentList.current.userId;
         let prevTypeOfEmergency;
        
         if (prevDataFromIncidentList.current.isFalseAlert === true) {
          prevTypeOfEmergency = 'false';
        } else {
          prevTypeOfEmergency = (prevDataFromIncidentList.current.typeOfEmergency || '').toLowerCase();
        }

        const prevMarkerKey = `${prevVictimUserId}_${prevTypeOfEmergency}`;
        if (victimMarkers[prevMarkerKey]) {
          hideVictimMarker(prevVictimUserId, prevTypeOfEmergency);
          setVictimMarkers(prevMarkers => {
            const newMarkers = { ...prevMarkers };
            delete newMarkers[prevMarkerKey];
            return newMarkers;
          });
        }
      
        // removeVictimMarker(victimUserId, typeOfEmergency)
      // Updating the ref with the current value for the next comparison
      }
      prevDataFromIncidentList.current = dataFromIncidentList;
    }

    }
  }, [dataFromIncidentList,loading,map.current,victimMarkers,geofenceCircleRef]);// End custom markers
   

          
  /*
      Author: Reshma Ghanta
      Desc: To locate the markers when a new incident occurs.
            To set Victim details and soteria details on user's on click event
            To handle multiple alert situation
            Clear out the markers when they are beyond timestamp value.
 */
    useEffect(() => {
              if (!geofenceCircleRef.current || !victimDataSocket || loading) return;
             
              for (const victim of victimDataSocket) {
                  const victimCoordinates = [victim.startLongitude, victim.startLatitude];
                  const victimUserId = victim.userId;
                  const victimTimestamp = victim.timeStamp;
                  const typeOfEmergency = victim.typeOfEmergency.toLowerCase();
                 
                  const markerKey = `${victimUserId}_${typeOfEmergency}`;
                  const matchingUserKey = victimMarkers[markerKey] !== undefined;
            
                  const isInsideGeofence = turf.booleanPointInPolygon(turf.point(victimCoordinates), geofenceCircleRef.current);
            
                  if (isInsideGeofence && !matchingUserKey) {
                    // console.log('User is inside the geofence.');
                    addVictimMarker(map.current, victimCoordinates, victimUserId, victimTimestamp, typeOfEmergency);
                   // setVictimMarkers(prevMarkers => ({ ...prevMarkers, [markerKey]: true }));
                  } else if (!isInsideGeofence && matchingUserKey) {
                    //console.log('User is outside the geofence.');
                    hideVictimMarker(victimUserId, typeOfEmergency);
                    /* setVictimMarkers(prevMarkers => {
                      const newMarkers = { ...prevMarkers };
                      delete newMarkers[markerKey];
                      return newMarkers;
                    }); */
                  }
                }
              
  }, [victimDataSocket, loading, map.current, geofenceRadius, victimMarkers]);
             
          
 /*
      Author: Reshma Ghanta
      Desc: Hide victim markers when they fall outside the geofence.
 */
function hideVictimMarker(hideVictimUserId, hideTypeOfEmergency) {

  const hideMarkerKey = `${hideVictimUserId}_${hideTypeOfEmergency}`;
  const matchingUserKey = victimMarkers[hideMarkerKey] !== undefined;
  //const matchingUserKey = Object.keys(victimMarkers).includes(hideMarkerKey);
  
  if (matchingUserKey) {
      const markerEntry = victimMarkers[hideMarkerKey];
      // Check if the marker exists and is a valid Mapbox GL JS marker instance
       if (markerEntry && markerEntry.marker) {
        const marker = markerEntry.marker;
        marker.remove(); // Remove the marker from the map
          
        //console.log("Marker hidden successfully.");
     
        // Update victimMarkers state by removing the marker associated with the victim
        setVictimMarkers(prevVictimMarkers => {
          const updatedMarkers = { ...prevVictimMarkers };
          delete updatedMarkers[hideMarkerKey];
          return updatedMarkers;
        });

      } else {
        console.log("No marker found to hide for the specified key.");
       }
  } else {
     // console.log(`No marker found to hide for victimUserId: ${hideVictimUserId}`);
  }
}


 useEffect(() => {
  //console.log(endVictimDataSocket)
  if(endVictimDataSocket){
    endVictimDataSocket.forEach(function (victim) {
      //console.log("????????????")
   // console.log("End socket data",victim)
        const victimUserId=victim.userId;
        let typeOfEmergency=(victim.typeOfEmergency).toLowerCase();
        removeVictimMarker(victimUserId, typeOfEmergency)
       
      })
  }
}, [endVictimDataSocket]);  

useEffect(() => {
  //console.log(endVictimDataSocket)
  if(endSoteriaDataSocket){
    endSoteriaDataSocket.forEach(function (device) {
      //console.log("????????????")
         const deviceId=device.deviceId;
         removeSoteriaMarker(deviceId)
         //console.log("End soteria socket data",device)  
      })
  }
}, [endSoteriaDataSocket]);  


function removeSoteriaMarker(removeDeviceId) {
 // const removeMarkerKey = `${removeVictimUserId}_${removeTypeOfEmergency}`;
   // console.log(soteriaMarkers)

  const matchingDeviceKey = Object.keys(soteriaMarkers).includes(removeDeviceId);
  
  if (matchingDeviceKey) {
      const markerEntry = soteriaMarkers[removeDeviceId];
       
     // updateDeviceDetails(markerEntry.soteriaDeviceId,markerEntry.deviceTimestamp,false);

      // Check if the marker exists and is a valid Mapbox GL JS marker instance
       if (markerEntry && markerEntry.marker) {
        const marker = markerEntry.marker;
        marker.remove(); // Remove the marker from the map
       // console.log("Marker removed successfully.");
         updateEndDeviceData(removeDeviceId);
         updateDeviceData(removeDeviceId);
      } else {
        console.log("No marker found for the specified device id.");
       }

      // Update victimMarkers state by removing the marker associated with the victim
      setSoteriaMarkers(prevSoteriaMarkers => {
          const updatedMarkers = { ...prevSoteriaMarkers };
          delete updatedMarkers[removeDeviceId];
          return updatedMarkers;
      });

     // console.log(`Marker removed for deviceId: ${removeDeviceId}`);
  } else {
      //console.log(`No marker found for deviceId: ${removeDeviceId}`);
  }
}


/*
      Author: Reshma Ghanta
      Desc: Hide device markers when they fall outside the geofence.
 */
    const hideDeviceMarker = (hideDeviceId) => {
       // console.log(hideDeviceId)
       //console.log(soteriaMarkers)
        const matchingDeviceKey = Object.keys(soteriaMarkers).includes(hideDeviceId);
        
        if (matchingDeviceKey) {
           // console.log(matchingDeviceKey);

            const markerEntry = soteriaMarkers[hideDeviceId];
            // Check if the marker exists and is a valid Mapbox GL JS marker instance
             if (markerEntry && markerEntry.marker) {
                //  console.log(markerEntry.marker)
              const marker = markerEntry.marker;
              marker.remove(); // Remove the marker from the map
                
              // console.log("Marker hidden successfully.");
           
              // Update soteriaMarkers state by removing the marker associated with the device
              setSoteriaMarkers(prevDeviceMarkers => {
                const updatedMarkers = { ...prevDeviceMarkers };
                delete updatedMarkers[hideDeviceId];
                return updatedMarkers;
              });
      
            } else {
              console.log("No marker found to hide for the specified device key.");
             }
        } else {
            console.log(`No marker found to hide for deviceId: ${hideDeviceId}`);
        }
      }
      
/*
      Author: Reshma Ghanta
      Desc: To locate the soteria markers when a new incident occurs.
            To handle multiple alert situation
            Clear out the markers when they are beyond timestamp value.
 */
 useEffect(()=>{      
    
    const fetchDeviceInformation = async (device) => {    
        const soteriaDeviceId = device.deviceId;
        const deviceTimestamp = device.timeStamp;
        const soteriaLatLngRef = query(ref(Soteria_db, `/devices/${soteriaDeviceId}/deviceInformation`)); 
    try{
        const snapshot = await get(soteriaLatLngRef);          
        if (snapshot.exists()) {
               const soteriaCoordinates = [snapshot.val().longitude, snapshot.val().latitude];
               //console.log(soteriaDeviceId,deviceTimestamp,soteriaCoordinates)
               
               if (geofenceCircleRef.current) {
               const isInsideGeofence = turf.booleanPointInPolygon(soteriaCoordinates, geofenceCircleRef.current);
               if (isInsideGeofence){
                if (!soteriaMarkers[soteriaDeviceId]) {
               addSoteriaMarker(map.current, soteriaCoordinates, soteriaDeviceId, deviceTimestamp);
                }
               }else if (soteriaMarkers[soteriaDeviceId]) {
                   console.log('Device is outside the geofence.');
                    hideDeviceMarker(soteriaDeviceId)
              }
            }
            }
      }catch(e){
        console.error('Error fetching device information:', e);
        }
      };
            
      const addMarkers = async () => {
        if (geofenceCircleRef.current&&soteriaDataSocket && !loading && map.current) {
            for (const device of soteriaDataSocket) {
                //console.log(device);
                await fetchDeviceInformation(device);
            }
        }
    };
    
    addMarkers();
},[soteriaDataSocket,loading, map.current,geofenceRadius]) 



/*
    Author:Reshma Ghanta
    Desc: Add soteria marker to the map
 */
     function addSoteriaMarker(map, coordinates, soteriaDeviceId, deviceTimestamp) {
          //console.log("adding device marker")
        document.body.click(); // Simulate a click on the document body
    
        if (alertSound) {
          alertSound.muted = false; // Unmute before playing
          alertSound.play().catch(error => console.error("Play failed:", error));
        }
   
        const markerDiv = document.createElement('div');
        markerDiv.className = `soteriamarker`;
      
        const marker = new mapboxgl.Marker(markerDiv);
     
        marker.getElement().addEventListener("click", () => {
          //console.log("on click triggered")
          updateDeviceDetails(soteriaDeviceId,deviceTimestamp,true);
          //setSoteria({ soteriaShow:true});
        });
       
        if (!map) {
          console.log("Map object not found. Waiting for it...");
          return; // Do not proceed if the map object is not available
        } else{
    
         /*  const popup = new mapboxgl.Popup({ closeOnClick: false })
        .setLngLat([-96, 37.8])
        .setHTML('<h1>Hello World!</h1>')
        .addTo(map); */

        let devicePopup = new mapboxgl.Popup({ offset: 25 }).addTo(map);  
          if (devicePopup) {
                devicePopup.setHTML("<h6>" + soteriaDeviceId + "</h6>");
            }        
    
        /* devicePopup.on('close', () => {
          updateDeviceDetails(soteriaDeviceId,deviceTimestamp,false);
          //setSoteria({ soteriaShow: false });
        }); */
      
        marker.setLngLat(coordinates).setPopup(devicePopup).addTo(map);
      }
        //  const markerKey = `${victimUserId}_${markerType}`;
          setSoteriaMarkers((prevSoteriaMarkers) => ({...prevSoteriaMarkers,
            [soteriaDeviceId]: {
              deviceId: soteriaDeviceId,
              deviceTimestamp:deviceTimestamp,
              marker: marker,
            }, 

          }));  
  } 

  
 /*
    Author:Reshma Ghanta
    Desc: Remove victim marker from the map (user emergency, fire, medical, false)
 */
  function removeVictimMarker(removeVictimUserId, removeTypeOfEmergency) {
    const removeMarkerKey = `${removeVictimUserId}_${removeTypeOfEmergency}`;
     // console.log(victimMarkers)

    const matchingUserKey = Object.keys(victimMarkers).includes(removeMarkerKey);
      // console.log(matchingUserKey)
 
     // console.log(removeVictimUserId)

    if (matchingUserKey) {      
        const markerEntry = victimMarkers[removeMarkerKey];
         // console.log(markerEntry)
       //   console.log(matchingUserKey,removeMarkerKey)
      // console.log(markerEntry.victimUserId, markerEntry.victimTimestamp)
        //  console.log(markerEntry.marker)
      // updateCaVictimDetails(markerEntry.victimUserId, markerEntry.victimTimestamp, false);
        
        // Check if the marker exists and is a valid Mapbox GL JS marker instance
          if (markerEntry && markerEntry.marker) {
          const marker = markerEntry.marker;
          marker.remove(); // Remove the marker from the map
         // console.log("Marker removed successfully.");
         updateEndVictimData(removeVictimUserId,removeTypeOfEmergency);
         updateVictimData(removeVictimUserId,removeTypeOfEmergency);
        } else {
          console.log("No marker found for the specified key.");
         } 

        // Update victimMarkers state by removing the marker associated with the victim
        setVictimMarkers(prevVictimMarkers => {
            const updatedMarkers = { ...prevVictimMarkers };
            delete updatedMarkers[removeMarkerKey];
            return updatedMarkers;
        });

       // console.log(`Marker removed for victimUserId: ${removeVictimUserId}`);
    } else {
        //console.log(`No marker found for victimUserId: ${removeVictimUserId}`);
    }
}





 /*
    Author:Reshma Ghanta
    Desc: Add victim marker to the map (user emergency, fire, medical, false)
 */
  function addVictimMarker(map, coordinates, victimUserId, victimTimestamp, markerType) {
    
  console.log("add victim marker");
  //console.log(isFalseAlert);

    document.body.click(); // Simulate a click on the document body

    if (alertSound) {
      alertSound.muted = false; // Unmute before playing
      alertSound.play().catch(error => console.error("Play failed:", error));
    }

  const markerDiv = document.createElement('div');
    markerDiv.className = `${markerType}marker`;
  
    const marker = new mapboxgl.Marker(markerDiv);
   
    if (!map) {
      console.log("Map object not found. Waiting for it...");
      return; // Do not proceed if the map object is not available
    }
    let victimPopup = new mapboxgl.Popup({ offset: 25 }).addTo(map);
  
    const victimInfoRef = query(ref(CA_db, `/users/${victimUserId}/victimInformation`));
    onValue(victimInfoRef, (snapshot) => {
          if (victimPopup && snapshot.exists()) {
            victimPopup.setHTML("<h6>" + snapshot.val().firstName + " " + snapshot.val().lastName + "</h6> <p>" + snapshot.val().email + "</p>");
          }        
     });


     if (markerType !== 'false') {
      marker.getElement().addEventListener("click", () => {
           //console.log("clicked")
        updateCaVictimDetails(victimUserId, victimTimestamp, true);
      });

      /* victimPopup.on('close', () => {
        console.log("close click")
        updateCaVictimDetails(victimUserId, victimTimestamp, false);
      }); */
    }
     marker.setLngLat(coordinates).setPopup(victimPopup).addTo(map);

     const markerKey = `${victimUserId}_${markerType}`;

      setVictimMarkers((prevVictimMarkers) => ({...prevVictimMarkers,
        [markerKey]: {
          victimUserId: victimUserId,
          typeOfEmergency:markerType,
          victimTimestamp:victimTimestamp,
          marker: marker,
        },
      }));

 };
 
/*
    Author:Reshma Ghanta
    Desc: Update victim markers count for active emergencies
 */
useEffect(() => {
      const incidentCount = Object.keys(victimMarkers).length;
      setAppsCount(incidentCount);
}, [victimMarkers]);

useEffect(() => {
  const incidentCount = Object.keys(soteriaMarkers).length;
  setDevicesCount(incidentCount);
}, [soteriaMarkers]);

/*
    Author:Reshma Ghanta
    Desc: Clear victim markers when switched from active emergencies to history and vice versa
 */
/* useEffect(() => {
  //console.log(victimMarkers)
  //console.log(selectTimestamp);
      clearVictimMarkers(victimMarkers);
}, [selectTimestamp]); */


/*
    Author:Reshma Ghanta
    Desc: Function to clear victim markers when switched from active emergencies to history and vice versa
 */
function clearVictimMarkers(victimMarkers) {
    
  if(Object.entries(victimMarkers).length > 0){
     const victimMarkersKeys=Object.keys(victimMarkers);
    // console.log(victimMarkersKeys);
     //console.log(victimMarkersKeys[0]);

    victimMarkersKeys.forEach(function(element) {
       // console.log(element);
   
     const markerInstance = victimMarkers[element].marker;
     const markerElement = markerInstance.getElement();
     
     if (markerElement && markerElement.parentNode) {
       markerElement.parentNode.removeChild(markerElement);
     }
     
     const updatedVictimMarkers = { ...victimMarkers };
     delete updatedVictimMarkers[element];

      // Update the state with the new object
      setVictimMarkers(updatedVictimMarkers);

     
    });

  }
 } // end function

 //console.log(victimMarkers);

  return (
    <> 
      {
      loading? (<Col xs={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 9, order: 2 }} xxl={{ span: 10, order: 2 }}
      className="pulse-loader-container" style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh',background: 'rgba(224, 224, 224, 0.7)'}}>
        <PulseLoader color={'#ff9b30'} loading={loading} margin={10} size={15} speedMultiplier={0.50}/>
        
      </Col> )
      :
     ( <Col
      xs={{ span: 12, order: 1 }}
      md={{ span: 12, order: 1 }}
      lg={{ span: 9, order: 2 }}
      xxl={{ span: 10, order: 2 }}
      ref={mapContainer}
      className="map-container"
    >
    </Col> 
    )}  
    </>
  );
};

export default Mapping;