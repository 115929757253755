/*
    Author: Reshma Ghanta
    Desc: Victim Health information details for e911live
          Importing the small components and React hooks that are necessary.
*/
import React, { useState , forwardRef} from "react";
import { useCollapse } from "react-collapsed";
import { BsCaretDownSquare } from "react-icons/bs";
import { bodySidebar, bodySidebarName } from "../../styles/customStyle";
import TextE911 from "../reusableComponents/TextE911";

const CollapsableTextE911 = forwardRef((props,ref) => {
  const { allergies, medications, preExistingConditions } = props.data;
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  function handleOnClickCollapsable() {
    setExpanded(!isExpanded);
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <TextE911
          data={{ text: "Health Information", customStyle: bodySidebarName }}
        />
        <BsCaretDownSquare
          className="CollapseIcon"
          {...getToggleProps({ onClick: handleOnClickCollapsable })}
        />
      </div>
      <div ref={ref} {...getCollapseProps()}>
        <div className="d-flex align-items-start">
          <TextE911
            data={{ text: "Allergies:", customStyle: bodySidebarName }}
          />
          <TextE911
            data={{
              text: allergies,
              customStyle: bodySidebar,
            }}
          />
        </div>
        <div className="d-flex align-items-start">
          <TextE911
            data={{ text: "Medications:", customStyle: bodySidebarName }}
          />
          <TextE911
            data={{
              text: medications,
              customStyle: bodySidebar,
            }}
          />
        </div>
        <div>
          <TextE911
            data={{
              text: "Pre-Existing Conditions:",
              customStyle: bodySidebarName,
            }}
          />
          <TextE911
            data={{
              text: preExistingConditions,
              customStyle: bodySidebar,
            }}
          />
        </div>
      </div>
    </>
  );

});

export default CollapsableTextE911;
