/*
    Author: Reshma Ghanta
    Desc: Victim details for e911live
          Importing the small components and React hooks that are necessary.
          Importing Links into react dom router
*/
import React, { useEffect, useState } from "react";
import ProfilePhotoE911 from "../../../components/reusableComponents/ProfilePhotoE911.js";
import TextE911 from "../../../components/reusableComponents/TextE911";
import {
  headlineSidebar,
  bodySidebar,
  bodySidebarName,
} from "../../../styles/customStyle";

import { Row, Col, Badge } from "react-bootstrap";
import placeholder from "../../../assets/images/pngs/placeholder.png";
import {
  ProfilePicStyle,
  victimNameStyle,
  speedStyle,
} from "../../../styles/customStyle";
import "../../../styles/scss/sidebar.scss";
import "../../../styles/scss/video.scss";
import CollapsableTextE911 from "../../../components/CollapsableText";
import { VictimData } from "../../../contexts/E650DContext";
//import { CA_storage } from "../../../firebaseConfig.js";
//import { ref, getDownloadURL } from "firebase/storage";
//import Slider from "rc-slider";
//import "rc-slider/assets/index.css";
//import { MdOutlinePlayCircle } from "react-icons/md";
//import { RiStopCircleLine } from "react-icons/ri";
//import { MdStopCircle } from "react-icons/md";
//import AmazonIVSPlayer from 'amazon-ivs-player';
//import videojs from "video.js";
//import 'video.js/dist/video-js.css';
//import 'videojs-contrib-hls';
import ReactAudioPlayer from "react-audio-player";

/*
    Author: Reshma Ghanta
    Desc: Check if entered date is in desired format or no.
*/
function isValidDateFormat(input) {
  // Regular expression pattern for the desired format (MM/DD/YYYY)
  var dateFormatPattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;

  // Test if the input matches the pattern
  return dateFormatPattern.test(input);
}

/*
   Author: Reshma Ghanta
   Desc: Calculate age of the victim based on DOB entered.
 */
function calculateAge(dateString) {
  //console.log(dateString);
  if (isValidDateFormat(dateString)) {
    // console.log("Valid date format");
    const dob = new Date(dateString);
    const monthDiff = Date.now() - dob.getTime();
    const ageDt = new Date(monthDiff);
    const year = ageDt.getUTCFullYear();
    const age = Math.abs(year - 1970);
    return age;
  } else {
    // console.log("Invalid date format");
    const age = "";
    return age;
  }
}

const checkUrl = async (url) => {
  try {
    const response = await fetch(url);
    return response.ok;
  } catch (error) {
    console.error("Error checking URL:", error);
    return false;
  }
};

/*
    Author: Reshma Ghanta
    Desc: Display the respective victim details based on the marker click by the user.
*/
const VictimDetails = () => {
  const { caVictimDetails } = VictimData();
  const placeholderImage = placeholder;
  const age = calculateAge(caVictimDetails.victimInformation.dateOfBirth);
  const [isUrlValid, setIsUrlValid] = useState(false);
  //const isUrlValidRef = useRef(false);
  // const videoRef = useRef(null);
  //const [isLive, setIsLive] = useState(true);
  //const [recentAudio, setRecentAudio] = useState(false);
 // const [recentAudioUrl, setRecentAudioUrl] = useState(null);
  // video player for Victim Details

  //console.log(selectTimestamp)

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch region, access key, and secret key from respective endpoints
        const regionResponse = await fetch('https://covertalertwebsitetest.web.app/utilapi/ivs_basic');
        if (!regionResponse.ok) throw new Error('Network response was not ok for ivs_basic');      
        const regionData = await regionResponse.json();

        const accessKeyResponse = await fetch('https://covertalertwebsitetest.web.app/utilapi/ivs_a');
        if (!accessKeyResponse.ok) throw new Error('Network response was not ok for ivs_a');
        const accessKeyData = await accessKeyResponse.json();

        const secretKeyResponse = await fetch('https://covertalertwebsitetest.web.app/utilapi/ivs_s');
        if (!secretKeyResponse.ok) throw new Error('Network response was not ok for ivs_s');
        const secretKeyData = await secretKeyResponse.json();

        const options = {
          region: regionData.region,
          accessKeyId: accessKeyData.accessKeyId,
          secretAccessKey: secretKeyData.secretAccessKey,
        }; 

       // console.log('AWS IVS Credentials:',options);
      }
      catch (err){
           console.log(err)
      }
    }; 

    fetchData();
    },[]);
    
  /*  useEffect(() => {
    if(recentAudio===false){
      return;
    }else{
      setIsLive(false);
      //console.log(caVictimDetails);
       const victimID=caVictimDetails.userID;
       const recordName=caVictimDetails.recordingInfo.recordName;
       const recordingWasStreamed=caVictimDetails.recordingInfo.recordingWasStreamed;
     
       if(recordingWasStreamed){
      //  gs://covertalert-e650d.appspot.com/Audio/UygyLnpBkJM3Bh55b0P4cCcx3QG3/2024-02-26 13:51:36.m4a
       // const storageRef = ref(E911_storage, `Audio/${victimID}/${recordName}`);
      // gs://covertalert-e650d.appspot.com/Audio/KORtHjhMLwZ2GpMLiW8G2EywwCJ3/2024-02-27 13:47:38.m4a
        const storageRef = ref(CA_storage, `Audio/${victimID}/${recordName}`);
           // console.log(storageRef);
        getDownloadURL(storageRef).then((url) => {
          console.log(url);
          setRecentAudioUrl(url);
        }).catch((error) => {
          // Handle any errors that may occur during URL retrieval
          console.error("Error getting download URL:", error);
        })
       }
   }
  }, [recentAudio]); */

  /* const checkUrl = async () => {
    try {
      const response = await fetch(caVictimDetails.streamInfo.playbackUrl);
      if (response.ok) {
        //setIsUrlValid(true);
        //setIsLive(true);
        console.log("Live URL: available");
        //isUrlValidRef.current = true;
      } else {
        //setIsUrlValid(false);
        //setIsLive(false);
        console.log("Live URL: NOT available");
        //setRecentAudio(true);
         if (!isUrlValidRef.current) {
          setTimeout(checkUrl, 1000);
        } 
      }
    } catch (error) {
      //setIsUrlValid(false);
      //setIsLive(false);
      console.error("Error checking URL:", error);
       if (!isUrlValidRef.current) {
        setTimeout(checkUrl, 1000);
      } 
    }
  };
 */
  /* useEffect(() => {
    console.log(selectTimestamp)
    console.log(caVictimDetails)
    if (selectTimestamp === "Past24hours") {
        setIsLive(false);
        //console.log("most recent audio")
      //setRecentAudioUrl(caVictimDetails.mostRecentUrl);
      //console.log(caVictimDetails);
     // const victimID = caVictimDetails.userID;
     // const recordName = caVictimDetails.recordingInfo.recordName;
      //const recordingWasStreamed =caVictimDetails.recordingInfo.recordingWasStreamed;

       if (recordingWasStreamed) {
        //  gs://covertalert-e650d.appspot.com/Audio/UygyLnpBkJM3Bh55b0P4cCcx3QG3/2024-02-26 13:51:36.m4a
        // const storageRef = ref(E911_storage, `Audio/${victimID}/${recordName}`);
        // gs://covertalert-e650d.appspot.com/Audio/KORtHjhMLwZ2GpMLiW8G2EywwCJ3/2024-02-27 13:47:38.m4a
        const storageRef = ref(CA_storage, `Audio/${victimID}/${recordName}`);
        // console.log(storageRef);
        getDownloadURL(storageRef)
          .then((url) => {
            console.log(url);
            setRecentAudioUrl(url);
          })
          .catch((error) => {
            // Handle any errors that may occur during URL retrieval
            console.error("Error getting download URL:", error);
          });
      } 
    }  else if (selectTimestamp === "activeEmergencies") {
      isUrlValidRef.current = false;
      const timeoutId = setTimeout(checkUrl, 3000);

      const stopTimeoutId = setTimeout(() => {
        isUrlValidRef.current = true;
        // console.log('Stopping URL checks after 6 seconds');
      }, 6000);

      return () => {
        clearTimeout(timeoutId);
        clearTimeout(stopTimeoutId);
      };
    }  else {
      isUrlValidRef.current = false;
      const timeoutId = setTimeout(checkUrl, 3000);

      const stopTimeoutId = setTimeout(() => {
        isUrlValidRef.current = true;
        console.log("Stopping URL checks after 6 seconds");
      }, 6000);

      return () => {
        clearTimeout(timeoutId);
        clearTimeout(stopTimeoutId);
      };
    }
  }, [caVictimDetails, selectTimestamp]); */

  useEffect(() => {
    let intervalId; 
    const setupPlayer = async () => {
       //console.log(caVictimDetails);
       //console.log(caVictimDetails.liveStream);
      if (caVictimDetails.liveStream === true) {
        //console.log(caVictimDetails.liveUrl)

        let attempts = 0;
        const maxAttempts = 3;
        const interval = 2000;
     

        const checkAndSetup = async () => {
          if (attempts >= maxAttempts) {
            clearInterval(intervalId);
            console.warn("Max attempts reached, live URL is not available");
            return;
          }

         const isValid = await checkUrl(caVictimDetails.liveUrl);
         attempts += 1;

           //console.log(isValid);
        setIsUrlValid(isValid);

        if (isValid) {
            clearInterval(intervalId);
            initializePlayer(caVictimDetails.liveUrl);
          }
      };
      intervalId = setInterval(checkAndSetup, interval);
    }
    };

    const initializePlayer = (liveUrl) => {
        if (!window.IVSPlayer.isPlayerSupported) {
          console.warn("The current browser does not support the IVS player.");
          return;
        }

        const PlayerState = window.IVSPlayer.PlayerState;
        const PlayerEventType = window.IVSPlayer.PlayerEventType;

        // Initialize player
        const player = window.IVSPlayer.create();
        player.attachHTMLVideoElement(document.getElementById("video-player"));

        // Attach event listeners
        const onPlaying = () => console.log("Player State - PLAYING"); 
        const onEnded = () => console.log("Player State - ENDED");
        const onReady = () => console.log("Player State - READY");
        const onError = (err) => console.warn("Player Event - ERROR:", err);
        const onTextMetadataCue = (cue) => {
          const metadataText = cue.text;
          const position = player.getPosition().toFixed(2);
          console.log(`PlayerEvent - TEXT_METADATA_CUE: "${metadataText}". Observed ${position}s after playback started.`);
        };
        const onBuffering = () => console.log("Player State - BUFFERING");
        const onRebuffering = () => console.log("Player State - REBUFFERING");

        player.addEventListener(PlayerState.PLAYING, onPlaying);
        player.addEventListener(PlayerState.ENDED, onEnded);
        player.addEventListener(PlayerState.READY, onReady);
        player.addEventListener(PlayerEventType.ERROR, onError);
        player.addEventListener(PlayerEventType.TEXT_METADATA_CUE, onTextMetadataCue);
        player.addEventListener(PlayerState.BUFFERING, onBuffering);
        player.addEventListener(PlayerEventType.REBUFFERING, onRebuffering);

        // Setup stream and play
        player.setAutoplay(true);
        player.load(liveUrl);
        player.setVolume(0.5);

        // Cleanup when the component is unmounted
        return () => {
          player.removeEventListener(PlayerState.PLAYING, onPlaying);
          player.removeEventListener(PlayerState.ENDED, onEnded);
          player.removeEventListener(PlayerState.READY, onReady);
          player.removeEventListener(PlayerEventType.ERROR, onError);
          player.removeEventListener(PlayerEventType.TEXT_METADATA_CUE, onTextMetadataCue);
          player.removeEventListener(PlayerState.BUFFERING, onBuffering);
          player.removeEventListener(PlayerEventType.REBUFFERING, onRebuffering);
        };
    };

    setupPlayer();

     // Cleanup interval on component unmount
     return () => clearInterval(intervalId);
  }, [caVictimDetails.liveStream]);

 /*  useEffect(() => {
    if (caVictimDetails.liveStream === true) {
      if (!window.IVSPlayer.isPlayerSupported) {
        console.warn("The current browser does not support the IVS player.");
        return;
      }

      const PlayerState = window.IVSPlayer.PlayerState;
      const PlayerEventType = window.IVSPlayer.PlayerEventType;

      // Initialize player
      const player = window.IVSPlayer.create();
      //console.log("IVS Player version:", player.getVersion());
      player.attachHTMLVideoElement(document.getElementById("video-player"));

      // Attach event listeners
      const onPlaying = () => {
        console.log("Player State - PLAYING");
        //console.log("Latency: " + player.getLiveLatency());
      };

      const onEnded = () => {
        console.log("Player State - ENDED");
        // document.getElementById("video-player").display='none';
      };

      const onReady = () => {
        console.log("Player State - READY");
      };

      const onError = (err) => {
        console.warn("Player Event - ERROR:", err);
      };

      const onTextMetadataCue = (cue) => {
        const metadataText = cue.text;
        const position = player.getPosition().toFixed(2);
        console.log(
          `PlayerEvent - TEXT_METADATA_CUE: "${metadataText}". Observed ${position}s after playback started.`
        );
      };

      const onBuffering = () => {
        console.log("Player State - BUFFERING");
      };

      const onRebuffering = () => {
        console.log("Player State - REBUFFERING");
      };

      player.addEventListener(PlayerState.PLAYING, onPlaying);
      player.addEventListener(PlayerState.ENDED, onEnded);
      player.addEventListener(PlayerState.READY, onReady);
      player.addEventListener(PlayerEventType.ERROR, onError);
      player.addEventListener(
        PlayerEventType.TEXT_METADATA_CUE,
        onTextMetadataCue
      );
      player.addEventListener(PlayerState.BUFFERING, onBuffering);
      player.addEventListener(PlayerEventType.REBUFFERING, onRebuffering);

    //  if (isUrlValid) {
        // Setup stream and play
        player.setAutoplay(true);
        //player.load("https://cfb0684581a8.us-east-1.playback.live-video.net/api/video/v1/us-east-1.676714028793.channel.lMqyBmDMdXhy.m3u8");
        //player.load(caVictimDetails.streamInfo.playbackUrl);
        player.load(caVictimDetails.liveUrl);
        player.setVolume(0.5);
    //  } else {
        // document.getElementById("video-player").display='none';
    //  }

      // Cleanup when the component is unmounted
      return () => {
        player.removeEventListener(PlayerState.PLAYING, onPlaying);
        player.removeEventListener(PlayerState.ENDED, onEnded);
        player.removeEventListener(PlayerState.READY, onReady);
        player.removeEventListener(PlayerEventType.ERROR, onError);
        player.removeEventListener(
          PlayerEventType.TEXT_METADATA_CUE,
          onTextMetadataCue
        );
        player.removeEventListener(PlayerState.BUFFERING, onBuffering);
        player.removeEventListener(PlayerEventType.REBUFFERING, onRebuffering);
      };
    }
  }, [caVictimDetails]); 
 */
  /*  useEffect(() => {
    const fetchData = async () => {
      try {
        const userID = 'mSMNluGFB2PKEYGsNwkRQTXymq12';
        const userDataSnapshot = await firebase.database().ref(`/users/${userID}`).once('value');
        const userData = userDataSnapshot.val();

        if (userData.recordings) {
          const recordListRef = firebase.database().ref(`/users/${userID}/recordings`);
          recordListRef.on("value", (snapshot) => {
            const records = [];
            snapshot.forEach((childSnapshot) => {
              records.push(childSnapshot.val());
            });
            setFileList(records);
          });
        } 
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return () => {
      // Cleanup the listeners when the component unmounts
      firebase.database().ref(`/users/${userID}/recordings`).off();
    };
  }, []); */

  // Lamiaa Moustafa
  //seek Functionality for handling play and pause
  /* const [videoState, setVideoState] = useState({
    playing: true,
    muted: false,
    volume: 0.5,
    played: 0,
    seeking: false,
    Buffer: true,
  });
  const { playing, muted, volume, playbackRate, played, seeking, buffer } =
    videoState;

  const playPauseHandler = () => {
    //plays and pause the video (toggling)
    setVideoState({ ...videoState, playing: !videoState.playing });
  };
 */
  //  Lamiaa --- seek Functionality for progress bar
  /*  const progressHandler = (state) => {
    if (!seeking) {
      setVideoState({ ...videoState, ...state });
    }
  };

  const seekHandler = (e, value) => {
    setVideoState({ ...videoState, played: parseFloat(value) / 50 });
  };

  const seekMouseUpHandler = (e, value) => {
    setVideoState({ ...videoState, seeking: false });
    videoRef.current.seekTo(value / 50);
  }; */

  return (
    <>
      <div id="containerTest"></div>
      <Row className="d-flex justify-content-start align-items-center no-gutters">
        <Col sm={3} md={5}>
          <ProfilePhotoE911
            data={{
              source: caVictimDetails.victimInformation.profilePictureUrl
                ? caVictimDetails.victimInformation.profilePictureUrl
                : placeholderImage,
              customStyle: ProfilePicStyle,
            }}
          ></ProfilePhotoE911>
        </Col>
        <Col sm={9} md={7} className="d-flex flex-column align-items-start">
          <Col className="d-flex justify-content-start align-items-end">
            <TextE911
              data={{
                text: caVictimDetails.victimInformation.firstName,
                customStyle: victimNameStyle,
              }}
            ></TextE911>
            <TextE911
              data={{
                text: caVictimDetails.victimInformation.lastName,
                customStyle: victimNameStyle,
              }}
            ></TextE911>
          </Col>
          <Col className="speedDiv">
            <TextE911
              data={{ text: "SPEED: ", customStyle: speedStyle }}
            ></TextE911>
            <TextE911
              data={{
                text:
                  caVictimDetails.speedTravel > 0
                    ? Math.round(
                        caVictimDetails.speedTravel * 2.23694
                      )
                    : 0,
              }}
            />
            <TextE911 data={{ text: " MPH" }} />
          </Col>
        </Col>
      </Row>
      <div>
        <Col>
          <TextE911
            data={{ text: "Time of incident", customStyle: headlineSidebar }}
          />
          <TextE911
            data={{
              text: caVictimDetails.timeOfIncident,
              customStyle: bodySidebar,
            }}
          />
          {caVictimDetails.liveStream ? (
            // caVictimDetails.streamInfo.isStreaming && isUrlValid &&
            <div className="AudioDiv">
              <TextE911
                data={{ text: "LIVE AUDIO", customStyle: headlineSidebar }}
              />
              <Badge
                text="light"
                bg={
                  caVictimDetails.liveStream && isUrlValid
                  // && isLive
                    ? "success"
                    : "warning"
                }
              >
                {caVictimDetails.liveStream && isUrlValid 
                //&& isLive
                  ? "Live"
                  : "Offline"}
              </Badge>
              <audio id="video-player" controls></audio>
            </div>
          ) : (
            <div className="AudioDiv">
              <TextE911
                data={{ text: "RECENT AUDIO", customStyle: headlineSidebar }}
              />
              <ReactAudioPlayer src={caVictimDetails.mostRecentUrl} autoPlay controls />
            </div>
          )}

          <TextE911 data={{ text: "Address", customStyle: headlineSidebar }} />
          <TextE911
            data={{
              text: caVictimDetails.address,
              customStyle: bodySidebar,
            }}
          />
          <TextE911
            data={{ text: "ADDITIONAL INFO", customStyle: headlineSidebar }}
          />
          <div className="d-flex align-items-center">
            <TextE911 data={{ text: "Age:", customStyle: bodySidebarName }} />
            <TextE911 data={{ text: age, customStyle: bodySidebar }} />
          </div>
          <div className="d-flex align-items-center">
            <TextE911
              data={{ text: "Gender:", customStyle: bodySidebarName }}
            />
            <TextE911
              data={{
                text: caVictimDetails.victimInformation.sex,
                customStyle: bodySidebar,
              }}
            />
          </div>
          <div className="d-flex align-items-center">
            <TextE911
              data={{ text: "Height:", customStyle: bodySidebarName }}
            />
            <TextE911
              data={{
                text: caVictimDetails.victimInformation.height,
                customStyle: bodySidebar,
              }}
            />
          </div>
          <div className="d-flex align-items-center">
            <TextE911
              data={{ text: "Weight:", customStyle: bodySidebarName }}
            />
            <TextE911
              data={{
                text: caVictimDetails.victimInformation.weight,
                customStyle: bodySidebar,
              }}
            />
          </div>
          <div className="d-flex align-items-center">
            <TextE911
              data={{ text: "Hair Color:", customStyle: bodySidebarName }}
            />
            <TextE911
              data={{
                text: caVictimDetails.victimInformation.hairColor,
                customStyle: bodySidebar,
              }}
            />
          </div>
          <div className="d-flex align-items-center">
            <TextE911
              data={{ text: "Eye Color:", customStyle: bodySidebarName }}
            />
            <TextE911
              data={{
                text: caVictimDetails.victimInformation.eyeColor,
                customStyle: bodySidebar,
              }}
            />
          </div>
          <div className="AdditionalInfo">
            <div className="d-flex align-items-center">
              <TextE911
                data={{ text: "Addt'l Info:", customStyle: bodySidebarName }}
              />
              <TextE911
                data={{
                  text: caVictimDetails.victimInformation.moreDescription,
                  customStyle: bodySidebar,
                }}
              />
            </div>
          </div>

          <div>
            {caVictimDetails.victimInformation.healthInfo ? (
              <CollapsableTextE911
                data={{
                  allergies:
                    caVictimDetails.victimInformation.healthInfo.allergies,
                  medications:
                    caVictimDetails.victimInformation.healthInfo.medications,
                  preExistingConditions:
                    caVictimDetails.victimInformation.healthInfo
                      .preExistingConditions,
                }}
              />
            ) : null}
          </div>
        </Col>
      </div>
    </>
  );
};

export default VictimDetails;
