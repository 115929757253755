/*
   Author: Reshma Ghanta/Lamiaa Moustafa
    Desc: My profile page for e911live
          This page allows the user to update their info into the e911 system.
          Importing the small components and React hooks that are necessary.
    Desc: Styling using React Bootstrap and importing elements from the grid System.
*/
import { React, useState, useEffect } from "react";
import { Container, Col } from "react-bootstrap";
import { styles } from "../../styles/MainStyled";
import { InputStyle, selectStyle } from "../../styles/customStyle";
import earthContact from "../../assets/images/svgs/earthContact.svg";
import FormInputE911 from "../../components/reusableComponents/FormInputE911";
import "../../styles/scss/register.scss";
import ButtonE911 from "../../components/reusableComponents/ButtonE911";
import SelectE911 from "../../components/reusableComponents/SelectE911";
import "../../styles/scss/radio.scss";
import { UserData } from "../../contexts/GlobalContext";
import { UserAuth } from "../../contexts/AuthContext";
import useMediaQuery from "../../hooks/useMediaQuery";
import { E911_storage } from "../../firebaseConfig";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage"

const ManageAccount = () => {
  /*
    Author: Lamiaa Moustafa
    Desc: changing background image based on route.
  */
  const earthContactImage = {
    backgroundImage: `url(${earthContact})`,
  };
  /*
    Author: Lamiaa Moustafa
    Desc: changing edit Button width using use media hook.
  */
  const isMobile = useMediaQuery("(min-width: 575px)");
  const EditButton = {
    minWidth: isMobile ? "120px" : "100px",
  };

  /*
      Author: Reshma Ghanta
      Desc: Edit the values and update it to the database.
   */
  const options = [
    { id: 1, label: "Soft alert", value: "softAlert" },
    { id: 2, label: "Loud alert", value: "loudAlert" },
  ];
  const { e911User } = UserAuth();
  const { e911UserDetails, updateUserDataE911 } = UserData();
  //console.log(e911UserDetails)
 // const {firstname,lastname}=e911UserDetails;

  const [buttonLabel, setButtonLabel] = useState("Edit");
  const [formErrors, setFormErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectValue, setSelectValue] = useState("");
  // State to store uploaded profile picture
  const [profilePhoto, setProfilePhoto] = useState("");  

  // Setting up state to manage form values
  const [formValue, setFormValue] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    profilePhotoUrl:"",
    alertSound: "",
  });

  /*
        Author: Reshma Ghanta
        Desc: Fetch e911user details and show on page load.
              Change the form input values when the e911user details are changed. 
     */
  useEffect(() => {
   // const {firstname,lastname}=e911UserDetails;
    
    //console.log(e911UserDetails)

    if (e911UserDetails) {
    // console.log(firstname,lastname)
      const data = {
        firstName:e911UserDetails.firstName,
        lastName:e911UserDetails.lastName,
        phoneNumber: e911UserDetails.phoneNumber,
        alertSound: e911UserDetails.alertSound,
        profilePhotoUrl:e911UserDetails.profilePhotoUrl
      };
      // console.log(data)
      setFormValue(data);
    }
  }, [e911UserDetails]);

  // Storing form values into an object.
  const handleChangeEdit = (event) => {
    setFormValue({ ...formValue, [event.target.name]: event.target.value });
  };

  // Event handler for selecting alert sound of form values
  const handleChangeSelect = (selected,name) => {
   // console.log(name,selected.value);
    setSelectValue(selected);
    setFormValue({ ...formValue, [name]: selected.value }); // Updating the formValue state with the selected value for alertSound
  };

  // Event handler for updating the image uploaded by user.
  const handleChangeFile = (event)=>{
    //console.log(event.target.files[0])
    setProfilePhoto(event.target.files[0]); // Updating the profilePhoto state with the user upload image.
  }
  //console.log(formValue)

  /*
    Author: Reshma Ghanta
    Desc:  Toggle the button, enable/disable the input fields.
           Validate the form input values
           update the e911 user details based on input.
*/
  const handleClickSubmit = async (event) => {
    event.preventDefault(); // Prevent default submission
    if (buttonLabel === "Edit") {
      setIsDisabled(!isDisabled);
      isDisabled ? setButtonLabel("Submit") : setButtonLabel("Edit");
    } else if (buttonLabel === "Submit" && e911User !== null) {
      setFormErrors(validate(formValue)); //function for validating form input values and it returns form input errors
      var formInputErrors = validate(formValue);  // Validate function returns an object with form input errors
     
      //Check if the form input has any errors or no
      if (Object.keys(formInputErrors).length === 0) {
        const newUid = e911User.uid; // e911User.uid is the user ID for e911 user
          // console.log(profilePhoto);

        // Check if profile photo is uploaded by user nor not
        if(!profilePhoto){
           /*  console.log(newUid);
            console.log(formValue);
            console.log(e911UserDetails.profilePhotoURL) */
          // If profile photo is not uploaded by user, update user details with empty profile photo
             updateUserDataE911(
              newUid,
              formValue.firstName,
              formValue.lastName,
              formValue.phoneNumber,
              formValue.alertSound,
              e911UserDetails.profilePhotoUrl
            );
        }else{
          // If profile photo is uploaded by user.
          // Create a storage ref instance for E911Live project, and assign path to store the image.
        const storageRef = ref(E911_storage, `ProfilePhoto/${newUid}/${profilePhoto.name}`);

        // Once the storage ref is created, upload the image to the storage of E911Live project.
        uploadBytes(storageRef, profilePhoto).then((snapshot) => {
          console.log(snapshot.ref);
          //Once the image is uploaded, get the download url for the image to save it to the database.
          getDownloadURL(snapshot.ref).then((url) => {
           // console.log(url);
            //Once you get the url save it to the database using app context.
             updateUserDataE911(
              newUid,
              formValue.firstName,
              formValue.lastName,
              formValue.phoneNumber,
              formValue.alertSound,
              url
            );
          });
        });
        }
        isDisabled ? setButtonLabel("Submit") : setButtonLabel("Edit");
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  };


  const handleClickCancel = async (event) => {
        isDisabled ? setButtonLabel("Submit") : setButtonLabel("Edit");
        setFormValue( {firstName: e911UserDetails.firstName,
          lastName: e911UserDetails.lastName,
          phoneNumber: e911UserDetails.phoneNumber,
          alertSound: e911UserDetails.alertSound,});
        setIsDisabled(true);
  }
  /*
    Author: Reshma Ghanta
    Desc:  validating form inputs from user using Regex
*/
  const validate = (values) => {
    const errors = {};
    const name_regex = /^[a-zA-Z]+$/; //eslint-disable-next-line
    const phone_regex =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/; //eslint-disable-next-line
    //console.log(values);
    if (!values.firstName) {
      errors.firstName = "First name is required!";
    } else if (!name_regex.test(values.firstName)) {
      errors.firstName =
        "Numbers and special characters not allowed for first name";
    }
    if (!values.lastName) {
      errors.lastName = "Last name is required!";
    } else if (!name_regex.test(values.lastName)) {
      errors.lastName =
        "Numbers and special characters not allowed for last name";
    }
    if (!values.phoneNumber) {
      errors.phone = "Phone number is required!";
    } else if (!phone_regex.test(values.phoneNumber)) {
      errors.phone = "This is not a valid phone number format!";
    } else if (values.phoneNumber.length > 10) {
      errors.phone = "Phone number cannot exceed 10 numbers!";
    }

    if (!values.alertSound) {
      errors.alertSound = "Please select alert sound!!";
    }
   /*  if (!values.profilePhotoUrl) {
       alert("Please upload an image first!");
      } */
    return errors;
  };

  return (
    <div style={{ ...styles.LandingContainer, ...earthContactImage }}>
      <Container fluid className="RegisterContainerStyle">
        <div className="RegisterGroup">
          <Col
            className="RegisterFormInput mt-4 d-flex
              flex-column align-items-start justify-content-center"
          >
            <FormInputE911
              data={{
                name: "firstName",
                type: "text",
                label: "First Name",
                customStyle: InputStyle,
                error: formErrors.firstName,
                value: formValue.firstName,
                disabled: isDisabled,
                onChange: handleChangeEdit,
              }}
            />
            <FormInputE911
              data={{
                name: "lastName",
                type: "text",
                label: "Last Name",
                customStyle: InputStyle,
                error: formErrors.lastName,
                value: formValue.lastName,
                disabled: isDisabled,
                onChange: handleChangeEdit,
              }}
            />
            <FormInputE911
              data={{
                name: "phoneNumber",
                type: "number",
                label: "Phone Number",
                customStyle: InputStyle,
                error: formErrors.phone,
                value: formValue.phoneNumber,
                disabled: isDisabled,
                onChange: handleChangeEdit,
              }}
            />
            <FormInputE911
              data={{
                name: "profilePhotoUrl",
                type: "file",
                label: "Upload Profile Photo",
                customStyle: InputStyle,
                error: formErrors.profilePhotoUrl,
               // value: formValue.profilePhotoUrl,
                disabled: isDisabled,
                onChange: handleChangeFile,
                accept:"/image/*"
              }}
            />
          </Col>
          <Col sm={12} lg={6} className="mt-3">
         
            <SelectE911
              label="Select your alert sound"
              options={options}
              value={options.find((option) => option.value === formValue.alertSound)}
              customStyle={selectStyle}
             // defaultValue={options[0]}
              onChange={(selected) => handleChangeSelect(selected, "alertSound")}
              classNamePrefix="filter"
              disabled={isDisabled}
              error={formErrors.alertSound}
            />
          </Col>
          <Col md={6} sm={12} className="d-flex justify-content-center">
            <ButtonE911
              data={{
                label: buttonLabel,
                onClick: handleClickSubmit,
                customStyle: EditButton,
              }}
            />
            {!isDisabled&&<ButtonE911
              data={{
                label: "Cancel",
                onClick:handleClickCancel,
              }}
            />}
          </Col>
        </div>
      </Container>
    </div>
  );
};
export default ManageAccount;
