/*
    Author: Reshma Ghanta
    Desc: Routes for e911live
          Importing GlobalContextProvider from firebase-react GlobalContext.
          Importing Router,Route,Routes from react dom router.
          Defining all the components in only two pages(Landing,Dashboard).
*/
import React from "react";
import Landing from "../views/Landing";
import Login from "../views/Login";
import ForgotPassword from "../views/ForgotPassword";
import Authentication from "../views/Authentication";
import Contact from "../views/Contact";
import Faq from "../views/Faq";
import Terms from "../views/Terms";
import Dashboard from "../views/Dashboard";
import MappingContainer from "../views/MappingContainer";
import AccessControl from "../views/AccessControl";
import ManageAccount from "../views/ManageAccount";
import Register from "../views/Register";
 import NotFound from "../views/NotFound"; 
import Invitation from "../views/Register/Invitation";
import ExpirationLink from "../views/Register/Expiration";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { GlobalContextProvider } from "../contexts/GlobalContext";
import {E650DContextProvider} from "../contexts/E650DContext";
import { SoteriaContextProvider } from "../contexts/SoteriaContext";

function Routers() {
  return (
    <div>
      <GlobalContextProvider>
        <E650DContextProvider>
          <SoteriaContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Landing />}>
              <Route index element={<Login />} />
              <Route path="forgotPassword" element={<ForgotPassword />} />      
              <Route path="expiration" element={<ExpirationLink />} />
              <Route path="invitelink/:emailid" element={<Invitation />} />
              <Route path="authentication" element={ <ProtectedRoute>  <Authentication /> </ProtectedRoute> }/>
            </Route>

            <Route element={ <ProtectedRoute> <Dashboard /> </ProtectedRoute>}>
              <Route index path="dashboard" element={<MappingContainer />} />
              <Route path="register" element={<Register />} />
              <Route path="accesscontrol" element={<AccessControl />} />
              <Route path="manageaccount" element={<ManageAccount />} />
              <Route path="contact" element={ <Contact />}/>
              <Route path="faq" element={ <Faq /> }/>
              <Route path="termsofuse" element={ <Terms />} />
            </Route>

            <Route path="*" element={<NotFound />} /> 
          </Routes>
        </Router>
         </SoteriaContextProvider>
        </E650DContextProvider>
      </GlobalContextProvider>
    </div>
  );
}

export default Routers;
