/*
    Author: Reshma Ghanta
    Desc: Creating AuthContext for E911 project.
          SignUp, SignIn, resetPassword, Logout from E911 auth.
          SignIn Anonymous from CA auth.
          Importing auth instances from firebase configuration.
*/
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
 // PhoneAuthProvider,
 // multiFactor,
 // signInWithPhoneNumber,
 // RecaptchaVerifier
} from "firebase/auth";
import { E911_auth } from "../firebaseConfig";
import { signInWithCustomToken } from "firebase/auth";
import { CA_auth, Soteria_auth } from "../firebaseConfig";

/*
    Author: Reshma Ghanta
    Desc:Creating Auth context for the E911live project.
*/
const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  // Current user instance who logged into E911 system. (User is from E911 project)
  const [e911User, setE911User] = useState({});
  const [victimInstance, setVictimInstance] = useState(() => {
    return localStorage.getItem("victimInstance") || null;
  });
  const [soteriaInstance, setSoteriaInstance] = useState(() => {
    return localStorage.getItem("soteriaInstance") || null;
  });

  /*
     Author: Reshma Ghanta
     Desc: Signup new user into E911 project.
    */
  const signUpE911 = (email, password) => {
    return createUserWithEmailAndPassword(E911_auth, email, password);
  };

  /*
    Author: Reshma Ghanta
    Desc: SignIn the existing user from E911 project. 
    */

  const signInE911 = async(email, password) => {
    //console.log(email,password)
    try{
        const userCredential= await signInWithEmailAndPassword(E911_auth, email, password);
        const E911User = userCredential.user;

        //console.log(userCredential)

        if (!E911User) {
          throw new Error("User not found")
        }

        const [e650dData, soteriaData] = await Promise.all([
          fetch("/e650dLogin").then(response => response.json()),
          fetch("/soteriaLogin").then(response => response.json()),
         
          //fetch("http://localhost:5000/e650dLogin").then(response => response.json()),
          //fetch("http://localhost:5000/soteriaLogin").then(response => response.json())
        ]);

        const victimToken = e650dData.customToken;
        const soteriaToken=soteriaData.customToken;

        const victimCredential = await signInWithCustomToken(CA_auth, victimToken);
        //console.log(victimCredential.user.uid);
        setVictimInstance(victimCredential.user.uid);
        localStorage.setItem("victimInstance", victimCredential.user.uid);
    
        const deviceCredential = await signInWithCustomToken(Soteria_auth, soteriaToken);
        setSoteriaInstance(deviceCredential.user.uid);
        localStorage.setItem("soteriaInstance", deviceCredential.user.uid);

        return E911User;
        
    } catch(e){
        console.log("Error",e);
        throw e;
    }
  };

  /*
    Author: Reshma Ghanta
    Desc: Reset email link for E911 project.
    */
  const resetPasswordE911 = (email) => {
    return sendPasswordResetEmail(E911_auth, email);
  };

  /*
    Author: Reshma Ghanta
    Desc: Logging out from E911 project.
    */
  const logoutE911 = () => {
    return signOut(E911_auth);
  };



  /*
    Author: Reshma Ghanta
    Desc: OnAuthStateChanged values for E911live project.
    */
  useEffect(() => {
    //Current instance of user logged into E911 project.
    const unsubscribeE911 = onAuthStateChanged(E911_auth, (currentUser) => {
      setE911User(currentUser);
    });

    return () => {
      unsubscribeE911();
    };
  }, []);

  // console.log(e911User);

  return (
    <AuthContext.Provider
      value={{
        signUpE911,
        signInE911,
        resetPasswordE911,
        logoutE911,
        e911User,
        victimInstance,
        soteriaInstance,
       // sendPhoneVerification, 
       // verifyPhoneCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

//Exporting all
export const UserAuth = () => {
  return useContext(AuthContext);
};
