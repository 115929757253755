import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logoutButton } from "../../styles/customStyle";
import { UserAuth } from "../../contexts/AuthContext";
import { UserData } from "../../contexts/GlobalContext";
import { FaPlus } from "react-icons/fa";
//import { RiLogoutBoxLine } from "react-icons/ri";
import {
  avatarStyle,
  emailStyle,
  ListIconsNav,
 // LogoutIconStyle,
} from "../../styles/customStyle";
import ProfilePhotoE911 from "../../components/reusableComponents/ProfilePhotoE911";
import TextE911 from "../../components/reusableComponents/TextE911";
import ButtonE911 from "../../components/reusableComponents/ButtonE911";

const AccountPopup = () => {
  const navigate = useNavigate();
  const { logoutE911 } = UserAuth();
  const { e911UserDetails } = UserData();



  /*
   * Author: Reshma Ghanta
   * Desc:  Function to handle logout action
   */
  const handleClickLogout = async () => {
    try {
      localStorage.removeItem('victimInstance');
      localStorage.removeItem('soteriaInstance');
      localStorage.removeItem('historyData');
      await logoutE911(); // Call logoutE911 function for logging out user from E911 live project
      navigate("/"); // Navigate to the home page after logout
    } catch (err) {
      console.error("Error while logging out", err);
    }
  };

  /*
        Author: Reshma Ghanta
        Desc: Show the details of the e911user logged in.
              Change the details when the e911user details are changed. 
     */
  return (
    <>
      <div className="m-2 d-flex justify-content-start align-items-center">
        <ProfilePhotoE911
          data={{
            source: e911UserDetails.profilePhotoUrl
              ? e911UserDetails.profilePhotoUrl
              : require(`../../assets/images/pngs/Avatar.png`),
            customStyle: avatarStyle,
          }}
        ></ProfilePhotoE911>
        <div className="flex-column justify-content-center align-items-start">
          <TextE911
            data={{ text: e911UserDetails.firstName, customStyle: emailStyle }}
          />
          <TextE911
            data={{ text: e911UserDetails.email, customStyle: emailStyle }}
          />
        </div>
      </div>

      <div className="d-flex p-3 justify-content-start align-items-center ManageAccountStyle">
        <FaPlus style={ListIconsNav} />

        <Link className="LinkAvatarStyle" to="/manageaccount">
          Manage account
        </Link>
      </div>
      <div className="d-flex p-3 justify-content-start align-items-center">
        {/* <RiLogoutBoxLine style={LogoutIconStyle} />
        <Link
          handleClickLogout={handleClickLogout}
          className="LinkAvatarStyle"
          to="/"
        >
          Logout
        </Link>  */}
        <ButtonE911
          data={{
            onClick: handleClickLogout,
            label: "LOGOUT",
            customStyle: logoutButton,
          }}
        />
      </div>
    </>
  );
};

export default AccountPopup;
