/*
    Author: Lamiaa Moustafa
    Desc: react bootstrap modal as reusable component
 */

import React from "react";
import { Modal } from "react-bootstrap";

const ModalE911 = (props) => {
  const { show, handleClose, size, modalTitle } = props;

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onClose={handleClose}
      size={size}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
        <p className="btn-modal-close" onClick={() => handleClose()}>
          <i className="fa fa-times text-danger"></i>
        </p>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
};

export default ModalE911;
