/*
    Author: Reshma Ghanta
    Desc: Reusable Form Input component.
*/
import React, { useState } from "react";
import TextE911 from "../reusableComponents/TextE911";
import { ErrorMessageStyle } from "../../styles/customStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

function FormInputE911(props) {
  //console.log(props.data);
  const {
    name,
    type,
    placeholder,
    ref,
    onChange,
    onKeyPress,
    customStyle,
    label,
    error,
    disabled,
    value,
    hidden,
    autoComplete,
  } = props.data;

  //Hide and show password
  const [passwordShown, setPasswordShown] = useState(type);
  const eye = <FontAwesomeIcon icon={faEye} />;
  const togglePasswordVisible = () => {
    let v = passwordShown === "password" ? "text" : "password";
    setPasswordShown(v);
  };
  //Hide and show password

  return (
    <>
      <div
        style={{
          width: "100%",
          marginTop: "0",
          paddingTop: "0",
        }}
      >
        <label>{label}</label>
        <input
          name={name}
          type={passwordShown}
          placeholder={placeholder}
          onChange={onChange}
          onKeyPress={onKeyPress}
          ref={ref}
          style={customStyle}
          disabled={disabled}
          value={value}
          autoComplete={autoComplete}
          hidden={hidden}
        />
        <TextE911
          data={{ text: error, customStyle: ErrorMessageStyle }}
        ></TextE911>
        {name === "password" ? (
          <i onClick={togglePasswordVisible}>{eye}</i>
        ) : null}
      </div>
    </>
  );
}

export default FormInputE911;
