import React, { useState } from "react";
import { Link } from "react-router-dom";

const MailToE911 = ({ mailto, label }) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const HoverStyle = {
    backgroundColor: isHover ? "var(--yellowOrange)" : "var(--orange)",
  };
  const mailToStyle = {
    backgroundColor: "var(--orange)",
    fontWeight: "var(--regularFont)",
    color: "var(--white)",
    fontSize: "1em",
    padding: "10px 20px",
    letterSpacing: "1px",
    borderRadius: "25px",
    margin: "20px 10px",
    border: "1px solid var(--white)",
    cursor: "pointer",
  };

  return (
    <Link
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ ...mailToStyle, ...HoverStyle }}
      to="#"
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};

export default MailToE911;
