/*
    Author: Reshma Ghanta
    Desc: Footer for e911live
          Footer has links to FAQ,Privacy policy and Terms of Use
          Importing Links from react dom router.
          Importing all necessary small components. (Text)
*/
import React, { useEffect, useState } from "react";
import TextE911 from "../reusableComponents/TextE911";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { CopyrightStyle } from "../../styles/customStyle";
import { UserAuth } from "../../contexts/AuthContext";
import "../../styles/scss/footer.scss";

const FooterE911 = () => {
  const { e911User } = UserAuth();
  const [isE911UserAvailable, setIsE911UserAvailable] = useState(false);

  //console.log(e911User);

  /*  const HideLink = () => {
    setIsE911UserAvailable(!isE911UserAvailable);
  }; */
  useEffect(() => {
   // console.log(e911User);
    if (e911User) {
      setIsE911UserAvailable(true);
    } else {
      setIsE911UserAvailable(false);
    }
    //console.log(isE911UserAvailable);
  }, [e911User]);

  // if (!e911User) {
  //   isE911UserAvailable(true);
  // }

  return (
    <Container fluid className="footer fixed-bottom">
      <Row>
        <Col
          sm={12}
          md={6}
          className="d-flex justify-content-center justify-content-md-between align-items-center"
        >
          <TextE911
            data={{
              text: "Copyright@2023 covertalert. All rights reserved",
              customStyle: CopyrightStyle,
            }}
          ></TextE911>
        </Col>

        <Col
          sm={12}
          md={6}
          className="d-flex justify-content-center align-items-center justify-content-md-end"
        >
          <div className="d-flex  justify-content-center align-items-center">
            {isE911UserAvailable && (
              <Link className="reverseLinkStyle" to="/contact">
                Contact Us
              </Link>
            )}
            {isE911UserAvailable && (
              <Link className="reverseLinkStyle" to="/faq">
                | FAQ
              </Link>
            )}
            {isE911UserAvailable && (
              <Link className="reverseLinkStyle" to="/termsofuse">
                | Terms of Use
              </Link>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default FooterE911;
