/*
    Author: Reshma Ghanta
    Desc: Reusable Success Message component.
*/
import React from "react";
import TextE911 from "../reusableComponents/TextE911.js";

const SuccessMessageE911 = (props) => {
  const { text,customStyle } = props.data;
  return (
    <div>
      <TextE911 data={{ text: text, customStyle: customStyle }}></TextE911>
    </div>
  );
};

export default SuccessMessageE911;
