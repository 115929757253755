/*
    Author: Reshma Ghanta
    Desc: Protected Routes for e911live
          Importing UserAuth from firebase-react AuthContext.
          Importing Navigate into react dom router
*/

import React from 'react'
import {Navigate} from 'react-router-dom';
import { UserAuth } from '../contexts/AuthContext';

const ProtectedRoute = ({children}) => {
    const{e911User}=UserAuth();
    if(!e911User){
       return <Navigate to='/'/>;
    }/* else if(e911User){
        console.log(e911User)
        return <Navigate to='/dashboad'/>;
    } */
  return children;
}

export default ProtectedRoute