/*
    Author: Reshma Ghanta
    Desc: Reusable Radio button component.
*/
import React from "react";

const RadioButtonE911 = (props) => {
  const { value, label, onChange, customStyle,checked } = props.data;
  return (
    <div className="d-flex justify-content-start align-items-center">
      <input
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
        style={customStyle}
      />
      <label>{label}</label>
    </div>
  );
};

export default RadioButtonE911;
